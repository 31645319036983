export const CHANNEL_PARTNER_MAP = new Map([
	[
		'stately',
		{
			brand: 'Partner',
			fiId: 423,
			partnerId: 1,
			partnerName: 'stately',
			storeName: 'stately',
			storeNumber: 0,
		}
	]
]);
