<template>
  <div
    :class="[{ 'open' : step.showStep, 'incomplete' : !step.completed && !step.showStep }, getStepClass(step) ]"
    class="step channel-partner-section-title"
  >
    <span class="step">{{ step.id }}</span>
    <h3>{{ step.title }}</h3>
    <span
      class="completion-status"
    ><font-awesome-icon icon="check-circle" /></span>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    getStepClass(step) {
      return `step${step.id}`;
    }
  }
}
</script>