export const confirmationIcon = `<svg width="55px" height="50px" viewBox="0 0 55 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="LOANS-flow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Loans-Page_Confirmation" transform="translate(-656.000000, -247.000000)">
            <g id="Group-11" transform="translate(659.000000, 250.000000)">
                <path d="M6.30051469,6.30051469 C-2.10017156,14.7012009 -2.10017156,28.3215784 6.30051469,36.7222646 C14.7012009,45.1243805 28.3215784,45.1243805 36.7222646,36.7222646 C45.1243805,28.3215784 45.1243805,14.7012009 36.7222646,6.30051469 C28.3215784,-2.10017156 14.7012009,-2.10017156 6.30051469,6.30051469 Z" id="Stroke-1" stroke="#FFFFFF" stroke-width="6" opacity="0.451535247"></path>
                <path d="M25.5939287,28.9836543 L23.0462734,31.5313096 C22.7331777,31.8444053 22.1999143,31.8186714 21.8539364,31.4741232 L12.6469215,22.2671083 L9.53740947,19.1575963 C9.41016967,19.0303565 9.32724935,18.8759531 9.28721885,18.7172608 C9.26005529,18.4813668 9.32581969,18.2454728 9.49737897,18.0739135 L10.0349314,17.5377907 L10.836971,16.7343214 L12.3338258,15.2374667 C12.6254766,15.1459684 12.9714545,15.2260294 13.2202154,15.4747903 L16.7085875,18.9631624 L17.9166508,20.1712257 L18.8001811,21.054756 L25.5367423,27.7913172 C25.8827202,28.1358655 25.9070244,28.6705586 25.5939287,28.9836543" id="Fill-3" fill="#FFFFFF"></path>
                <path d="M25.5939287,28.9836543 L23.0462734,31.5313096 C22.7331777,31.8444053 22.1999143,31.8186714 21.8539364,31.4741232 L12.6469215,22.2671083 L9.53740947,19.1575963 C9.41016967,19.0303565 9.32724935,18.8759531 9.28721885,18.7172608 C9.26005529,18.4813668 9.32581969,18.2454728 9.49737897,18.0739135 L10.0349314,17.5377907 L10.836971,16.7343214 L12.3338258,15.2374667 C12.6254766,15.1459684 12.9714545,15.2260294 13.2202154,15.4747903 L16.7085875,18.9631624 L17.9166508,20.1712257 L18.8001811,21.054756 L25.5367423,27.7913172 C25.8827202,28.1358655 25.9070244,28.6705586 25.5939287,28.9836543 Z" id="Stroke-5" stroke="#FFFFFF" stroke-width="2.372"></path>
                <path d="M24.2431853,29.0511342 L21.69553,26.5034789 C21.3824343,26.1903832 21.4081682,25.6571197 21.7527164,25.3111419 L42.9331396,4.13071865 L46.0440813,1.02120664 C46.1713211,0.893966833 46.3242948,0.809616852 46.4829871,0.771016013 C46.7188811,0.742422799 46.9562048,0.809616852 47.1277641,0.981176136 L47.6638868,1.5172989 L48.4659265,2.32076821 L49.9642109,3.81762296 C50.0542795,4.10927374 49.9742185,4.45525163 49.7254576,4.70401259 L34.2636771,20.165793 L33.0556138,21.3738563 L32.1720835,22.2573866 L25.4355223,28.9939478 C25.0909741,29.3399257 24.556281,29.3642299 24.2431853,29.0511342" id="Fill-7" fill="#FFFFFF"></path>
                <path d="M24.2431853,29.0511342 L21.69553,26.5034789 C21.3824343,26.1903832 21.4081682,25.6571197 21.7527164,25.3111419 L42.9331396,4.13071865 L46.0440813,1.02120664 C46.1713211,0.893966833 46.3242948,0.809616852 46.4829871,0.771016013 C46.7188811,0.742422799 46.9562048,0.809616852 47.1277641,0.981176136 L47.6638868,1.5172989 L48.4659265,2.32076821 L49.9642109,3.81762296 C50.0542795,4.10927374 49.9742185,4.45525163 49.7254576,4.70401259 L34.2636771,20.165793 L33.0556138,21.3738563 L32.1720835,22.2573866 L25.4355223,28.9939478 C25.0909741,29.3399257 24.556281,29.3642299 24.2431853,29.0511342 Z" id="Stroke-9" stroke="#FFFFFF" stroke-width="2.655"></path>
            </g>
        </g>
    </g>
</svg>`;

export const technicalIssueIcon = `<svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
<path style="fill: #f0b0b4;" class="cls-1" d="M21.61,4,3.19,31.82a2,2,0,0,0,1.66,3.11H41.71a2,2,0,0,0,1.66-3.11L25,4A2,2,0,0,0,21.61,4Z" />
<path style="fill: #d41a27;" class="cls-2"
  d="M33.55,29.6,44.87,40.92c.49.48.31,1.45-.39,2.16l-1.11,1.11c-.71.7-1.68.88-2.17.39L29.87,33.26Z" />
<ellipse style="fill: none;stroke: #d41a27;stroke-miterlimit: 10;stroke-width: 4px;" class="cls-3" cx="23.37" cy="23.18" rx="13.67" ry="13.67" />
<path style="fill: #fff;" class="cls-4" d="M23.22,30.42a1.71,1.71,0,1,1,1.71-1.68A1.72,1.72,0,0,1,23.22,30.42Z" />
<rect style="fill: #fff;" class="cls-4" x="18.52" y="18.33" width="9.39" height="3.42" rx="1.5"
  transform="translate(43.26 -3.18) rotate(90)" />
</svg>`

export const loadingIcon = `<svg viewBox="0 0 100 100"
xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
style="width:150px;height:150px;background-size:initial;background-repeat-y:initial;background-repeat-x:initial;background-position-y:initial;background-position-x:initial;background-origin:initial;background-color:initial;background-clip:initial;background-attachment:initial;background-color:#fff;border-radius:50%;padding: 20px;border:1px solid #979797">
    <g class="ldl-scale"
        style="transform-origin: 25% 25%;transform: rotate(0deg) scale(0.5, 0.5);">
        <defs>

            <linearGradient gradientUnits="userSpaceOnUse" y2="23.4" x2="13.26" y1="64.91" x1="127.31"
                id="linear-gradient">
                <stop stop-color="#9d2985" offset="0"
                    style="stop-color:rgb(157, 41, 133);"></stop>
                <stop stop-color="#8f3990" offset="0.21"
                    style="stop-color:rgb(143, 57, 144);"></stop>
                <stop stop-color="#725ca6" offset="0.72"
                    style="stop-color:rgb(114, 92, 166);"></stop>
                <stop stop-color="#6769af" offset="1"
                    style="stop-color:rgb(103, 105, 175);"></stop>
            </linearGradient>
            <linearGradient gradientUnits="userSpaceOnUse" gradientTransform="matrix(-1, 0, 0, 1, -18.49, 0)"
                y2="34.7" x2="-145.79" y1="76.21" x1="-31.74" id="linear-gradient-2"
                >
                <stop stop-color="#cf202b" offset="0"
                    style="stop-color:rgb(207, 32, 43);"></stop>
                <stop stop-color="#d2272b" offset="0.03"
                    style="stop-color:rgb(210, 39, 43);"></stop>
                <stop stop-color="#dd482c" offset="0.21"
                    style="stop-color:rgb(221, 72, 44);"></stop>
                <stop stop-color="#e6612c" offset="0.39"
                    style="stop-color:rgb(230, 97, 44);"></stop>
                <stop stop-color="#ec732d" offset="0.58"
                    style="stop-color:rgb(236, 115, 45);"></stop>
                <stop stop-color="#f07e2d" offset="0.77"
                    style="stop-color:rgb(240, 126, 45);"></stop>
                <stop stop-color="#f1812d" offset="1"
                    style="stop-color:rgb(241, 129, 45);"></stop>
            </linearGradient>
        </defs>
        <g data-name="Layer 2" id="Layer_2">
            <g data-name="Layer 1" id="Layer_1-2">
                <polygon points="0 88.31 140.56 28.49 140.56 0 0 59.83 0 88.31" class="loading-gradient1" style="transform: scale(0.91);transform-origin: 50px 50px;animation: 1.11111s linear -0.666667s infinite normal forwards running grow;"
                    ></polygon>
                <rect transform="translate(117.67 166.18) rotate(180)" height="150.89" width="18.44" y="7.64"
                    x="49.62" class="red-fill" style="fill:rgb(207, 32, 43);"></rect>
                <polygon points="140.56 99.61 0 39.79 0 11.3 140.56 71.13 140.56 99.61" class="loading-gradient2" style="transform: scale(0.91); transform-origin: 50px 50px; animation: 1.11111s linear -1.11111s infinite normal forwards running grow;"
                    ></polygon>
            </g>
        </g>
    </g><!-- generated by https://loading.io/ -->
</svg>`;

export const maintenanceIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tools" viewBox="0 0 16 16" style="fill: #eacf4a;width: 150px; height: 150px;">
  <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z"/>
</svg>
`;