<template>
  <div class="page-header">
    <div class="container">
      <div class="row">
        <div
          v-if="layout === 'confirmation'"
          class="col-lg-7 offset-lg-3"
        >
          <div class="header-content confirmation">
            <gtm-events
              :e-purchase="true"
              :event-data="getTrackingData()"
            />
            <div
              v-if="applicantData.status === 'APPROVED'"
            >
              <h1>Great news, {{ applicantData.firstName }} (drum roll, please).</h1>

              <h2>
                <svg-icon icon="confirmation-icon" />
                You've been approved for the Kasasa Loan<sup>&reg;</sup>!
              </h2>
            </div>
            <div
              v-else-if="applicantData.status === 'REFERRED'"
            >
              <h1>Great news, {{ applicantData.firstName }} (drum roll, please).</h1>

              <h2>
                <svg-icon icon="confirmation-icon" />
                You've got pending approval for the Kasasa Loan<sup>&reg;</sup>!
              </h2>
            </div>
            <div
              v-else-if="applicantData.status === 'DECLINED'"
            >
              <h1>We're sorry, {{ applicantData.firstName }}.</h1>

              <h2>
                No offers are available at this time.
              </h2>
            </div>
            <div
              v-else-if="applicantData.status === 'SUBMITTED'"
            >
              <h1>Hey, {{ applicantData.firstName }}.</h1>
              <h2>Looks like this application's already been submitted. </h2>
            </div>
          </div>
        </div>
        <div
          v-else-if="layout === 'application'"
          class="col-lg-7 offset-lg-3"
        >
          <div class="header-content application">
            <h1>
              You're on your way to getting the only loan with
              Take-Backs.&trade;
            </h1>
            <h2>Take 5 minutes to finish your application.</h2>
          </div>
        </div>
        <div
          v-else-if="layout === 'prequal__auto'"
          class="col-lg-7 offset-lg-3"
        >
          <div class="header-content prequal">
            <h1>
              You're about to get a better payment for your sweet ride.
            </h1>
            <h2>Get started now.</h2>
          </div>
        </div>
        <div
          v-else-if="layout === 'prequal__personal'"
          class="col-lg-7 offset-lg-3"
        >
          <div class="header-content prequal">
            <h1>
              Get the money you need. <br> Get the Take-Backs<sup>&trade;</sup> you'll love.
            </h1>
            <h2>Get started now.</h2>
          </div>
        </div>
        <div
          v-else-if="layout === 'results'"
          class="col-lg-12"
        >
          <div class="header-content results">
            <results-header :applicant-data="applicantData" />
          </div>
        </div>
        <div
          v-else
          class="col-lg-7 offset-lg-3"
        >
          <div class="header-content">
            <h1>Sorry. Nothing to see here.</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import GtmEvents from "@/components/GtmEvents.vue";
import ResultsHeader from './ResultsHeader.vue';

export default {
  components: {
    SvgIcon,
    GtmEvents,
    ResultsHeader,
  },
  props: {
    layout: {
      type: String,
      default: "application",
    },
    applicantData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    getTrackingData() {
      return {
        title: "Thank You",
        stepNumber: 6,
      };
    },
  },
};
</script>