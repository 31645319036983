<template>
  <div class="application-page fade-in maintenance-mode">
    <div
      class="icon mb-4"
      style="width: 150px"
      v-html="getIcon()"
    />
    <h1 class="font-weight-600">
      Maintenance Mode
    </h1>
  </div>
</template>

<script>
import { maintenanceIcon } from '@/svgs';

const MaintenanceModePage = {
  methods: {
    getIcon() {
      return maintenanceIcon;
    }
  },
};

export default MaintenanceModePage;
</script>
<style lang="scss" scoped>
.maintenance-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  h1 {
    color: #555;
  }
}
</style>