export const generator = (name, validator) => [name, validator];

const VALIDATION_MESSAGE = 'Field cannot have special characters';

const RULE_NAME = 'specialChars';

export const specialCharsRule = [RULE_NAME, {
  getMessage: (field, { message } = {}) => message || VALIDATION_MESSAGE,
  validate: (value = '') => !Boolean(String(value).match(/[^\w\d\s:]/gmi, '')),
}, {
  paramsNames: ['message'],
}];
