<template>
  <span
    v-show="validationErrors.has(field)"
    class="invalid-feedback"
  >
    {{ validationErrors.first(field) ? message : '' }}
  </span>
</template>

<script>
export default {
  props: {
    validationErrors: {
      type: Object,
      default() {
        return {}
      }
    },
    field: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
}
</script>