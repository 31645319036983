import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import PartnerFormPage from '@/pages/PartnerFormPage.vue';
import PartnerOffersPage from '@/pages/PartnerOffersPage.vue';
import PartnerReviewPage from '@/pages/PartnerReviewPage.vue';
import ConfirmationPage from '@/pages/ConfirmationPage.vue';
import ChannelPartnerPage from '@/pages/ChannelPartnerPage.vue';
import PrequalPage from '@/pages/loans/PrequalPage.vue';
import ResultsPage from '@/pages/loans/ResultsPage.vue';
import PartnerConfirmationPage from '@/pages/PartnerConfirmationPage.vue';
import store from '@/store';
import { CHANNEL_PARTNER_MAP } from '@/constants/channel-partner-map';
import { maintenanceModeRouteHandler } from '@/services/api/api';

Vue.use(VueRouter);

const routes = [
	{
		name: 'Home',
		path: '/',
		component: HomePage,
	},
	{
		name: 'Loan Prequal',
		path: '/loan/prequal',
		component: PrequalPage
	},
	{
		name: 'Loan Results',
		path: '/loan/results',
		component: ResultsPage,
		props: true
	},
	{
		name: 'PartnerFormPage',
		path: '/:partnerName',
		component: PartnerFormPage,
		beforeEnter: async (to, from, next) => {
      const { partnerName } = to.params;

      await maintenanceModeRouteHandler(next, partnerName);

			const { creditScore } = to.query;
			const channelPartner = CHANNEL_PARTNER_MAP.get(partnerName);

			store.commit('setChannelPartner', { channelPartner });
			store.commit('setCreditScore', { creditScore });

			await store.dispatch('getAndSetChannelPartnerFi', channelPartner.fiId);

			next();
		},
	},
	{
		name: 'PartnerOffersPage',
		path: '/:partnerName/offers',
		component: PartnerOffersPage,
		beforeEnter: async (to, from, next) => {
      const { partnerName } = to.params;

      await maintenanceModeRouteHandler(next, partnerName);


			const { creditScore } = to.query;
			const channelPartner = CHANNEL_PARTNER_MAP.get(partnerName);

			store.commit('setChannelPartner', { channelPartner });
			store.commit('setCreditScore', { creditScore });

			await store.dispatch('getAndSetChannelPartnerFi', channelPartner.fiId);

			next();
		},
	},
	{
		name: 'PartnerReviewPage',
		path: '/:partnerName/review',
		component: PartnerReviewPage,
		beforeEnter: async (to, from, next) => {
      const { partnerName } = to.params;

      await maintenanceModeRouteHandler(next, partnerName);


			const { creditScore } = to.query;
			const channelPartner = CHANNEL_PARTNER_MAP.get(partnerName);

			store.commit('setChannelPartner', { channelPartner });
			store.commit('setCreditScore', { creditScore });

			await store.dispatch('getAndSetChannelPartnerFi', channelPartner.fiId);

			next();
		},
	},
	{
		name: 'PartnerConfirmationPage',
		path: '/:partnerName/confirmation',
		component: PartnerConfirmationPage,
		beforeEnter: async (to, from, next) => {
      const { partnerName } = to.params;

      await maintenanceModeRouteHandler(next, partnerName);


			const { creditScore } = to.query;
			const channelPartner = CHANNEL_PARTNER_MAP.get(partnerName);

			store.commit('setChannelPartner', { channelPartner });
			store.commit('setCreditScore', { creditScore });

			await store.dispatch('getAndSetChannelPartnerFi', channelPartner.fiId);

			next();
		},
	},
	{
		name: 'Confirmation',
		path: '/confirmation',
		component: ConfirmationPage,
		props: true
	},
	{
		path: '*',
		redirect: { name: 'Home' },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return new Promise((resolve, reject) => {
		  setTimeout(() => {
			resolve({ x: 0, y: 0 })
		  }, 500)
		})
	}
});

export default router;
