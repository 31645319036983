import Vue from 'vue';
import Vuex from 'vuex';
import { ApiService } from '@/services/api';
import { partnerApplicationSteps } from '@/constants/partner-application-steps';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		uuid: null,
		fiId: null,
		fiName: null,
		applicationId: null,
		loanType: null,
		loanPurpose: null,
		loanDetails: {},
		creditScore: null,
		applicationData: null,
		channelPartnerForm: null,
		channelPartners: [],
		channelPartner: null,
		channelPartnerFi: null,
		selectedChannelPartnerOffer: null,
    partnerForm: null,
    selectedPartnerOffer: null,
    partnerPrequalifiedData: null,
    partnerApplicationData: null,
    partnerSubmissionData: null,
    partnerApplicationStep: partnerApplicationSteps.stepOne,
    isMaintenanceModeEnabled: false,
	},
	getters: {
		getUuid() {
			return state.uuid;
		},
		getFiId() {
			return state.fiId;
		},
		getFiName() {
			return state.fiName;
		},
		getApplicationId() {
			return state.applicationId;
		},
		getLoanType() {
			return state.loanType;
		},
		getLoanPurpose() {
			return state.loanPurpose;
		},
		getLoanDetails() {
			return state.loanDetails;
		},
		getCreditScore() {
			return state.creditScore;
		},
		getApplicationData() {
			return state.applicationData;
		},
    getPartnerOffers() {
      return (state.partnerPrequalifiedData && state.partnerPrequalifiedData.offers)
        ? state.partnerPrequalifiedData.offers
        : [];
    }
	},
	mutations: {
		setUuid (state, payload) {
			state.uuid = payload.uuid
		},
		setFiId (state, payload) {
			state.fiId = payload.fiId
		},
		setFiName (state, payload) {
			state.fiName = payload.fiName
		},
		setApplicationId (state, payload) {
			state.applicationId = payload.applicationId
		},
		setLoanType (state, payload) {
			state.loanType = payload.loanType
		},
		setLoanPurpose (state, payload) {
			state.loanPurpose = payload.loanPurpose
		},
		setLoanDetails (state, payload) {
			state.loanDetails = payload.loanDetails
		},
		setCreditScore (state, payload) {
			state.creditScore = payload.creditScore
		},
		setApplicationData (state, payload) {
			state.applicationData = payload.applicationData
		},
		setChannelPartnerForm (state, payload) {
			state.channelPartnerForm = payload.channelPartnerForm;
		},
		setChannelPartner (state, payload) {
			state.channelPartner = payload.channelPartner;
		},
		setChannelPartnerFi (state, payload) {
			state.channelPartnerFi = payload.channelPartnerFi;
		},
		setSelectedChannelPartnerOffer (state, payload) {
			state.selectedChannelPartnerOffer = payload.selectedChannelPartnerOffer;
		},
    setPartnerForm (state, payload) {
      state.partnerForm = payload.partnerForm;
    },
    updatePartnerForm (state, formSection) {
      state.partnerForm = {
        ...state.partnerForm,
        ...formSection,
      };
    },
    setPartnerPrequalifiedData (state, data) {
      state.partnerPrequalifiedData = data;
    },
    setSelectedPartnerOffer (state, offer) {
      state.selectedPartnerOffer = offer;
    },
    setPartnerSubmissionData (state, data) {
      state.partnerSubmissionData = data;
    },
    setPartnerApplicationData (state, data) {
      state.partnerApplicationData = data;
    },
    setPartnerApplicationStep (state, step) {
      state.partnerApplicationStep = step;
    },
    setIsMaintenanceModeEnabled (state, isEnabled) {
      state.isMaintenanceModeEnabled = isEnabled;
    }
	},
	actions: {
		async getAndSetChannelPartnerFi ({ commit }, fiId) {
			const fiData = await ApiService.getFiData({
				financialid: fiId,
			});

			commit('setChannelPartnerFi', { channelPartnerFi: fiData});
		}
	},
	modules: {
	},
	plugins: [],
});
