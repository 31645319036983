<template>
  <div class="header-content row">
    <div
      v-if="applicantData.storageData.isPrequalified"
      class="qualification-message col-md-8"
    >
      <h1>Thanks {{ applicantData.payload.firstName }} {{ applicantData.payload.lastName }}!</h1>
      <h1>You're on your way to getting the Kasasa Loan.</h1>
      <p
        v-if="applicantData.payload.loanType ==='Debt Consolidation'"
        class="noMobile prequal-par"
      >
        Good news! You've found the only loan designed to help you get out of debt faster.
      </p>
      <p
        v-else-if="applicantData.payload.loanType ==='Home Improvement'"
        class="noMobile prequal-par"
      >
        Good news! You can start upgrading your home with the only loan built to help you take control of your debt.
      </p>
      <p
        v-else-if="applicantData.payload.loanType ==='Auto Refinance'"
        class="noMobile prequal-par"
      >
        Good news! You're about to get a better car payment with the only loan designed to help you get out of debt faster.
      </p>
      <p
        v-else
        class="noMobile prequal-par"
      >
        Good news! You've found the only loan built to help you take control of your debt.
      </p>
    </div>
    <div
      v-else
      class="qualification-message"
    >
      <h1>
        Thanks for applying
        <br>{{ applicantData.payload.firstName }} {{ applicantData.payload.lastName }}
      </h1>
    </div>

    <div class="editable-information col-md-4">
      <div class="information-display">
        <p>Name: {{ applicantData.payload.firstName }} {{ applicantData.payload.lastName }}</p>
        <p>Email: {{ applicantData.payload.email | decodeUri }}</p>
        <p>Phone: {{ applicantData.payload.phone | decodeUri }}</p>
      </div>
      <div class="amount-info">
        <p v-if="applicantData.payload.loanType === 'VEHICLE'">
          Auto refinance loan amount:
        </p>
        <p v-else-if="applicantData.payload.loanType === 'PERSONAL'">
          Personal loan amount:
        </p>
        <div class="qual-amount">
          {{ applicantData.storageData.amountApproved | toCurrency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    applicantData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
}
</script>