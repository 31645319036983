<template>
  <div class="form-container">
    <div
      v-if="isAuto"
      class="form-section vehicle-section"
    >
      <h3>Vehicle information</h3>
      <p>
        Tell us a little about your car.
      </p>
      <div class="form-row">
        <div class="form-group col-lg-4 select">
          <select
            v-model="formData.vehicleYear"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': errors.has('prequal.vehicleYear') }"
            :disabled="formDataDefaults.vehicleYears.length === 0"
            name="vehicleYear"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
            @change="onChangeYear($event)"
          >
            <option
              value="null"
              selected
              disabled
            >
              Vehicle year*
            </option>
            <option
              v-for="(year, index) in formDataDefaults.vehicleYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <span
            v-show="errors.has('prequal.vehicleYear')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.vehicleYear') ? 'Choose vehicle year' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-4 select">
          <select
            v-model="formData.vehicleMake"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': errors.has('prequal.vehicleMake') }"
            :disabled="formDataDefaults.vehicleMakes.length === 0"
            name="vehicleMake"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
            @change="onChangeMake($event)"
          >
            <option
              value="null"
              selected
              disabled
            >
              Vehicle make*
            </option>
            <option
              v-for="(make, index) in formDataDefaults.vehicleMakes"
              :key="index"
              :value="make"
            >
              {{ make }}
            </option>
          </select>
          <span
            v-show="errors.has('prequal.vehicleMake')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.vehicleMake') ? 'Choose vehicle make' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-4 select">
          <select
            v-model="formData.vehicleModel"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': errors.has('prequal.vehicleModel') }"
            :disabled="formDataDefaults.vehicleModels.length === 0"
            name="vehicleModel"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
          >
            <option
              value="null"
              selected
              disabled
            >
              Vehicle model*
            </option>
            <option
              v-for="(model, index) in formDataDefaults.vehicleModels"
              :key="index"
              :value="model"
            >
              {{ model }}
            </option>
          </select>
          <span
            v-show="errors.has('prequal.vehicleModel')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.vehicleModel') ? 'Choose vehicle model' : '' }}
          </span>
        </div>
      </div> <!-- end form-row -->
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="formData.estimatedMileage"
            v-validate="'required|numeric|min_value:1|max_value:999999'"
            name="estimatedMileage"
            type="text"
            placeholder="Est. mileage*"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.estimatedMileage') }"
            @input="preventNonNumericInput"
          >
          <span
            v-show="errors.has('prequal.estimatedMileage')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.estimatedMileage')?'Enter estimated mileage':'' }}
          </span>
        </div>
        <div class="form-group col-lg-6">
          <input
            v-model="formattedData.loanBalanceFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            type="text"
            placeholder="Loan balance*"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.loanBalance') }"
          >
          <input
            v-model="formData.loanBalance"
            v-validate="'required|min_value:1|max_value:30000'"
            name="loanBalance"
            type="hidden"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
          >
          <span
            v-show="errors.has('prequal.loanBalance')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.loanBalance')?'Enter loan balance':'' }}
          </span>
        </div>
      </div> <!-- end form-row -->
    </div> <!-- end form-section -->
    <div
      v-else-if="isPersonal"
      class="form-section"
    >
      <h3>Loan amount</h3>
      <p>
        First, let's talk money!
      </p>
      <div class="form-row">
        <div class="form-group col-lg-6 select">
          <select
            v-model="formData.loanPurpose"
            v-validate="'required'"
            :class="{ 'form-control': true, 'is-invalid': errors.has('prequal.loanPurpose') }"
            name="loanPurpose"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
          >
            <option
              value="null"
              selected
              disabled
            >
              Loan purpose*
            </option>
            <option
              v-for="(purpose, index) in formDataDefaults.loanPurposes"
              :key="index"
              :value="purpose"
            >
              {{ purpose | sentencecase }}
            </option>
          </select>
          <span
            v-show="errors.has('prequal.loanPurpose')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.loanPurpose') ? 'Choose loan purpose' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-6">
          <input
            v-model="formattedData.loanAmountFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            type="text"
            placeholder="Preferred loan amount*"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.loanAmount') }"
          >
          <input
            v-model="formData.loanAmount"
            v-validate="'required|min_value:1|max_value:30000'"
            name="loanAmount"
            type="hidden"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
          >
          <span
            v-show="errors.has('prequal.loanAmount')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.loanAmount')?'Enter preferred loan amount':'' }}
          </span>
        </div>
      </div> <!-- end form-row -->
    </div> <!-- end form-section -->
    <div class="form-section">
      <h3>Personal information</h3>
      <p>
        Next, we'll need a few tidbits about you!
      </p>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model.trim="formData.firstName"
            v-validate="{ required: true, regex: regexRules.alphaDash }"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.firstName') }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="text"
            name="firstName"
            data-vv-scope="prequal"
            placeholder="First name*"
          >
          <span
            v-show="errors.has('prequal.firstName')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.firstName') ? 'Enter first name' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-6">
          <input
            v-model.trim="formData.lastName"
            v-validate="{ required: true, regex: regexRules.alphaDashApos }"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.lastName') }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="text"
            name="lastName"
            data-vv-scope="prequal"
            placeholder="Last name*"
          >
          <span
            v-show="errors.has('prequal.lastName')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.lastName') ? 'Enter last name' : '' }}
          </span>
        </div>
      </div><!-- End form-inputs -->
      <div class="form-row">
        <div class="form-group col-lg-12">
          <address-autocomplete
            address-type="fullAddress"
            :validation-errors="errors"
            :form-data-parent="formData.addressData"
            :form-scope="'prequal'"
            @acUpdate="handleAutoCompleteData($event)"
          />
          <input
            v-model="formData.addressData.fullAddress"
            v-validate="'required'"
            name="address"
            type="hidden"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.address') }"
          >
          <span
            v-show="errors.has('prequal.address')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.address')?'Enter address':'' }}
          </span>
        </div>
      </div><!-- End form-row -->
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="formData.dob"
            v-validate="{ required: true, date_format: 'MM/dd/yyyy', isOldRange: true, isOld: true }"
            v-mask="'##/##/####'"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.dob') }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="text"
            name="dob"
            data-vv-scope="prequal"
            placeholder="Birthdate* (MM/DD/YYYY)"
          >
          <span
            v-show="errors.has('prequal.dob')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.dob') ? 'Enter birthdate' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-6">
          <input
            v-model="formData.phone"
            v-validate="'required|isUsPhone'"
            v-mask="'(###) ###-####'"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.phone') }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="text"
            name="phone"
            data-vv-scope="prequal"
            placeholder="Mobile number*"
          >
          <span
            v-show="errors.has('prequal.phone')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.phone') ? 'Enter mobile number' : '' }}
          </span>
        </div>
        <div class="form-group col-lg-12">
          <input
            v-model="formData.email"
            v-validate="'required|email'"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.email') }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="text"
            name="email"
            data-vv-scope="prequal"
            placeholder="Email*"
          >
          <span
            v-show="errors.has('prequal.email')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.email') ? 'Enter email' : '' }}
          </span>
        </div>
      </div><!-- End form-row -->
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="formattedData.annualIncomeFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            type="text"
            placeholder="Annual income*"
            :class="{'form-control': true, 'is-invalid': errors.has('prequal.annualIncome') }"
          >
          <input
            v-model="formData.annualIncome"
            v-validate="'required|min_value:1|max_value:999999'"
            name="annualIncome"
            type="hidden"
            data-vv-validate-on="none"
            data-vv-scope="prequal"
          >
          <span
            v-show="errors.has('prequal.annualIncome')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.annualIncome')?'Enter annual income':'' }}
          </span>
        </div>
        <div class="form-group col-lg-6 ssn-field">
          <b-button
            v-b-tooltip.hover.right.v-light="{ customClass: 'ssn-tooltip' }"
            class="tooltip-button"
            title="Our lending partners need this to provide you with personalized rates. Checking rates won't affect your credit score."
          >
            <font-awesome-icon icon="info-circle" />
          </b-button>
          <span
            class="toggle-ssn"
            @click="toggleSsnStatus"
          >
            <font-awesome-icon
              v-if="ssnToggleStatusLabel === 'show'"
              icon="eye"
            />
            <font-awesome-icon
              v-if="ssnToggleStatusLabel === 'hide'"
              icon="eye-slash"
            />
          </span>
          <input
            v-model="formattedData.ssnFormatted"
            v-mask="'###-##-####'"
            :class="{ 'form-control': true, 'is-invalid': errors.has('prequal.ssn') }"
            autocomplete="new-input"
            :type="ssnToggleStatus"
            placeholder="Social Security number*"
          >
          <input
            v-model="formData.ssn"
            v-validate="{ required: true, numeric: true, length: 9 }"
            :class="{ 'form-control': true }"
            autocomplete="new-input"
            data-vv-validate-on="none"
            type="hidden"
            name="ssn"
            data-vv-scope="prequal"
            maxlength="9"
          >
          <span
            v-show="errors.has('prequal.ssn')"
            class="invalid-feedback"
          >
            {{ errors.first('prequal.ssn') ? 'Enter SSN' : '' }}
          </span>
        </div>
      </div><!-- End form-row -->
    </div> <!-- end form-section -->
    <div class="form-section last">
      <modal
        ref="creditModal"
      >
        <template v-slot:body>
          <p>You understand that by clicking on the Continue button immediately following this notice you are providing 'written instructions' to Kasasa under the Fair Credit Reporting Act authorizing Kasasa to obtain information from your personal credit profile or other information from Experian. You authorize Kasasa to obtain such information solely to:<br> QUALIFY YOU FOR LOAN OPTIONS.</p>
        </template>
      </modal>
      <div class="disclaimer">
        <p>
          By continuing, you agree to
          <a
            href="#"
            @click.prevent="$refs.creditModal.openModal()"
          >credit authorization</a> from Kasasa and to receive marketing communications, offers, and recommendations from Kasasa and our partners.
        </p>
      </div>
      <div class="submit">
        <button
          class="next btn btn-primary btn-block"
          type="button"
          :disabled="submitting"
          @click.prevent="submitPrequal()"
        >
          <div class="btn-text">
            <span
              v-if="submitting"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            {{ (submitting) ? 'Loading..' : 'Continue' }} <font-awesome-icon icon="caret-right" />
          </div>
        </button>
      </div>
    </div> <!-- end form-section -->
    <modal ref="confirmPrequal">
      <template v-slot:body>
        Confirmation
      </template>
    </modal>
  </div>
</template>

<script>
import { formDataDefaults } from '@/utils/formDataDefaults'
import { getVehicleModelsByYearByMake, getVehicleMakeByYear, getVehicleYears } from '@/api/vehicle.api';
import { createApplication, getApplicationStatus } from '@/api/appservice.api';
import { preventNonNumericInput, closeInputErrorValidation, allowCloseErrorValidation, delayPoll, regexRules } from '@/utils/utils'
import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: { AddressAutocomplete, Modal },
  props: {
    loanType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      regexRules,
      formDataDefaults,
      formData: {
        vehicleYear: null,
        vehicleMake: null,
        vehicleModel: null,
        estimatedMileage: null,
        loanAmount: null,
        loanBalance: null,
        loanPurpose: null,
        firstName: null,
        lastName: null,
        ssn: null,
        dob: null,
        phone: null,
        email: null,
        annualIncome: null,
        addressData: {
          fullAddress: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          county: ''
        },
      },
      ssnToggleStatus: 'password',
      ssnToggleStatusLabel: 'show',
      formattedData: {
        loanAmountFormatted: null,
        loanBalanceFormatted: null,
        annualIncomeFormatted: null,
        ssnFormatted: null
      },
      isAuto: null,
      isPersonal: null,
      submitting: false,
    }
  },
  watch: {
    'formattedData.loanAmountFormatted': function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.loanAmount = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.loanAmount = '';
      }
    },
    'formattedData.loanBalanceFormatted': function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.loanBalance = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.loanBalance = '';
      }
    },
    'formattedData.annualIncomeFormatted': function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.annualIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.annualIncome = '';
      }
    },
    'formattedData.ssnFormatted': function (val) {
      if (val !== null) {
        this.formData.ssn = val.replace(/-/g,'');
      }
      if (val === '') {
        this.formData.ssn = '';
      }
    },
  },
  created() {
    this.setLoanType();

    if ( this.isPersonal ) {
      this.setLoanPurpose();
    }

    this.initFields();
  },
  methods: {
    async getVehicleModels() {
      if ( this.formData.vehicleMake !== null ) {
        try {
          const response = await getVehicleModelsByYearByMake(this.formData.vehicleYear, this.formData.vehicleMake);
          this.formDataDefaults.vehicleModels = response.data;
        } catch(error) {
          this.$root.$emit('triggerTechnicalErrorModal');
          console.log(error);
        }
      }
    },
    async getVehicleMakes() {
      if ( this.formData.vehicleYear !== null ) {
        try {
          const response = await getVehicleMakeByYear(this.formData.vehicleYear);
          this.formDataDefaults.vehicleMakes = response.data;
        } catch(error) {
          this.$root.$emit('triggerTechnicalErrorModal');
          console.log(error);
        }
      }
    },
    onChangeYear(event){
      // If year changes, reset vehicle make, model, trims data
      this.formData.vehicleMake = null;
      this.formData.vehicleModel = null;
      this.formDataDefaults.vehicleMakes = [];
      this.formDataDefaults.vehicleModels = [];

      // Get vehicle makes based on year
      this.getVehicleMakes();
    },
    onChangeMake(event){
      // If make changes, reset vehicle model and trim data
      this.formData.vehicleModel = null;
      this.formDataDefaults.vehicleModels = [];

      // Get vehicle models based on year and make
      this.getVehicleModels();
    },
    preventNonNumericInput,
    async initFields() {
      if ( this.isAuto ) {
        // Get vehicle years
        try {
          const response = await getVehicleYears();
          this.formDataDefaults.vehicleYears = response.data;
        } catch(error) {
          this.$root.$emit('triggerTechnicalErrorModal');
          console.log(error);
        }
      }
    },
    handleAutoCompleteData: function (e) {
      [this.formData.addressData.fullAddress, this.formData.addressData.address, this.formData.addressData.city, this.formData.addressData.state, this.formData.addressData.zip, this.formData.addressData.county] = e;
    },
    toggleSsnStatus() {
      this.ssnToggleStatus = ( this.ssnToggleStatus === 'password' ) ? 'text' : 'password';
      this.ssnToggleStatusLabel = ( this.ssnToggleStatus === 'password' ) ? 'show' : 'hide';
    },
    setLoanType() {
      if ( this.loanType === 'auto' ) {
        this.isAuto = true;
      } else if ( this.loanType === 'personal' ) {
        this.isPersonal = true;
      }
    },
    setLoanPurpose() {
      const queryParams = this.$route.query;

      // Only prefill if available and one of the preselected purposes
      if ( !!queryParams.purpose ) {
        if ( this.formDataDefaults.loanPurposes.includes(queryParams.purpose.trim()) ) {
          this.formData.loanPurpose = queryParams.purpose.trim();
        }
      }
    },
    validate(scope) {
      // if scope validate fields in scope
      return this.$validator.validateAll(scope);
    },
    submitPrequal() {
      const scope = 'prequal';

      this.validate(scope).then((valid) => {
        if (valid) {
          console.log('valid and can submit');
          this.submitting = true;
          this.sendToApplicationService();
        } else {
          closeInputErrorValidation();
        }
      });

      allowCloseErrorValidation();
    },
    async sendToApplicationService() {
      const payload = this.getApplicationServicePayload();

      this.submitting = true;

      if ( this.isPolling ) {
        this.getStatusPolling(0);
      } else {
        try {
          const apply = await createApplication(payload);
          this.idvStatus = apply.data.data.status;

          // Store on Vuex
          this.$store.commit({
            type: 'setApplicationId',
            applicationId: apply.data.data.applicationExternalId
          })

          if ( this.idvStatus === 'IDV_SUCCESS' ) {
            this.isPolling = true;
            // Start polling
            this.getStatusPolling(0);
          } else {
            console.log('Could not verify you --- throw modal')
          }
        } catch(e) {
          console.log(e);
          this.submitting = false;
          this.$root.$emit('triggerTechnicalErrorModal');
        }
      }
    },
    async getStatusPolling(retryCount) {
      try {
        const polledData = await getApplicationStatus({
          "applicationExternalId": this.$store.state.applicationId
        });
        this.checkAppStatus(polledData, retryCount)
      } catch (e) {
        console.log(e);
        this.submitting = false;
        this.$root.$emit('triggerTechnicalErrorModal');
      }
    },
    async checkAppStatus(polledData, retryCount) {
      /*
        Application Statuses
        Create Application:
        IDV_SUCCESS - IDV was successful and a call to getRates is in progress (poll)
        IDV_REJECTED - IDV was rejected, the user should not be allowed to continue
        IDV_FAILED - We failed to complete IDV (error state)
        Get Status:
        PREQUALIFIED - User has qualified for rates, continue to results page
        NOT_QUALIFIED - User does not qualify for any rates at this time
        RATES_FAILED - We failed to complete getRates (error state)
        Submit Application:
        SUBMITTED - Submit is in progress (poll)
        APPROVED
        REFERRED
        DECLINED
        SUBMIT_FAILED - We failed to submit the application (error state)
      */
      const status = polledData.data.data.status;

      switch (status) {
        case 'SUBMIT_FAILED':
        case 'NOT_QUALIFIED':
        case 'RATES_FAILED':
        case 'IDV_REJECTED':
        case 'IDV_FAILED':
          // Send to confirmation page with custom message
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          return false;
          break;
        case 'PREQUALIFIED':
          this.sendToResults(polledData.data.data)
          break;
        case 'IDV_SUCCESS':
        default:
          // continue
          if ( retryCount > 10 ) {
            console.log('reached max retries');
            this.maxPollingTriesReached = true;
            this.submitting = false;
            return false;
          }
          await this.delayPoll(retryCount);
          this.getStatusPolling(retryCount + 1);
      }
    },
    delayPoll,
    sendToResults(data) {
      this.$router.push(
        {
          name: 'Loan Results',
          params: {
            resultsData: data
          }
        }
      );
    },
    getApplicationServicePayload() {
      return {
        "loanType": ( this.isAuto ) ? "VEHICLE" : "PERSONAL",
        "loanPurposeType": ( this.isAuto ) ? "Auto Refinance" : this.formData.loanPurpose,
        "amount": ( this.isAuto ) ? this.formData.loanBalance.toString() : this.formData.loanAmount.toString(),
        "firstName": this.formData.firstName,
        "lastName": this.formData.lastName,
        "email": this.formData.email,
        "phoneNumber": this.formData.phone,
        "dob": this.parseDate(this.getFormattedDateBirth(this.formData.dob)).formatted,
        "creditScore": '0',
        "ssn": this.formData.ssn,
        "monthlyIncome": parseInt(parseInt(this.formData.annualIncome) / 12).toString(),
        "address": this.formData.addressData.address,
        "apt": null,
        "city": this.formData.addressData.city,
        "state": this.formData.addressData.state,
        "zip": this.formData.addressData.zip,
        "county": this.formData.addressData.county.replace(' County', ''),
        "vehicle": ( this.isAuto ) ? this.getVehicleDataForPayload() :null,
        "applicationSource": "KasasaWeb",
        "resultsRedirectUrl": 'hosted'
      };
    },
    getVehicleDataForPayload() {
      return {
        "make": this.formData.vehicleMake,
        "model": this.formData.vehicleModel,
        "year": this.formData.vehicleYear,
        "mileage": this.formData.estimatedMileage,
        "loanBalance": this.formData.loanBalance.toString(),
      };
    },
    getFormattedDateBirth(dob) {
      const dateArray = dob.split('/');

      if (dateArray.length) {
        const month = dateArray[0];
        const day = dateArray[1];
        const year = dateArray[2];

        return `${month}${day}${year}`;
      }
      return null;
    },
    parseDate(str) {
      var m = str.match(/^(\d{1,2})(\d{1,2})(\d{4})$/);

      const parsedDate = {
        object: new Date(m[3], m[1]-1, m[2]),
        match: m,
        formatted: `${m[3]}/${m[1]}/${m[2]}`
      };

      return (m) ? parsedDate : null;
    }
  }
}
</script>