import { apiClient } from "@/apiClient";

const createApplication = async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/applications`, payload)
}

const findApplication = async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/applications/find-by-id`, payload);
}

const submitApplication = async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/applications/submit`, payload);
}

const getApplicationStatus = async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/applications/get-status`, payload, { timeout: 7000 });
}

const createChannelPartnerApplication =  async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/channel-partner-applications`, payload);
}

const submitChannelPartnerApplication = async (payload) => {
  return await apiClient.post(`/api/v1/kloans-loan-application/channel-partner-applications/submit`, payload);
}

export {
  getApplicationStatus,
  createApplication,
  submitApplication,
  findApplication,
  createChannelPartnerApplication,
  submitChannelPartnerApplication
}