<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group select position-relative">
        <select
          v-model="form.employmentType.value"
          v-validate="form.employmentType.validators"
          class="form-control"
          :class="{ 'is-invalid': form.employmentType.error.state.isShown }"
          :name="form.employmentType.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Employment type*
          </option>
          <option
            v-for="(type, index) in formDataDefaults.employmentTypes"
            :key="index"
            :value="type"
          >
            {{ type | sentencedash }}
          </option>
        </select>
        <InputError
          :state="form.employmentType.error.state"
          :message="form.employmentType.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.jobTitle.value"
          v-validate="form.jobTitle.validators"
          :name="form.jobTitle.name"
          type="text"
          placeholder="Job title*"
          class="form-control"
          :class="{'is-invalid': form.jobTitle.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.jobTitle.error.state"
          :message="form.jobTitle.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.employersName.value"
          v-validate="form.employersName.validators"
          :name="form.employersName.name"
          type="text"
          placeholder="Employer's name*"
          class="form-control"
          :class="{'is-invalid': form.employersName.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.employersName.error.state"
          :message="form.employersName.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.employersPhone.value"
          v-validate="form.employersPhone.validators"
          v-mask="'(###) ###-####'"
          class="form-control"
          :class="{'is-invalid': form.employersPhone.error.state.isShown }"
          type="text"
          :name="form.employersPhone.name"
          placeholder="Employer's phone number*"
          @input="handleInput"
        >
        <InputError
          :state="form.employersPhone.error.state"
          :message="form.employersPhone.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative select">
        <select
          v-model="form.employmentYears.value"
          v-validate="form.employmentYears.validators"
          class="form-control"
          :class="{ 'is-invalid': form.employmentYears.error.state.isShown }"
          :name="form.employmentYears.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Time of employment* (years)
          </option>
          <option
            v-for="(years, index) in formDataDefaults.residenceYears"
            :key="index"
            :value="years"
          >
            {{ years }}
          </option>
        </select>
        <InputError
          :state="form.employmentYears.error.state"
          :message="form.employmentYears.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group select position-relative">
        <select
          v-model="form.employmentMonths.value"
          v-validate="form.employmentMonths.validators"
          class="form-control"
          :class="{ 'is-invalid': form.employmentMonths.error.state.isShown }"
          :name="form.employmentMonths.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Months*
          </option>
          <option
            v-for="(months, index) in formDataDefaults.residenceMonths"
            :key="index"
            :value="months"
          >
            {{ months }}
          </option>
        </select>
        <InputError
          :state="form.employmentMonths.error.state"
          :message="form.employmentMonths.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-12 mt-4 mb-2"
    >
      <span class="font-barlow-medium color-gray mb-3">Previous employment</span>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group select position-relative">
        <select
          v-model="form.previousEmploymentType.value"
          v-validate="form.previousEmploymentType.validators(isPreviousEmploymentShown)"
          class="form-control"
          :class="{ 'is-invalid': form.previousEmploymentType.error.state.isShown }"
          :name="form.previousEmploymentType.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Previous employment type*
          </option>
          <option
            v-for="(type, index) in formDataDefaults.employmentTypes"
            :key="index"
            :value="type"
          >
            {{ type | sentencedash }}
          </option>
        </select>
        <InputError
          :state="form.previousEmploymentType.error.state"
          :message="form.previousEmploymentType.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group position-relative">
        <input
          v-model="form.previousJobTitle.value"
          v-validate="form.previousJobTitle.validators(isPreviousEmploymentShown)"
          :name="form.previousJobTitle.name"
          type="text"
          placeholder="Previous job title*"
          class="form-control"
          :class="{'is-invalid': form.previousJobTitle.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.previousJobTitle.error.state"
          :message="form.previousJobTitle.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group position-relative">
        <input
          v-model="form.previousEmployersName.value"
          v-validate="form.previousEmployersName.validators(isPreviousEmploymentShown)"
          :name="form.previousEmployersName.name"
          type="text"
          placeholder="Previous employer's name*"
          class="form-control"
          :class="{'is-invalid': form.previousEmployersName.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.previousEmployersName.error.state"
          :message="form.previousEmployersName.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group position-relative">
        <input
          v-model="form.previousEmployersPhone.value"
          v-validate="form.previousEmployersPhone.validators(isPreviousEmploymentShown)"
          v-mask="'(###) ###-####'"
          class="form-control"
          :class="{'is-invalid': form.previousEmployersPhone.error.state.isShown }"
          type="text"
          :name="form.previousEmployersPhone.name"
          placeholder="Previous employer's phone number*"
          @input="handleInput"
        >
        <InputError
          :state="form.previousEmployersPhone.error.state"
          :message="form.previousEmployersPhone.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group position-relative select">
        <select
          v-model="form.previousEmploymentYears.value"
          v-validate="form.previousEmploymentYears.validators(isPreviousEmploymentShown)"
          class="form-control"
          :class="{ 'is-invalid': form.previousEmploymentYears.error.state.isShown }"
          :name="form.previousEmploymentYears.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Time of previous employment* (years)
          </option>
          <option
            v-for="(years, index) in formDataDefaults.residenceYears"
            :key="index"
            :value="years"
          >
            {{ years }}
          </option>
        </select>
        <InputError
          :state="form.previousEmploymentYears.error.state"
          :message="form.previousEmploymentYears.error.message"
        />
      </div>
    </div>

    <div
      v-show="isPreviousEmploymentShown"
      class="col-lg-6"
    >
      <div class="form-group select position-relative">
        <select
          v-model="form.previousEmploymentMonths.value"
          v-validate="form.previousEmploymentMonths.validators(isPreviousEmploymentShown)"
          class="form-control"
          :class="{ 'is-invalid': form.previousEmploymentMonths.error.state.isShown }"
          :name="form.previousEmploymentMonths.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Months*
          </option>
          <option
            v-for="(months, index) in formDataDefaults.residenceMonths"
            :key="index"
            :value="months"
          >
            {{ months }}
          </option>
        </select>
        <InputError
          :state="form.previousEmploymentMonths.error.state"
          :message="form.previousEmploymentMonths.error.message"
        />
      </div>
    </div>
  </div>
  <!-- ./row -->
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';
import { formDataDefaults } from '@/utils/formDataDefaults'

const EmploymentInformation = {
  components: {
    InlineValidationError,
    InputError,
  },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      isPreviousEmploymentShown: false,
      formDataDefaults,
      form: {
        employmentType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'employmentType',
          validators: {
            requiredExtended: {
              message: 'Choose employment type',
            },
          },
        },
        jobTitle: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'jobTitle',
          validators: {
            requiredExtended: {
              message: 'Enter job title',
            },
            specialChars: {
              message: 'Job title cannot have special characters',
            },
          },
        },
        employersName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'employersName',
          validators: {
            requiredExtended: {
              message: `Enter employer's name`,
            },
            specialChars: {
              message: `Employer's name cannot have special characters`,
            },
          },
        },
        employmentYears: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'employmentYears',
          validators: {
            requiredExtended: {
              message: 'Choose time of employment',
            },
          },
        },
        employmentMonths: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'employmentMonths',
          validators: {
            requiredExtended: {
              message: 'Choose time of employment',
            },
          },
        },
        employersPhone: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'employersPhone',
          validators: {
            requiredExtended: {
              message: `Enter employer's phone number`,
            },
            isUsPhone: true,
          }
        },
        previousEmploymentType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousEmploymentType',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: 'Choose employment type',
            },
          })
        },
        previousJobTitle: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousJobTitle',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: 'Enter job title',
            },
            specialChars: isPreviousEmploymentShown && {
              message: 'Job title cannot have special characters',
            },
          })
        },
        previousEmployersName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousEmployersName',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: `Enter employer's name`,
            },
            specialChars: isPreviousEmploymentShown && {
              message: `Employer's name cannot have special characters`,
            },
          })
        },
        previousEmploymentYears: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousEmploymentYears',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: 'Choose time of employment',
            },
          })
        },
        previousEmploymentMonths: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousEmploymentMonths',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: 'Choose time of employment',
            },
          })
        },
        previousEmployersPhone: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousEmployersPhone',
          validators: (isPreviousEmploymentShown) => ({
            requiredExtended: isPreviousEmploymentShown && {
              message: `Enter employer's phone number`,
            },
            isUsPhone: true,
          })
        },
      },
      hasValidated: false,
    };
  },
  methods: {
    handleInputError(input) {
        this.form[input].error = {
          ...this.form[input].error,
          state: {
            ...this.form[input].error.state,
            isShown: this.errors.has(input),
          },
          message: this.errors.first(input),
        };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {

        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.$emit('collect-data', { form: this.form, isValid: true });
        }
      });

      this.hasValidated = true;
    },
    clearPreviouseEmployementFields() {
      this.form.previousEmploymentType.value = null;
      this.form.previousJobTitle.value = null;
      this.form.previousEmployersName.value = null;
      this.form.previousEmploymentYears.value = null;
      this.form.previousEmploymentMonths.value = null;
      this.form.previousEmployersPhone.value = null;
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    }
  },
  watch: {
    event(event) {
      if (event.type === 'VALIDATE') {
        this.validate();
      }
    },
    'form.employmentYears.value': function (value) {
      this.isPreviousEmploymentShown = value <= 1;

      if (!this.isPreviousEmploymentShown) {
        this.clearPreviouseEmployementFields();
      }
    },
  },
  computed: {}
};

export default EmploymentInformation;
</script>