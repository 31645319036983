<template>
  <b-modal
    v-model="isShown"
    hide-footer
    hide-header
    no-fade
  >
    <div class="p-3 text-center d-flex flex-column align-items-center">
      <div>
        <img
          class="mr-1"
          src="@/assets/images/identity.png"
          alt="Identity Error"
        >
      </div>
      <div class="text-center">
        <h3 class="mb-3">
          We could not verify your identity.
        </h3>
      </div>
    </div>
    <div class="d-flex justify-content-end p-3">
      <button
        class="btn btn-secondary btn-block"
        type="button"
        @click="isShown = false"
      >
        <div class="btn-text">
          Close
        </div>
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      default() {
        return {
          isShown: false,
        };
      }
    }
  },
  data() {
    return {
      isShown: false,
    };
  },
  watch: {
    state(state) {
      this.isShown = Boolean(state.isShown);
    }
  }
}
</script>