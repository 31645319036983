<template>
  <footer>
    <div class="container my-4">
      <div class="row">
        <div class="col-lg-12">
          <p>
            <span class="font-weight-600">Loan Description: </span>
            Your Kasasa Loan enables you to borrow money and to pay it back on a fixed schedule just like any other personal loan, but your Kasasa Loan also gives you the option to make prepayments (payments in excess of your scheduled monthly payments) to reduce the total amount of interest you pay. Unlike traditional personal loans, consumers who have met each of their required scheduled payments, can borrow against (e.g., Take-Backs™) these excess funds at the same interest rate as their initial Kasasa Loan to address unexpected needs (e.g., car repairs, health issues) or take advantage of opportunities (e.g., college acceptance) that may arise. The loan's current available credit limit will be specified in each periodic statement issued. Loans originated by TransPecos Banks, a Texas State Chartered Bank.  You understand and agree that any loan you receive may be sold to another lender at any time. Contact the lender for additional information regarding the restrictions and qualifications related to the Take-Back feature. 
          </p>
          <p>
            <span class="font-weight-600">Loan Rates &amp; Terms: </span>
            Kasasa Loans are subject to credit approval by the funding institution. Many factors affect credit approval and the interest rate you may receive.  Upon approval, your loan's Annual Percentage Yield (APR) will not change throughout the life of your loan, unless otherwise mutually agreed to and documented.  Should your application be approved, your Kasasa Loan will begin on the day you sign the funding institution’s loan agreements and will continue until the maturity date or until you have a zero balance in your account.   For illustrative purposes only, assuming a loan value of {{ formatDollar(loanAmount) }} and that you make all of your scheduled payments and do not borrow against any excess funds, your loan works out as follows: 
          </p>
        </div>

        <div class="col-lg-12 mt-4">
          <table class="table color-white">
            <thead>
              <tr>
                <th scope="col">
                  Term
                </th>
                <th scope="col">
                  APRs (as of {{ today }})
                </th>
                <th scope="col">
                  # of Payments
                </th>
                <th scope="col">
                  Monthly Payments
                </th>
                <th scope="col">
                  Total Payments
                </th>
              </tr>
            </thead>
            <tbody>
              <tr               
                v-for="(offer, index) in offers"
                :key="index"
              >
                <td>
                  {{ offer.term }} Months
                </td>
                <td>
                  {{ offer.rate }}%
                </td>
                <td>{{ offer.term }}</td>
                <td>{{ formatDollar(offer.estimatedMonthlyPayment) }}</td>
                <td>{{ formatDollar(offer.totalOfPayments) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-lg-12">
          <p>
            <span class="font-weight-600">Loan Interest Charges &amp; Fees: </span>
            Interest will begin to accrue, with no grace period, on the date advances are posted to your loan. Funding institutions use the daily balance method to calculate the interest on your account.  Your daily balance is determined by adding any new advances to the day’s beginning balance and then subtracting any payments or credits that are made. Funding institutions determine your interest charges by applying a daily periodic rate (i.e., APR / 365) to each daily balance within a billing cycle. In addition to interest, you may also be charged fees for late payments, insufficient funds payments and applicable membership or participation fees.
          </p>
          <p>
            <span class="font-weight-600">Application of Loan Payments: </span>
            All payments are applied first to any accrued interest, then to the loan's principal, then to any outstanding fees.  Any remaining funds will be applied to reduce the loan’s principal balance. Subject to applicable law, payments are applied at the lender’s discretion.
          </p>
          <p>
            <span class="font-weight-600">Additional Information: </span>
            To qualify, a borrower must be at least 18 years old, a U.S. citizen or a permanent resident and must meet the funding institution's underwriting requirements. Not all borrowers receive the lowest rate. To qualify for the lowest rate, you must have a responsible financial history and meet other funding criteria. If approved, your actual rate will be within the range of rates offered by the lender at the time of application and will depend on a variety of factors including the term of the loan, your financial history, work experience, income, and other factors. Rates, fees and terms are subject to change, prior to approval, without notice and are subject to state restrictions. All promotions; credit decisions; underwriting, funding; sale and servicing activities associated with any loan may be performed by one or more participating community financial institutions. Additional information can be obtained from your funding source
          </p>
          <p>
            <span class="font-weight-600">Trademarks: </span>
            Kasasa, Kasasa Loans and Take-Backs are trademark of Kasasa, Ltd., registered in the U.S.A.   All trademarks, brands, logos, and copyright images are property of their respective owners and rights holders.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { formatDollar } from '@/utils/utils';
import { format } from 'date-fns';

const PartnerOffersPageFooter = {
  props: {
    offers: {
      type: Array,
      default() {
        return [];
      }
    },
    loanAmount: {
      type: String,
      default() {
        return '0';
      }
    },
  },
  data() {
    return {
      formatDollar,
    };
  },
  computed: {
    today() {
      return format(new Date(), 'MM/dd/yyyy');
    },
  },
  methods: {
    handleSelect() {
      this.$emit('select', this.offer);
    },
  },
};

export default PartnerOffersPageFooter;
</script>
<style lang="scss" scoped>
</style>
