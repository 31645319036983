export const initialize = () => {
	if (window && window.dataLayer) {
		return;
	}

	window.dataLayer = [];
};

export const addEvent = (event) => {
	if (!window || !window.dataLayer) {
		console.warn('dataLayer not initialized');
		return;
	}

	console.log({ event });
	window.dataLayer.push(event);
}

export const addPageLoadEvent = (page) => {
	const event = {
		event: 'pageLoad',
		page: {
			...page,
			timestamp: new Date().getTime(),
		},
	};

	addEvent(event);
};

export const addEcommerceImpressionViewEvent = (impressions) => {
	const event = {
		event: 'ecommerce.impressionView',
		ecommerce: {
			impressions,
		},
	};

	addEvent(event);
};

export const addEcommerceImpressionClickEvent = ({
	actionField,
	products,
}) => {
	const event = {
		event: 'ecommerce.impressionClick',
		ecommerce: {
			click: {
				actionField,
				products,
			},
		},
	};

	addEvent(event);
};

export const addEcommerceAddEvent = ({
	actionField,
	products,
}) => {
	const event = {
		event: 'ecommerce.add',
		ecommerce: {
			add: {
				actionField,
				products,
			},
		},
	};

	addEvent(event);
};

export const addEcommerceRemoveEvent = ({
	actionField,
	products,
}) => {
	const event = {
		event: 'ecommerce.remove',
		ecommerce: {
			remove: {
				actionField,
				products,
			},
		},
	};

	addEvent(event);
};

export const addEcommercePurchaseEvent = ({
	currencyCode,
	purchase,
	products,
}) => {
	const event = {
		event: 'ecommerce.purchase',
		ecommerce: {
			currencyCode,
			purchase: {
				...purchase,
				products,
			},
		},
	};

	addEvent(event);
};
