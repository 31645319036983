<template>
  <div class="payment-info">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton width="85%" />
        <b-skeleton width="55%" />
        <b-skeleton width="70%" />
        <b-skeleton width="85%" />
        <b-skeleton width="55%" />
      </template>
      <div class="monthly">
        ${{ installmentAmount | toFixedTwo }}
        <div class="occurence">
          monthly payment
        </div>
      </div>
      <div class="info">
        <div class="amount-approved">
          {{ amountApproved | toCurrency }}
        </div>
        <div class="rate-term">
          {{ term }}
          <span>months</span>
        </div>
        <div class="rate-apr">
          {{ rate | toFixedTwo }}%
          <span>APR</span>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
export default {
  filters: {
    toCurrency: function (value) {
       if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    toFixedTwo: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      return value.toFixed(2);
    }
  },
  props: {
    installmentAmount: {
      type: Number,
      default: 0,
    },
    amountApproved: {
      type: Number,
      default: 0
    },
    term: {
      type: Number,
      default: 12
    },
    rate: {
      type: Number,
      default: 0.0
    }
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    const self = this;
    // Fake loading details
    setTimeout(function(){
      self.loading = false;
    }, 300);
  }
}
</script>