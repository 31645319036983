<template>
  <div class="col-12 results-section">
    <h4>{{ approvedAmount | toCurrencyNoFraction }} Loan</h4>
    <div class="offers">
      <ul
        v-for="(offer, index) in resultsData.data.offers"
        :key="index"
        @click="handleOfferClick(offer, index)"
      >
        <li>${{ offer.estimatedMonthlyPayment }}/ month</li>
        <li>{{ offer.term }} months</li>
        <li>{{ offer.rate }}% APR</li>
        <li>
          <button
            class="btn"
            @click="selectOffer(offer, index)"
          >
            Select this option
          </button>
        </li>
      </ul>
    </div>
    <modal
      ref="selectedOfferModal"
      :close-button="false"
    >
      <template v-slot:body>
        <div class="selected-offer-modal">
          <div class="selected-offer-content">
            <p>You selected:</p>
            <h3>
              ${{ selectedOffer.approvedAmount }} Kasasa Loan<sup>&reg;</sup><br>
              ${{ selectedOffer.estimatedMonthlyPayment }}/ month<br>
              {{ selectedOffer.term }} months<br>
              {{ selectedOffer.rate }}% APR
            </h3>
          </div>
          <div class="button-row">
            <p>Is this right?</p>
            <button
              class="btn btn-primary btn-block"
              type="button"
              :disabled="isPolling || submitting"
              @click="acceptOffer"
            >
              <span
                v-if="isPolling || submitting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              {{ (isPolling || submitting) ? 'Loading..' : 'Yes, submit' }}
            </button>
            <button
              v-if="!submitting"
              class="btn btn-secondary btn-block"
              @click="declineOffer"
            >
              No, change loan terms
            </button>
          </div>
        </div>
      </template>
    </modal>
    <div class="disclaimer">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { delayPoll } from '@/utils/utils'
import { submitChannelPartnerApplication, getApplicationStatus } from '@/api/appservice.api';
import { GtmService } from '@/services/gtm';
import { capitalizeWord } from '@/utils/utils';

export default {
  components: { Modal },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    resultsData: {
      type: Object,
      default() {
        return {}
      }
    },
    partnerName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      selectedOffer: null,
      isPolling: false,
      submitting: false
    }
  },
  computed: {
    approvedAmount() {
      return this.resultsData.data.offers[0].approvedAmount;
    },
    applicationExternalId() {
      return this.resultsData.data.applicationExternalId;
    },
  },
  mounted() {
	this.$store.commit('setSelectedChannelPartnerOffer', { selectedChannelPartnerOffer: undefined });

	const impressions = this.resultsData.data.offers
		.map((offer, index) => ({
			id: 'Home Improvement',
			name: 'Personal Loan',
			category: 'Personal/Kasasa Loans/Personal Loan',
			list: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
			variant: String(offer.term),
			dimension6: String(offer.rate),
			brand: 'Partner',
			position: index + 1,
		}));

	GtmService.addEcommerceImpressionViewEvent(impressions || []);
  },
  methods: {
	handleOfferClick(offer, index) {
	  GtmService.addEcommerceImpressionClickEvent({
		actionField: {
			list: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
		},
		products: [{
			id: 'Home Improvement',
			name: 'Personal Loan',
			category: 'Personal/Kasasa Loans/Personal Loan',
			variant: String(offer.term),
			dimension6: String(offer.rate),
			brand: 'Partner',
			position: index + 1,
		}],
	  });
	},
    selectOffer(offer, index) {
		this.selectedOffer = offer;
		this.$refs.selectedOfferModal.openModal();

		GtmService.addEcommerceAddEvent({
			actionField: {
				list: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
			},
			products: [{
				id: 'Home Improvement',
				name: 'Personal Loan',
				category: 'Personal/Kasasa Loans/Personal Loan',
				variant: String(offer.term),
				dimension6: String(offer.rate),
				brand: 'Partner',
				quantity: 1,
				position: index + 1,
			}],
		});

		GtmService.addPageLoadEvent({
			pageType: 'partnerApplication',
			consumerType: 'Channel Partner',
			fiId: String(this.$store.state.channelPartner.fiId),
			fiName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
			pageName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}: Personal Loan Selection`,
			productType: 'Personal Loan',
			loanPurpose: 'Home Improvement',
			amount: this.formData.loanAmount.toString(),
			variant: String(offer.term),
			dimension6: String(offer.rate),
			creditScore: this.$store.state.creditScore,
			websiteType: 'Partner Loans',
		});
    },
    acceptOffer() {
      this.isSubmitted = true;
      this.submit();
    },
    declineOffer() {
      this.$refs.selectedOfferModal.closeModal();

	  GtmService.addEcommerceRemoveEvent({
		actionField: {
			list: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
		},
		products: [{
			id: 'Home Improvement',
			name: 'Personal Loan',
			category: 'Personal/Kasasa Loans/Personal Loan',
			variant: String(this.selectedOffer.term),
			dimension6: String(this.selectedOffer.rate),
			brand: 'Partner',
			quantity: 1,
			position: index + 1,
		}],
	  });

	  this.selectedOffer = {};

	  this.$store.commit('setSelectedChannelPartnerOffer', { selectedChannelPartnerOffer: undefined });
    },
    async submit() {
      const payload = {
        "applicationExternalId": this.applicationExternalId,
        "term": this.selectedOffer.term
      };

      if ( this.isPolling ) {
        this.getStatusPolling(0);
      } else {
        try {
          this.submitting = true;
          const apply = await submitChannelPartnerApplication(payload);
          this.isPolling = true;
          this.getStatusPolling(0);
        } catch(e) {
          console.log(e);
        //   this.$refs.errorModal.closeButton = true;
          this.$refs.errorModal.showModal('technical');
          this.submitting = false;
        }
      }
    },
    async getStatusPolling(retryCount) {
      try {
        const polledData = await getApplicationStatus({
          "applicationExternalId": this.applicationExternalId
        });
        this.checkAppStatus(polledData, retryCount)
      } catch (e) {
        console.log(e);
        this.$refs.errorModal.closeButton = true;
        this.$refs.errorModal.showModal('technical');
        this.submitting = false;
      }
    },
    async checkAppStatus(polledData, retryCount) {
      // Submit Application:
      // SUBMITTED - Submit is in progress (poll)
      // APPROVED
      // REFERRED
      // DECLINED
      // SUBMIT_FAILED - We failed to submit the application (error state)

      const status = polledData.data.data.status;

      switch (status) {
        case 'SUBMIT_FAILED':
          // Send to confirmation page with custom message
          console.log(status);
          this.isPolling = false;
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          return false;
          break;
        case 'APPROVED':
        case 'REFERRED':
        case 'DECLINED':
          // Send to confirmation page with custom message
          this.isPolling = false;
          this.offerData = polledData.data;

		  this.$store.commit('setSelectedChannelPartnerOffer', { selectedChannelPartnerOffer: this.selectedOffer });

          this.sendToConfirmation({
            status,
            loanNumber: this.offerData.data.loanNumber
          })
          break;
        case 'SUBMITTED':
        default:
          // continue
          if ( retryCount > 10 ) {
            console.log('reached max retries');
            this.maxPollingTriesReached = true;
            this.submitting = false;
            return false;
          }
          await delayPoll(retryCount);
          this.getStatusPolling(retryCount + 1);
      }
    },
    sendToConfirmation(data) {
      this.$router.push(
        {
          name: 'Confirmation',
          params: {
            headerType: 'partner',
            partnerName: this.partnerName,
            applicant: {
              firstName: this.formData.firstName,
              lastName: this.formData.lastName,
              applicationId: this.applicationExternalId,
              status: data.status,
              loanNumber: data.loanNumber
            },
          }
        }
      );
    },
  }
}
</script>