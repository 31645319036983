<template>
  <b-modal
    v-model="isShown"
    hide-footer
    hide-header
    no-fade
  >
    <div class="p-3 text-center d-flex flex-column align-items-center">
      <div
        style="width: 150px"
        v-html="getIcon()"
      />

      <div class="text-center">
        <h3 class="mb-3">
          It’s not you... it’s technical difficulties.
        </h3>
        <p>
          Sorry, we’re experiencing a system issue. We’ll let you know when we’re up and running again. Please check back in later.
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-end p-3">
      <button
        class="btn btn-secondary btn-block"
        type="button"
        @click="isShown = false"
      >
        <div class="btn-text">
          Close
        </div>
      </button>
    </div>
  </b-modal>
</template>

<script>
import { technicalIssueIcon } from '@/svgs';

export default {
  components: {
  },
  props: {
    state: {
      type: Object,
      default() {
        return {
          isShown: false,
        };
      }
    }
  },
  data() {
    return {
      isShown: false,
    };
  },
  watch: {
    state(state) {
      this.isShown = Boolean(state.isShown);
    }
  },
  methods: {
    getIcon() {
      return technicalIssueIcon;
    }
  },
}
</script>