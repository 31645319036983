<template>
  <div class="application-page">
    <nav-bar />
    <page-header layout="application" />
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-3">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </template>
            <side-bar
              :fi-id="fiId"
              :fi-name="fiName"
              :loan-details="loanDetails"
              :data-available="dataAvailable"
            />
          </b-skeleton-wrapper>
        </div>
        <div class="col-lg-7">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </template>
            <form-app
              v-if="loanType && dataAvailable"
              :loan-type="loanType"
              :fi-id="fiId"
              :loan-details="loanDetails"
            />
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
    <error-modal ref="errorModal" />
    <page-footer />
  </div>
</template>

<script>
import { apiClient } from '@/apiClient';
import NavBar from '@/components/NavBar.vue';
import PageFooter from '@/components/PageFooter.vue';
import SideBar from '@/components/SideBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import FormApp from '@/components/FormApp.vue';
import ErrorModal from '@/components/ErrorModal.vue';

export default {
  components: {
    NavBar,
    PageFooter,
		SideBar,
    PageHeader,
    FormApp,
    ErrorModal
	},
	data() {
		return {
      fiDataEndpoint: "https://api.hubapi.com/cms/v3/hubdb/tables/5290767/rows",
      applicationId: null,
      loanType: null,
      loanPurpose: null,
      loanDetails: null,
      fiId: null,
      fiName: null,
      dataAvailable: false,
      loading: true,
      showError: false
    }
	},
  created() {
    this.init();
  },
  mounted() {
    if ( this.showError ) {
      this.showErrorModal();
    }
  },
  methods: {
    paramsAvailable(params) {
      if (
        !!params.application_id &&
        !!params.installment_amount &&
        !!params.term &&
        !!params.rate &&
        !!params.credit_score
        ) {
        return true;
      }
      return false;
    },
    minimumData() {
      let minimumParams = false;

      if ( !!this.applicationId && !!this.loanType && !!this.loanPurpose && !!this.loanDetails ) {
        minimumParams = true;
      }

      return minimumParams;
    },
    async init() {
      const queryParams = this.$route.query;

      if (this.paramsAvailable(queryParams)) {
        try {
          const applicationId = queryParams.application_id;
          const applicationDataResponse = await this.getApplicationData(applicationId);

          this.$store.commit({
            type: 'setApplicationData',
            applicationData: applicationDataResponse.data.data
          });

          this.setupState(queryParams);
          this.getFiDetails();
          this.checkAppStatus();

		  this.dataAvailable = true;

          if ( this.minimumData() && this.checkAppStatus() ) {
            this.dataAvailable = true;
          } else {
            this.showError = true;
          }
        } catch(e) {
          console.error(e)
          this.showErrorModal();
        }
      } else {
        this.showError = true;
      }
    },
    setupState(queryParams) {
      // Setup uuid and store
      this.$store.commit({
        type: 'setUuid',
        uuid: this.$uuid.v4()
      })

      this.applicationId = queryParams.application_id;
      // Store on Vuex
      this.$store.commit({
        type: 'setApplicationId',
        applicationId: this.applicationId
      })

      // Leave in for now...send from results page
      if ( !!queryParams.credit_score ) {
        this.creditScore = queryParams.credit_score;
        // Store on Vuex
        this.$store.commit({
          type: 'setCreditScore',
          creditScore: this.creditScore
        })
      }

      this.loanType = this.$store.state.applicationData.loanType;

      // Store on Vuex
      this.$store.commit({
        type: 'setLoanType',
        loanType: this.loanType
      })

      this.loanPurpose = this.$store.state.applicationData.loanPurposeType;
      // Store on Vuex
      this.$store.commit({
        type: 'setLoanPurpose',
        loanPurpose: this.loanPurpose
      })

      // Setup loanDetails from params
      this.loanDetails = this.getLoanDetails(queryParams);

      // Store on Vuex
      this.$store.commit({
        type: 'setLoanDetails',
        loanDetails: this.loanDetails
      })

      // Setup FI from params
      this.fiId = this.getFiId(this.$store.state.applicationData);

      // Store on Vuex
      this.$store.commit({
        type: 'setFiId',
        fiId: this.fiId
      })
    },
    getLoanDetails(params) {
      return {
        installmentAmount: parseFloat(params.installment_amount) || null,
        amountApproved: parseFloat(this.$store.state.applicationData.amount) || null,
        term: parseFloat(params.term) || null,
        rate: parseFloat(params.rate) || null,
        loanPurpose: this.loanPurpose || null,
      };
    },
    getFiId(data) {
      return parseFloat(data.fiId) || null;
    },
    async getApplicationData(applicationExternalId) {
      const payload = {
        "applicationExternalId": applicationExternalId
      };
      return await apiClient.post(`/api/v1/kloans-loan-application/applications/find-by-id`, payload);
    },
    async getFiDetails() {
      const payload = {
        portalId: '4488620',
        financialid: this.fiId
      };

      try {
        const fiData = await apiClient.get(this.fiDataEndpoint, {
          params: payload
        });

        this.fiName = fiData.data.results[0].values.accountname;
        // Store on Vuex
        this.$store.commit({
          type: 'setFiName',
          fiName: this.fiName
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    showErrorModal() {
      this.$refs.errorModal.returnButton = true;
      this.$refs.errorModal.showModal('technical');
    },
    checkAppStatus() {
      const status = this.$store.state.applicationData.status;

      switch (status) {
        case 'SUBMIT_FAILED':
          // Send to confirmation page with custom message
          this.showErrorModal();
          return false;
          break;
        case 'SUBMITTED':
          // Send to confirmation page with custom message
          this.sendToConfirmation();
          break;
        case 'APPROVED':
        case 'REFERRED':
        case 'DECLINED':
          // Send to confirmition with respective message
          this.sendToConfirmation();
          break;
        case 'NEW':
        case 'PREQUALIFIED':
        default:
          // continue
          return true;
      }

    },
    sendToConfirmation() {
      this.$router.push(
        {
          name: 'Confirmation',
          params: {
            applicant: {
              firstName: this.$store.state.applicationData.firstName,
              lastName: this.$store.state.applicationData.lastName,
              applicationId: this.$store.state.applicationId,
              status: this.$store.state.applicationData.status,
              loanNumber: this.$store.state.applicationData.loanNumber
            }
          }
        }
      ).catch(()=> {});
    },
  }
}
</script>