<template>
  <div class="application-page channel-partner fade-in">
    <div
      class="channel-partner-header no-border"
    >
      <div>
        <img
          :src="logoSrc"
          class="channel-partner-logo"
          :alt="`${partnerName} logo`"
        >
      </div>
    </div>

    <div class="subheader">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-11 offset-lg-1">
            <button
              type="button"
              class="btn btn-link color-white"
              @click="handleGoBack"
            >
              <font-awesome-icon icon="caret-left" /> GO HOME
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          v-if="status === 'NOT_QUALIFIED'"
          class="row"
        >
          <div
            class="col-lg-8 offset-lg-2"
          >
            <h1 class="mb-4 font-barlow-light">
              We're sorry, {{ firstName }}.
            </h1>

            <div class="d-flex align-items-center">
              <h3 class="font-barlow-light">
                No offers are available at this time.
              </h3>
            </div>
          </div>
        </div>
        <!-- ./row -->

        <div
          v-if="status === 'REFERRED' || status === 'APPROVED'"
          class="row"
        >
          <div
            class="col-lg-8 offset-lg-2"
          >
            <h1 class="mb-4">
              Great news, {{ firstName }} (drum roll, please).
            </h1>

            <div class="d-flex align-items-center">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="mr-3"
                v-html="getCheckIcon()"
              />
              <div class="font-barlow-light font-size-3">
                You've got pending approval for a Kasasa Loan<sup>&reg;</sup> by TransPecos Banks!
              </div>
            </div>
          </div>
        </div>
        <!-- ./row -->

        <div
          v-if="status === 'DECLINED'"
          class="row"
        >
          <div
            class="col-lg-8 offset-lg-2"
          >
            <h1 class="mb-4">
              We're sorry, {{ firstName }}.
            </h1>
            <h2>
              No offers are available at this time.
            </h2>
          </div>
        </div>
        <!-- ./row -->

        <div
          v-if="status === 'SUBMITTED'"
          class="row"
        >
          <div
            class="col-lg-8 offset-lg-2"
          >
            <h1 class="mb-4">
              Hey, {{ firstName }}.
            </h1>
            <h2>
              Looks like this application's already been submitted.
            </h2>
          </div>
        </div>
        <!-- ./row -->
      </div>
    </div>

    <div class="container mt-5 mb-5">
      <div
        v-if="status === 'REFERRED' || status === 'APPROVED'"
        class="row"
      >
        <div class="col-lg-8 offset-lg-2">
          <div class="font-barlow-bold mb-2 color-dark-gray">
            Application ID: {{ applicationId }}
          </div>
          <p class="mb-4 color-gray">
            You’re even closer to getting the money you need — and a smarter way to pay it off. Your loan officer just needs a few more things from you to finalize your approval.
          </p>
          <p class="mb-5 color-gray">
            You will be contacted soon with further instructions. For now, your approval will be held in a pending state.  Keep your application ID handy – and thank you for choosing the Kasasa Loan<sup>&reg;</sup> from TransPecos Banks!
          </p>
        </div>
      </div>
      <!-- ./row -->

      <div
        v-if="status === 'DECLINED'"
        class="row"
      >
        <div class="col-lg-8 offset-lg-2">
          <p class="mb-5 color-gray">
            We suggest you speak with your {{ partnerName }} representative about other payment options.
          </p>
        </div>
      </div>
      <!-- ./row -->

      <div
        v-if="status === 'SUBMITTED'"
        class="row"
      >
        <div class="col-lg-8 offset-lg-2">
          <div class="font-barlow-medium mb-2 color-dark-gray">
            Application ID: {{ applicationId }}
          </div>
          <p class="mb-5 color-gray">
            No biggie — check your email. We sent an update with details on your application.
          </p>
        </div>
      </div>
      <!-- ./row -->
    </div>

    <PageContainerFooter />
    <PartnerPageFooter />
  </div>
</template>

<script>
import ChannelPartnerHeader from '@/components/ChannelPartnerHeader.vue';
import PartnerForm from '@/components/PartnerForm/PartnerForm.vue';
import PartnerOffer from '@/components/PartnerOffer.vue';
import PageFooter from '@/components/PageFooter.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import { PARTNER_FORM_SECTIONS } from '@/constants/partner-form-sections';
import { confirmationIcon } from '@/svgs';
import PageContainerFooter from '@/components/PageContainerFooter.vue';
import PartnerPageFooter from '@/components/PartnerPageFooter.vue';

const PartnerConfirmationPage = {
  components: {
    ChannelPartnerHeader,
    PartnerForm,
    PageFooter,
    ErrorModal,
    PartnerOffer,
    PageContainerFooter,
    PartnerPageFooter,
	},
	data() {
		return {
      offers: [],
      isSelectedOfferModalShown: false,
      selectedOffer: null,
      pollCount: 0,
      pollCountMax: 10,
      isErrorModalShown: false,
      isSubmitting: false,
      isPolling: false,
      confirmationIcon,
    }
	},
  mounted() {
    this.offers = (this.$store.state.partnerSubmissionData && this.$store.state.partnerSubmissionData.offers) || [];
  },
  computed: {
    partnerName() {
      const { partnerName } = this.$route.params;
      return partnerName;
    },
    logoSrc() {
      return `https://www.kasasa.com/hubfs/channel-partners-logos/${this.partnerName}.png`;
    },
    loanAmount() {
      return (this.$store.state.partnerSubmissionData.approvedAmount && this.$store.state.partnerSubmissionData) || 0;
    },
    applicationId() {
      return (this.$store.state.partnerSubmissionData && this.$store.state.partnerSubmissionData.loanNumber) || '';
    },
    status() {
      if (this.$route.query.status) {
        return this.$route.query.status;
      }

      return (this.$store.state.partnerSubmissionData && this.$store.state.partnerSubmissionData.status) || '';
    },
    firstName() {
      return (this.$store.state.partnerForm && this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.PERSONAL_INFORMATION] && this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.PERSONAL_INFORMATION].firstName.value) || '';
    },
  },
  methods: {
    handleError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.isErrorModalShown = true;
    },
    routeToReviewsPage() {
        const { partnerName } = this.$route.params;
        this.$router.push({ name: 'PartnerReviewPage', params: { partnerName } });
        this.scrolltop();
    },
    scrolltop() {
      window.scrollTo(0, 0, {
        behavior: 'smooth'
      });
    },
    getCheckIcon() {
      return confirmationIcon;
    },
    handleGoBack() {
      const { partnerName } = this.$route.params;
      this.$router.push({ name: 'PartnerFormPage', params: { partnerName } });
      this.scrolltop();
    }
  },
};

export default PartnerConfirmationPage;
</script>
<style lang="scss" scoped>
.subheader {
  background: linear-gradient(136.67deg, #F48126 0%, #D41A27 32.55%, #A22380 63.55%, #6569AF 100%);
  color: white;
  padding: 64px 0;
}
</style>