<template>
  <div class="loan-element">
    <div class="row">
      <div class="container">
        <div class="payment_info">
          <div class="monthly">
            {{ initialRate.installmentAmount | toCurrency }}
            <div class="occurance">
              monthly payment
            </div>
          </div>
          <div class="info">
            <div class="amount-approved">
              {{ loanConfig.amount_approved | toCurrency }}
            </div>
            <div class="rate-term">
              {{ initialRate.term }}
              <span>months</span>
            </div>
            <div class="rate-apr">
              {{ initialRate.rate | toFixedTwo }}% <span>APR</span>
            </div>
          </div>
          <div class="cta">
            <a
              :href="getFullApplicationUrl(initialRate)"
              class="vendor-link"
              onClick="stealthLoansDataLayer('Apply Now', 'Apply')"
            >
              Apply now <font-awesome-icon icon="caret-right" />
            </a>
          </div>
        </div>

        <div class="visual-and-summary">
          <div class="loan-viz-container">
            <div
              ref="visualization"
              class="graph"
            />
            <div class="loan-viz-info">
              <segmented-progress-bar
                :steps="steps"
                :balance="getTakeBackBalanceSixMonths"
                :maxbalance="getMaxLimitTakeBackBalanceAfterSixMonths"
              />

              <div class="breakdown">
                <p>
                  <strong>If you pay ahead</strong>
                  <span class="amount-input">$<input
                    ref="amountInput"
                    value="20"
                    @input="debounceInput"
                  ></span>
                  more per month,
                  <span
                    v-if="extraLimit"
                    style="color: red"
                  >Please enter a value that's between $0-${{ getMaxLimitTakeBackBalance }}</span>
                  <span v-if="!extraLimit">
                    you'll save <strong>{{ getTotalInterestSaved | toCurrency }}</strong> in interest by payoff.
                  </span>
                </p>
                <p v-if="!extraLimit">
                  After 6 months, you'd have <strong>{{ getTakeBackBalanceSixMonths | toCurrency }} </strong><br>
                  in your <strong>Take-Back<sup>&reg;</sup> balance*</strong>, accessible penalty-free.
                </p>
              </div>
            </div>
          </div>
          <div class="summary">
            <p>The Kasasa Loan comes with Take-Backs<sup>&trade;</sup>, so you can get out of debt sooner. Any money paid over your minimum monthly payment gets you ahead on your loan while being part of your Take-Back balance, available at the click of a button if you need it later, penalty-free.</p>
          </div>
          <div class="loan-video mobile">
            <a
              href="#"
              onClick="stealthLoansDataLayer('Mobile only - Watch the Kasasa Loan in Action', 'Loan Element')"
              @click.prevent="$parent.$refs.watchLoan.openModal()"
            >Watch the loan in action <span class="play-icon"><font-awesome-icon icon="play" /></span></a>
          </div>
        </div>
        <div class="loan-disclaimer">
          <p
            v-if="$parent.payload.loanType === 'PERSONAL'"
            class="disclaimer"
          >
            *You could qualify for rates as low as {{ getLowestRate() | toFixedTwo }}% based on the information you provided. These are non-binding, non-offers, of credit. Take-Back balance estimated on the paying ${{ extraMonies }} more per month ahead for 6 months over the term length of the original loan.
          </p>
          <p
            v-if="$parent.payload.loanType === 'VEHICLE'"
            class="disclaimer"
          >
            *You could qualify for rates as low as {{ getLowestRate() | toFixedTwo }}% based on the information you provided and an LTV of 100%. These are non-binding, non-offers, of credit. Take-Back balance estimated on the paying ${{ extraMonies }} more per month ahead for 6 months over the term length of the original loan.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import * as d3 from "d3"
import SegmentedProgressBar from '@/components/SegmentedProgressBar.vue'

export default {
  components: {
    SegmentedProgressBar
  },
  data() {
    return {
      initialProjection: [],
      updatedProjection: [],
      extraMonies: 20,
      svg: null,
      amountAproved: 0,
      extraLimit: false,
      defaultValue: 20,
      initalTotalInterest: 0,
      projectedTotalInterest: 0,
      initialRate: [],
      steps: 35,
      loanConfig: {}
    }
  },
  computed: {
    getTotalInterestSaved: function () {
      return this.round(this.initalTotalInterest - this.projectedTotalInterest, 2);
    },
    getTotalInterestSavedFormatted: function () {
      return `$${this.round(this.initalTotalInterest - this.projectedTotalInterest, 2)}`;
    },
    getTakeBackBalance: function () {
      return `${this.round(this.extraMonies * (this.initialRate.term / 2), 2)}`;
    },
    getTakeBackBalanceFormatted: function () {
      return `$${this.round(this.extraMonies * (this.initialRate.term / 2), 2)}`;
    },
    getTakeBackBalanceSixMonths: function () {
      return this.round(this.extraMonies * 6, 2);
    },
    getTakeBackBalanceSixMonthsFormatted: function () {
      return `$${this.round(this.extraMonies * 6, 2)}`;
    },
    getMaxLimitTakeBackBalance: function () {
      return this.round(this.initialRate.installmentAmount * 3, 2);
    },
    getMaxLimitTakeBackBalanceAfterSixMonths: function () {
      return this.round((this.initialRate.installmentAmount * 3) * 6, 2);
    }
  },
  created() {
    const vizComponent = this;

    this.$root.$on('loanActive', function (rate, index, loanConfig) {
      vizComponent.extraMonies = 20;
      vizComponent.$refs.amountInput.value = 20;
      vizComponent.initialRate = rate;
      vizComponent.loanConfig = loanConfig;
      vizComponent.clearCanvas();
      vizComponent.getInitialChart();
    });
  },
  mounted() {
    this.$nextTick(function () {
      window.dispatchEvent(new CustomEvent('vizLoaded', {
        detail: null
      }));
    })
  },
  methods: {
    getLowestRate() {
      const rates = this.$parent.rates;
      const lowest = rates.reduce(
        (acc, loc) =>
        acc.rate < loc.rate ?
        acc :
        loc
      )
      return lowest.rate;
    },
    debounceInput: _.debounce(function (e) {
      if (e.target.value === '') {
        this.extraLimit = true;
        return false;
      }

      var value = Number(e.target.value);
      if (value > this.getMaxLimitTakeBackBalance) {
        this.extraLimit = true;
      } else if (value < 0 || Number.isNaN(value)) {
        this.extraLimit = true;
      } else {
        this.extraLimit = false;
        this.extraMonies = e.target.value;
        this.updateArea();
        //this.triggerGtmEvent();
      }
    }, 500),
    handleInput: function (event) {
      if (event.target.value === '') {
        this.extraLimit = true;
        return false;
      }
      var value = Number(event.target.value)
      if (value > this.getMaxLimitTakeBackBalance) {
        this.extraLimit = true;
      } else if (value < 0 || Number.isNaN(value)) {
        this.extraLimit = true;
      } else {
        this.extraLimit = false;
        this.extraMonies = value;
      }
    },
    triggerGtmEvent() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "googleAnalyticsEvent",
        "googleAnalyticsEvent": {
          "category": "Pay Ahead",
          "action": "Edit",
          "label": this.extraMonies,
          "purpose": this.$parent.editForm.loanType
        }
      });
    },
    updateArea() {
      this.updatedProjection = this.getProjection(this.amountAproved, this.initialRate.term, this.initialRate.rate, this.extraMonies);

      this.projectedTotalInterest = this.updatedProjection[this.updatedProjection.length - 1].totalInterestPaid;

      const data = this.updatedProjection;

      var path = d3.select(this.$refs.visualization).select('svg').selectAll('#kpath').data([data])
      path.attr('id', 'kpath')
        .attr("stroke", "none")
        .attr("d", d => this.getArea(d, false))
        .style("fill", "url(#kLoanGradient)")
        .transition()
        .duration(1000)
        .attr("d", d => this.getArea(d, true));
      path.enter().append('svg:path').attr('id', 'kpath')
        .attr("stroke", "none")
        .attr("d", d => this.getArea(d, false))
        .style("fill", "url(#kLoanGradient)")
        .transition()
        .duration(1000)
        .attr("d", d => this.getArea(d, true));
      path.exit().remove()

      const endData = data[data.length - 1];

      var margin = {
          top: 10,
          right: 30,
          bottom: 30,
          left: 60
        },
        width = 700 - margin.left - margin.right,
        height = 340 - margin.top - margin.bottom;

      const termMonths = this.getTermAxisValues(this.initialRate.term);

      const x = d3
        .scaleLinear()
        .domain([this.initialProjection[0].period, this.initialProjection[this.initialProjection.length - 1].period])
        .range([0, width]);

      const endLine = d3.select(this.$refs.visualization).select('svg').selectAll('#endLine').data([endData])
      endLine.join(
        function (enter) {
          return enter;
        },
        function (update) {
          return update.attr("x1", x(endData.period)).attr("x2", x(endData.period));
        }
      )

      const interestLine = d3.select(this.$refs.visualization).select('svg').selectAll('#interestLine').data([endData]);
      interestLine.join(
        function (enter) {
          return enter;
        },
        function (update) {
          return update.attr("x1", x(endData.period))
        }
      )

      const interestLabel = d3.select(this.$refs.visualization).select('svg').selectAll('#interestLabel').data([endData]);
      interestLabel.join(
        function (enter) {
          return enter;
        },
        function (update) {
          return update.attr('x', x(endData.period));
        }
      )

      const interestLabelSpans = d3.select(this.$refs.visualization).select('svg').selectAll('.iltspan');
      interestLabelSpans.join(
        function (enter) {
          return enter;
        },
        function (update) {
          return update.attr('x', x(endData.period));
        }
      )

    },
    getInitialChart() {
      this.amountAproved = this.loanConfig.amount_approved;
      this.initialProjection = this.getProjection(this.amountAproved, this.initialRate.term, this.initialRate.rate, 0);
      this.initalTotalInterest = this.initialProjection[this.initialProjection.length - 1].totalInterestPaid;
      this.updatedProjection = this.getProjection(this.amountAproved, this.initialRate.term, this.initialRate.rate, this.extraMonies);
      this.projectedTotalInterest = this.updatedProjection[this.updatedProjection.length - 1].totalInterestPaid;

      this.buildChart(this.amountAproved, this.initialProjection, this.updatedProjection);
    },
    getProjection(loanAmount, months, rate, extra) {
      var interestRate = rate / 100;
      var monthlyPayment = loanAmount * (interestRate / 12) * Math.pow((1 + interestRate / 12), months) / (Math.pow((1 +
        interestRate / 12), months) - 1);
      var currentBalance = loanAmount;
      var paymentCounter = 1
      var totalInterest = 0;

      monthlyPayment = monthlyPayment + parseFloat(extra);

      var periods = [];
      let today = new Date();

      // Add starting point
      periods.push({
        period: 0,
        dateYear: today.getFullYear(),
        date: this.formatDate(today),
        monthlyPayment: 0,
        principal: 0,
        interest: 0,
        totalInterestPaid: 0,
        balance: this.round(currentBalance, 2),
      });

      while (this.round(currentBalance, 2) > 0) {
        // Calculates the portion of your monthly payment that goes towards interest
        var towardsInterest = (interestRate / 12) * currentBalance;

        if (monthlyPayment > currentBalance) {
          monthlyPayment = currentBalance + towardsInterest;
        }

        var towardsBalance = monthlyPayment - towardsInterest;
        totalInterest = totalInterest + towardsInterest;
        currentBalance = currentBalance - towardsBalance;

        var date = new Date(today.setMonth(today.getMonth() + 1));

        // Build periods
        periods.push({
          period: paymentCounter,
          dateYear: date.getFullYear(),
          date: this.formatDate(date),
          monthlyPayment: this.round(monthlyPayment, 2),
          principal: this.round(towardsBalance, 2),
          interest: this.round(towardsInterest, 2),
          totalInterestPaid: this.round(totalInterest, 2),
          balance: this.round(currentBalance, 2),
        });

        paymentCounter++;
      }

      return periods;
    },
    round(num, dec) {
      return parseFloat((Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec)).toFixed(dec));
    },
    formatDate(date) {
      var year = date.getFullYear();
      var month = (date.getMonth() + 1)
      var day = date.getDate();
      return `${year}-${month}-${day}`;
    },
    clearCanvas() {
      d3.select(this.$refs.visualization).select('svg').remove();
    },
    getArea(data, boolean) {
      var margin = {
          top: 10,
          right: 30,
          bottom: 30,
          left: 60
        },
        width = 700 - margin.left - margin.right,
        height = 340 - margin.top - margin.bottom;

      const x = d3
        .scaleLinear()
        .domain([this.initialProjection[0].period, this.initialProjection[this.initialProjection.length - 1].period])
        .range([0, width]);


      var y = d3.scaleLinear()
        .domain([0, this.amountAproved])
        .range([height, 0]);

      return d3.area()
        .x(function (d) {
          return boolean ? x(d.period) : 0;
        })
        .y0(height)
        .y1(function (d) {
          return y(d.balance)
        })
        .curve(d3.curveBasis)(data)
    },
    getTermAxisValues(term) {
      const termAxis = {
        '12': [6, 12],
        '24': [12, 24],
        '36': [12, 24, 36],
        '48': [12, 24, 36, 48],
        '60': [12, 24, 36, 48, 60],
        '66': [12, 24, 36, 48, 58, 66],
        '72': [12, 24, 36, 48, 60, 72],
        '76': [12, 24, 36, 48, 62, 76],
        '84': [12, 24, 36, 48, 60, 72, 84],
      };

      const selectedTermAxis = termAxis[term];

      return [0, ...selectedTermAxis];
    },
    buildChart(loanAmount, initialProjection, kLoanProjection) {
      var data = initialProjection;
      var newData = kLoanProjection;

      // set the dimensions and margins of the graph
      var margin = {
          top: 10,
          right: 30,
          bottom: 30,
          left: 60
        },
        width = 700 - margin.left - margin.right,
        height = 340 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3.select(this.$refs.visualization)
        .append("svg")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("viewBox", "0 0 740 350")
        .append("g")
        .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

      const termMonths = this.getTermAxisValues(this.initialRate.term);

      const x = d3
        .scaleLinear()
        .domain([data[0].period, data[data.length - 1].period])
        .range([0, width]);

      svg.append("g")
        .attr('id', 'x-axis')
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickValues(termMonths).tickFormat(function (d) {
          if (d !== 0) {
            return `${d} MONTHS`;
          }
          return 'start'; // if zero, return 'start'
        })).selectAll('#x-axis .tick text') // select all the x tick texts
        .call(function (t) {
          t.each(function (d) { // for each one
            var self = d3.select(this);
            var s = self.text().split(' '); // get the text and split it
            self.text(''); // clear it out
            self.append("tspan") // insert two tspans
              .attr("x", 0)
              .attr("dy", ".8em")
              .text(s[0]);
            self.append("tspan")
              .attr("x", 0)
              .attr("dy", "1.1em")
              .text(s[1]);
          })
        });


      // Add Y axis
      var y = d3.scaleLinear()
        .domain([0, loanAmount])
        .range([height, 0]);

      svg.append("g")
        .attr('id', 'y-axis')
        .call(d3.axisLeft(y).tickValues([d3.min(data, d => d.balance), d3.max(data, d => d.balance) / 2, d3.max(data,
          d => d.balance)]).tickFormat(this.dollarFormat));

      //make defs and add the linear gradient
      var lg = svg.append("defs").append("linearGradient")
        .attr("id", "kLoanGradient") //id of the gradient
        .attr("x1", "0%")
        .attr("x2", "100%")
        .attr("y1", "0%")
        .attr("y2", "0%") //since its a vertical linear gradient
      ;
      lg.append("stop")
        .attr("offset", "0%")
        .style("stop-color", "#973e8e") //end in red
        .style("stop-opacity", 1)

      lg.append("stop")
        .attr("offset", "100%")
        .style("stop-color", "#7177b3") //start in blue
        .style("stop-opacity", 1)

      // Add the area for the initial projection
      svg.append("path")
        .data([data])
        .attr("fill", "#a7a4a3")
        .attr("stroke", "none")
        .attr("d", d3.area()
          .x(function (d) {
            return x(d.period)
          })
          .y0(height)
          .y1(function (d) {
            return y(d.balance)
          })
        )

      const kLoanArea = function (datum, boolean) {
        return d3.area()
          .x(function (d) {
            return boolean ? x(d.period) : 0
          })
          .y0(height)
          .y1(function (d) {
            return y(d.balance)
          })
          .curve(d3.curveCatmullRom.alpha(0.5))(datum)
      }

      const initialEnd = data[data.length - 1];

      // Add end tick
      svg.append("line")
        .data([initialEnd])
        .attr('id', 'initialEnd')
        .attr("x1", x(initialEnd.period))
        .attr("y1", 295)
        .attr("x2", x(initialEnd.period))
        .attr("y2", 305)
        .style("stroke", "#636c6e")

      // Add the area for the kloan projection
      svg.append("path")
        .data([newData])
        .attr('id', 'kpath')
        .attr("stroke", "none")
        .attr("d", d => this.getArea(d, false))
        .style("fill", "url(#kLoanGradient)")
        .transition()
        .duration(1000)
        .attr("d", d => this.getArea(d, true));

      const endData = newData[newData.length - 1];

      svg.append("line")
        .data([endData])
        .attr('id', 'endLine')
        .attr("x1", x(endData.period))
        .attr("y1", 50)
        .attr("x2", x(endData.period))
        .attr("y2", 300)
        .style("stroke", "#636c6e")

      svg.append("line")
        .data([endData])
        .attr('id', 'interestLine')
        .attr("x1", x(endData.period))
        .attr("y1", 113)
        .attr("x2", width + 55)
        .attr("y2", 113)
        .style("stroke", "#636c6e")

      // Add interest end tick
      svg.append("line")
        .data([endData])
        .attr('id', 'interestEndTick')
        .attr("x1", width + 55)
        .attr("y1", 107)
        .attr("x2", width + 55)
        .attr("y2", 119)
        .style("stroke", "#636c6e")

      const interestLabel = svg.append("text")
        .attr("y", 73)
        .attr("x", x(endData.period))
        .attr('text-anchor', 'left')
        .attr("id", "interestLabel")

      interestLabel
        .append("tspan")
        .text('Earlier')
        .attr("x", x(endData.period))
        .attr("dx", 10)
        .attr("dy", 0)
        .attr("class", "iltspan");

      interestLabel
        .append("tspan")
        .text('Payoff')
        .attr("x", x(endData.period))
        .attr("dx", 10)
        .attr("dy", 14)
        .attr("class", "iltspan");

      interestLabel
        .append("tspan")
        .text('Date')
        .attr("x", x(endData.period))
        .attr("dx", 10)
        .attr("dy", 14)
        .attr("class", "iltspan");

    },
    dollarFormat(d) {
      return '$' + d3.format('.2s')(d)
    },
    getFullApplicationUrl(rateInfo) {
      const payload = {
        application_id: this.$parent.applicationId,
        installment_amount: rateInfo.installmentAmount,
        term: rateInfo.term,
        rate: rateInfo.rate,
        //credit_score: this.$parent.editForm.creditScore
      };
      const fullApplicationParams = new URLSearchParams(payload).toString();
      return `${this.$parent.fullApplicationUrl}?${fullApplicationParams}`;
    },
  }
}
</script>