<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.firstName.value"
          v-validate="form.firstName.validators"
          :name="form.firstName.name"
          type="text"
          placeholder="First name*"
          class="form-control"
          :class="{'is-invalid': form.firstName.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.firstName.error.state"
          :message="form.firstName.error.message"
        />
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.lastName.value"
          v-validate="form.lastName.validators"
          :name="form.lastName.name"
          type="text"
          placeholder="Last name*"
          class="form-control"
          :class="{'is-invalid': form.lastName.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.lastName.error.state"
          :message="form.lastName.error.message"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group position-relative">
        <AddressInput
          :is-invalid="form.address1.error.state.isShown"
          placeholder="Address*"
          @change="handleAddressInputChange"
          @context="handleAddressInputContext"
        />
        <input
          v-model="form.address1.value"
          v-validate="form.address1.validators"
          :name="form.address1.name"
          type="hidden"
        >
        <InputError
          :state="form.address1.error.state"
          :message="form.address1.error.message"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group position-relative">
        <input
          v-model="form.address2.value"
          v-validate="form.address2.validators"
          :name="form.address2.name"
          type="text"
          placeholder="Address line 2"
          class="form-control"
          :class="{'is-invalid': form.address2.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.address2.error.state"
          :message="form.address2.error.message"
        />
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group position-relative">
        <input
          v-model="form.city.value"
          v-validate="form.city.validators"
          :name="form.city.name"
          type="text"
          placeholder="City*"
          class="form-control"
          :class="{'is-invalid': form.city.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.city.error.state"
          :message="form.city.error.message"
        />
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group select position-relative">
        <select
          v-model="form.state.value"
          v-validate="form.state.validators"
          class="form-control"
          :class="{'is-invalid': form.state.error.state.isShown}"
          :name="form.state.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            State*
          </option>
          <option
            v-for="(state, index) in formDataDefaults.states"
            :key="index"
            :value="state.value"
          >
            {{ state.value }}
          </option>
        </select>
        <InputError
          :state="form.state.error.state"
          :message="form.state.error.message"
        />
      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group position-relative">
        <input
          v-model="form.zip.value"
          v-validate="form.zip.validators"
          v-mask="'##########'"
          :name="form.zip.name"
          type="text"
          placeholder="ZIP*"
          class="form-control"
          :class="{'is-invalid': form.zip.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.zip.error.state"
          :message="form.zip.error.message"
        />
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group position-relative">
        <input
          v-model="form.email.value"
          v-validate="form.email.validators"
          :name="form.email.name"
          type="text"
          placeholder="Email*"
          class="form-control"
          :class="{'is-invalid': form.email.error.state.isShown}"
          @input="handleInput"
        >
        <InputError
          :state="form.email.error.state"
          :message="form.email.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.dob.value"
          v-validate="form.dob.validators"
          v-mask="'##/##/####'"
          class="form-control"
          :class="{'is-invalid': form.dob.error.state.isShown }"
          data-vv-validate-on="none"
          type="text"
          :name="form.dob.name"
          placeholder="Birthdate* (MM/DD/YYYY)"
          @input="handleInput"
        >
        <InputError
          :state="form.dob.error.state"
          :message="form.dob.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.phone.value"
          v-validate="form.phone.validators"
          v-mask="'(###) ###-####'"
          class="form-control"
          :class="{'is-invalid': form.phone.error.state.isShown }"
          data-vv-validate-on="none"
          type="text"
          :name="form.phone.name"
          placeholder="Mobile number* (XXX) XXX-XXXX"
          @input="handleInput"
        >
        <InputError
          :state="form.phone.error.state"
          :message="form.phone.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="annualIncomeFormatted"
          v-currency="{currency: 'USD', locale: 'en'}"
          name="annualIncomeFormatted"
          type="text"
          placeholder="Annual income*"
          class="form-control"
          :class="{'is-invalid': form.annualIncome.error.state.isShown}"
          @input="handleInput"
        >
        <input
          v-model="form.annualIncome.value"
          v-validate="form.annualIncome.validators"
          :name="form.annualIncome.name"
          type="hidden"
        >
        <InputError
          :state="form.annualIncome.error.state"
          :message="form.annualIncome.error.message"
        />
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <b-button
          v-b-tooltip.hover
          class="tooltip-button"
          title="Your SSN allows us to provide you with the most accurate loan options. All information is securely encrypted."
          style="top: -16px; right: 0"
        >
          <font-awesome-icon icon="info-circle" />
        </b-button>
        <input
          v-model="ssnFormatted"
          v-mask="'###-##-####'"
          type="text"
          placeholder="Social Security number* XXX-XX-XXXX"
          class="form-control"
          :class="{'is-invalid': form.ssn.error.state.isShown}"
          @input="handleInput"
        >
        <input
          v-model="form.ssn.value"
          v-mask="'#########'"
          v-validate="form.ssn.validators"
          :name="form.ssn.name"
          type="hidden"
        >
        <InputError
          :state="form.ssn.error.state"
          :message="form.ssn.error.message"
        />
      </div>
    </div>

    <div class="col-12">
      <p class="color-gray font-size-sm-3 font-barlow-light">
        Alimony, child support and separate maintenance income need not be revealed if you do not wish to have them considered as a basis for repaying this obligation.
      </p>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative select">
        <select
          v-model="form.otherIncomeType.value"
          class="form-control"
          :name="form.otherIncomeType.name"
          @change="handleInput"
        >
          <option
            value="null"
            selected
            disabled
          >
            Other monthly income type
          </option>
          <option
            v-for="(type, index) in formDataDefaults.otherIncomeTypes"
            :key="index"
            :value="type"
          >
            {{ type | sentencecase }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="otherIncomeFormatted"
          v-currency="{currency: 'USD', locale: 'en'}"
          :disabled="!isOtherIncomeTypeSelected"
          name="otherIncomeFormatted"
          type="text"
          placeholder="Other monthly income amount"
          class="form-control"
          :class="{'is-invalid': form.otherIncome.error.state.isShown}"
          @input="handleInput"
        >
        <input
          v-model="form.otherIncome.value"
          v-validate="form.otherIncome.validators(isOtherIncomeTypeSelected)"
          :name="form.otherIncome.name"
          type="hidden"
        >
        <InputError
          :state="form.otherIncome.error.state"
          :message="form.otherIncome.error.message"
        />
      </div>
    </div>
  </div>
  <!-- ./row -->
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';
import { formDataDefaults } from '@/utils/formDataDefaults';
import AddressInput from '@/components/AddressInput.vue';

const PersonalInformation = {
  components: {
    InlineValidationError,
    InputError,
    AddressInput,
  },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      }
    },
    formData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      formDataDefaults,
      form: {
        firstName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'firstName',
          validators: {
            requiredExtended: {
              message: 'Enter first name',
            },
            specialChars: {
              message: 'First name cannot have special characters',
            },
          },
        },
        lastName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'lastName',
          validators: {
            requiredExtended: {
              message: 'Enter last name',
            },
            specialChars: {
              message: 'Last name cannot have special characters',
            },
          },
        },
        email: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'email',
          validators: {
            requiredExtended: {
              message: 'Enter email',
            },
            email: true,
          },
        },
        address1: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'address1',
          validators: {
            requiredExtended: {
              message: 'Enter address',
            },
            specialChars: {
              message: 'Address cannot have special characters',
            },
          },
        },
        address2: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'address2',
          validators: {
            specialChars: {
              message: 'Address 2 cannot have special characters',
            },
          },
        },
        city: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'city',
          validators: {
            requiredExtended: {
              message: 'Enter city',
            },
            specialChars: {
              message: 'City cannot have special characters'
            }
          },
        },
        state: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'state',
          validators: {
            requiredExtended: {
              message: 'Choose state',
            },
          },
        },
        zip: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'zip',
          validators: {
            requiredExtended: {
              message: 'Enter ZIP',
            },
          },
        },
        dob: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'dob',
          validators: {
            requiredExtended: {
              message: 'Enter date of birth (MM/DD/YYYY)',
            },
            date_format: 'MM/dd/yyyy',
            isOldRange: true,
            isOld: true,
          },
        },
        phone: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'phone',
          validators: {
            requiredExtended: {
              message: 'Enter mobile number',
            },
            isUsPhone: true,
          },
        },
        annualIncome: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'annualIncome',
          validators: {
            requiredExtended: {
              message: 'Enter annual income',
            },
            positive: {
              message: 'Annual income must be positive',
            },
          },
        },
        ssn: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'ssn',
          validators: {
            requiredExtended: {
              message: 'Enter SSN'
            },
            min: 9,
          },
        },
        otherIncomeType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'otherIncomeType',
        },
        otherIncome: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'otherIncome',
          validators: (isOtherIncomeTypeSelected) => ({
            requiredExtended: isOtherIncomeTypeSelected && {
              message: 'Enter other income'
            },
            positive: isOtherIncomeTypeSelected && {
              message: 'Other income must be positive'
            }
          }),
        },
      },
      otherIncomeFormatted: null,
      annualIncomeFormatted: null,
      ssnFormatted: null,
      hasValidated: false,
    };
  },
  mounted() {
    this.setFormValues(this.formData);
  },
  methods: {
    handleInputError(input) {
        this.form[input].error = {
          ...this.form[input].error,
          state: {
            ...this.form[input].error.state,
            isShown: this.errors.has(this.form[input].name),
          },
          message: this.errors.first(this.form[input].name),
        };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {
        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.$emit('collect-data', { form: this.form, isValid: true });
        }
      });

      this.hasValidated = true;
    },
    handleAddressInputChange(address) {
      this.form.address1.value = address;

      this.handleInput();
    },
    handleAddressInputContext(context) {
      this.form.city.value = context.city;
      this.form.state.value = context.state;
      this.form.zip.value = context.postalCode;
    },
    handleSsnChange(ssn) {
      this.form.ssn.value = ssn;
      this.handleInput();
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    },
    setFormValues(data = {}) {
      if (Object.keys(data).length < 1) {
        return;
      }

      try {
        this.form.firstName.value = data.firstName.value;
        this.form.lastName.value = data.lastName.value;
        this.form.email.value = data.email.value;
        this.form.address1.value = data.address1.value;
        this.form.address2.value = data.address2.value;
        this.form.city.value = data.city.value;
        this.form.state.value = data.state.value;
        this.form.zip.value = data.zip.value;
        this.form.dob.value = data.dob.value;
        this.form.phone.value = data.phone.value;
        this.form.ssn.value = data.ssn.value;
        this.form.annualIncome.value = data.annualIncome.value;
        this.form.otherIncomeType.value = data.otherIncomeType.value;
        this.form.otherIncome.value = data.otherIncome.value;

        this.otherIncomeFormatted = data.otherIncome.value;
        this.annualIncomeFormatted = data.annualIncome.value;
        this.ssnFormatted = data.ssn.value;
      } catch (error) {
        console.error('Invalid form data passed', error);
      }
    },
  },
  watch: {
    event(event) {
      if (event.type === 'VALIDATE') {
        this.validate();
      }
    },
    otherIncomeFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.-]/g,'');

      this.form.otherIncome.value = amount;
    },
    annualIncomeFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.-]/g,'');

      this.form.annualIncome.value = amount;
    },
    ssnFormatted(ssnFormatted) {
      const ssn = ssnFormatted.replace(/\D/g, '');

      this.form.ssn.value = ssn;
    },
    isOtherIncomeTypeSelected(isSelected) {
      if (!isSelected) {
        this.otherIncomeFormatted = '';
      }

      this.validate(false);
    },
    formData(data) {
      this.setFormValues(data)
    },
  },
  computed: {
    isOtherIncomeTypeSelected() {
      return (this.form.otherIncomeType.value !== null && this.form.otherIncomeType.value !== 'NONE');
    }
  }
};

export default PersonalInformation;
</script>