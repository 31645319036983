<template>
  <modal
    ref="technicalIssue"
    :close-button="closeButton"
  >
    <template v-slot:body>
      <div class="no-prequal-content">
        <div class="icon">
          <svg-icon icon="technical-issues-icon" />
        </div>
        <div>
          <h3>It’s not you... it’s technical difficulties.</h3>
          <p>
            Sorry, we’re experiencing a system issue. We’ll let you know when we’re up and running again. Please check back in later.
          </p>
        </div>
        <button
          v-if="returnButton"
          class="btn btn-block btn-primary mt-5"
          @click="goBack"
        >
          Go back home
          Update
          <font-awesome-icon
            icon="caret-right"
            class="ml-2"
          />
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import {goBack} from '@/utils/utils'

export default {
  components: {
      Modal,
      SvgIcon
  },
  data() {
    return {
      closeButton: false,
      returnButton:false
    }
  },
  methods: {
    showModal(type) {
      if ( type === 'technical' ) {
        this.$refs.technicalIssue.openModal();
      }
    },
    goBack() {
      goBack();
    }
  }
}
</script>