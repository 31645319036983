<template>
  <div class="form-container">
    <transition name="section">
      <div
        v-if="showStep(steps.step1)"
        class="accordion-item"
      >
        <section-title
          :step="getStep(1)"
        />
        <div
          class="section-content"
        >
          <gtm-events
            :e-add="true"
            :event-data="getTrackingData(step)"
          />
          <p v-if="isAuto">
            Make sure what we’ve got is spot on, and tell us a little more about your ride!
          </p>
          <div
            v-if="isAuto"
            class="form"
          >
            <div class="form-row">
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.vehicleYear"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step1.vehicleYear') }"
                  :disabled="formDataDefaults.vehicleYears.length === 0"
                  name="vehicleYear"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                  @change="onChangeYear($event)"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Vehicle year*
                  </option>
                  <option
                    v-for="(year, index) in formDataDefaults.vehicleYears"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step1.vehicleYear')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.vehicleYear') ? 'Choose vehicle year' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.vehicleMake"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step1.vehicleMake') }"
                  :disabled="formDataDefaults.vehicleMakes.length === 0"
                  name="vehicleMake"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                  @change="onChangeMake($event)"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Vehicle make*
                  </option>
                  <option
                    v-for="(make, index) in formDataDefaults.vehicleMakes"
                    :key="index"
                    :value="make"
                  >
                    {{ make }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step1.vehicleMake')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.vehicleMake') ? 'Choose vehicle make' : '' }}
                </span>
              </div>
            </div> <!-- end form-row -->
            <div class="form-row">
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.vehicleModel"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step1.vehicleModel') }"
                  :disabled="formDataDefaults.vehicleModels.length === 0"
                  name="vehicleModel"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                  @change="onChangeModel($event)"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Vehicle model*
                  </option>
                  <option
                    v-for="(model, index) in formDataDefaults.vehicleModels"
                    :key="index"
                    :value="model"
                  >
                    {{ model }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step1.vehicleModel')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.vehicleModel') ? 'Choose vehicle model' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6 select">
                <b-button
                  v-b-tooltip.hover
                  class="tooltip-button"
                  title="Not sure what your ride’s trim is? Check the original sales invoice or window sticker, your owner’s manual, or the side or rear of your car for a decal or lettering.(tirebuyer.com) "
                >
                  <font-awesome-icon icon="info-circle" />
                </b-button>
                <select
                  v-model="formData.vehicleTrim"
                  :class="{ 'form-control': true, }"
                  :disabled="formDataDefaults.vehicleTrims.length === 0"
                  name="vehicleTrim"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Vehicle trim
                  </option>
                  <option
                    v-for="(trim, index) in formDataDefaults.vehicleTrims"
                    :key="index"
                    :value="trim"
                  >
                    {{ trim }}
                  </option>
                </select>
              </div>
            </div><!-- End form-inputs -->
            <div class="form-row">
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.currentLender"
                  v-validate="'required'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step1.currentLender') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="currentLender"
                  data-vv-scope="step1"
                  placeholder="Current lender*"
                >
                <span
                  v-show="errors.has('step1.currentLender')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.currentLender') ? 'Enter current lender' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4">
                <input
                  v-model="currentMonthlyPaymentFormatted"
                  v-currency="{currency: 'USD', locale: 'en'}"
                  type="text"
                  placeholder="Current monthly payment*"
                  :class="{'form-control': true, 'is-invalid': errors.has('step1.monthlyPayment') }"
                >
                <input
                  v-model="formData.currentMonthlyPayment"
                  v-validate="'required|min_value:1|max_value:99999'"
                  name="monthlyPayment"
                  type="hidden"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                >
                <span
                  v-show="errors.has('step1.monthlyPayment')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.monthlyPayment')?'Enter monthly payment':'' }}
                </span>
              </div>
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.estimatedMileage"
                  v-validate="'required|numeric|min_value:1|max_value:999999'"
                  name="estimatedMileage"
                  type="text"
                  placeholder="Est. mileage*"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                  :class="{'form-control': true, 'is-invalid': errors.has('step1.estimatedMileage') }"
                  @input="preventNonNumericInput"
                >
                <span
                  v-show="errors.has('step1.estimatedMileage')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.estimatedMileage')?'Enter estimated mileage':'' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
          </div>
          <p v-if="isPersonal">
            Make sure your loan amount, loan term (months), and loan purpose are right on!
          </p>
          <div
            v-if="isPersonal"
            class="form"
          >
            <div class="form-row">
              <div class="form-group col-lg-4">
                <input
                  v-model="loanAmountFormatted"
                  v-currency="{currency: 'USD', locale: 'en'}"
                  type="text"
                  placeholder="Loan amount*"
                  :class="{'form-control': true, 'is-invalid': errors.has('step1.loanAmount') }"
                  :disabled="disableFields.loanAmount"
                >
                <input
                  v-model="formData.loanAmount"
                  v-validate="'required|min_value:1|max_value:30000'"
                  name="loanAmount"
                  type="hidden"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                >
                <span
                  v-show="errors.has('step1.loanAmount')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.loanAmount')?'Enter loan amount':'' }}
                </span>
              </div>
              <div class="form-group col-lg-4">
                <input
                  v-model="termFormatted"
                  v-validate="{ required: true, numeric: true }"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step1.loanTerm') }"
                  :disabled="disableFields.loanTerm"
                  name="loanTerm"
                  autocomplete="new-input"
                  type="text"
                  placeholder="Loan term*"
                  inputmode="decimal"
                  pattern="\d*"
                  maxlength="9"
                  data-vv-scope="step1"
                  data-vv-validate-on="none"
                  @input="preventNonNumericInput"
                >
                <span
                  v-show="errors.has('step1.loanTerm')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.loanTerm') ? 'Enter loan term' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4 select">
                <select
                  v-model="formData.loanPurpose"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step1.loanPurpose') }"
                  name="loanPurpose"
                  data-vv-validate-on="none"
                  data-vv-scope="step1"
                  :disabled="disableFields.loanPurpose"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Loan purpose*
                  </option>
                  <option
                    v-for="(purpose, index) in formDataDefaults.loanPurposes"
                    :key="index"
                    :value="purpose"
                  >
                    {{ purpose | sentencecase }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step1.loanPurpose')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step1.loanPurpose') ? 'Choose loan purpose' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="next('step1')"
            >
              <div class="btn-text">
                Continue <font-awesome-icon icon="caret-right" />
              </div>
            </button>
          </div>
        </div>
      </div> <!-- end step 1 -->
    </transition>
    <transition name="section">
      <div
        v-if="showStep(steps.step2)"
        class="accordion-item"
      >
        <section-title
          :step="getStep(2)"
        />
        <div
          class="section-content"
        >
          <gtm-events :event-data="getTrackingData(step)" />
          <p>
            Not to get personal — but is all the info we have below correct?
          </p>
          <div class="form">
            <div class="form-row">
              <div class="form-group col-lg-6">
                <input
                  v-model="formData.firstName"
                  v-validate="{ required: true, regex: regexRules.alphaDash }"
                  :class="{'form-control': true, 'is-invalid': errors.has('step2.firstName') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="firstName"
                  data-vv-scope="step2"
                  placeholder="First name*"
                  @blur="formData.firstName = formData.firstName.trim()"
                >
                <span
                  v-show="errors.has('step2.firstName')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.firstName') ? 'Enter first name' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6">
                <input
                  v-model="formData.lastName"
                  v-validate="{ required: true, regex: regexRules.alphaDashApos }"
                  :class="{'form-control': true, 'is-invalid': errors.has('step2.lastName') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="lastName"
                  data-vv-scope="step2"
                  placeholder="Last name*"
                  @blur="formData.lastName = formData.lastName.trim()"
                >
                <span
                  v-show="errors.has('step2.lastName')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.lastName') ? 'Enter last name' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div class="form-row">
              <div class="form-group col-lg-12">
                <input
                  v-model="formData.email"
                  v-validate="'required|email'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step2.email') }"
                  :disabled="disableFields.email"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="email"
                  data-vv-scope="step2"
                  placeholder="Email"
                >
                <span
                  v-show="errors.has('step2.email')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.email') ? 'Enter email' : '' }}
                </span>
              </div>
            </div><!-- End form-row -->
            <div class="form-row">
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.phone"
                  v-validate="'required|isUsPhone'"
                  v-mask="'(###) ###-####'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step2.phone') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="phone"
                  data-vv-scope="step2"
                  placeholder="Cell phone*"
                >
                <span
                  v-show="errors.has('step2.phone')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.phone') ? 'Enter phone number' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.dob"
                  v-validate="{ required: true, date_format: 'MM/dd/yyyy', isOldRange: true, isOld: true }"
                  v-mask="'##/##/####'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step2.dob') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="dob"
                  data-vv-scope="step2"
                  placeholder="Date of birth (MM/DD/YYYY)"
                >
                <span
                  v-show="errors.has('step2.dob')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.dob') ? 'Enter date of birth' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4 ssn-field">
                <b-button
                  v-b-tooltip.hover
                  class="tooltip-button"
                  title="Your SSN allows us to provide you with the most accurate loan options. All information is securely encrypted."
                >
                  <font-awesome-icon icon="info-circle" />
                </b-button>
                <span
                  class="toggle-ssn"
                  @click="toggleSsnStatus"
                >
                  <font-awesome-icon
                    v-if="ssnToggleStatusLabel === 'show'"
                    icon="eye"
                  />
                  <font-awesome-icon
                    v-if="ssnToggleStatusLabel === 'hide'"
                    icon="eye-slash"
                  />
                </span>
                <input
                  v-model="ssnFormatted"
                  v-mask="'###-##-####'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step2.ssn') }"
                  autocomplete="new-input"
                  :type="ssnToggleStatus"
                  placeholder="Social Security number*"
                >
                <input
                  v-model="formData.ssn"
                  v-validate="{ required: true, numeric: true, length: 9 }"
                  :class="{ 'form-control': true }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="hidden"
                  name="ssn"
                  data-vv-scope="step2"
                  maxlength="9"
                >
                <span
                  v-show="errors.has('step2.ssn')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step2.ssn') ? 'Enter SSN' : '' }}
                </span>
              </div>
            </div><!-- End form-row -->
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="next('step2')"
            >
              <div class="btn-text">
                Continue <font-awesome-icon icon="caret-right" />
              </div>
            </button>
            <button
              class="back btn btn-secondary btn-block"
              type="button"
              @click.prevent="prev()"
            >
              Back
            </button>
          </div>
        </div>
      </div> <!-- end step 2 -->
    </transition>
    <transition name="section">
      <div
        v-if="showStep(steps.step3)"
        class="accordion-item"
      >
        <section-title
          :step="getStep(3)"
        />
        <div
          class="section-content"
        >
          <gtm-events :event-data="getTrackingData(step)" />
          <p>
            Make sure your address is correct, then tell us a little about your place!
          </p>
          <div class="form">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <input
                  v-model="formData.address"
                  v-validate="'required'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step3.address') }"
                  :disabled="disableFields.address"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="address"
                  data-vv-scope="step3"
                  placeholder="Address*"
                >
                <span
                  v-show="errors.has('step3.address')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.address') ? 'Enter address' : '' }}
                </span>
              </div>
              <div class="form-group col-sm-12">
                <input
                  v-model="formData.address2"
                  :class="{'form-control': true}"
                  autocomplete="new-input"
                  type="text"
                  name="address2"
                  placeholder="Address line 2"
                >
              </div>
            </div><!-- End form-row -->
            <div class="form-row">
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.city"
                  v-validate="'required'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step3.city') }"
                  :disabled="disableFields.city"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="city"
                  data-vv-scope="step3"
                  placeholder="City*"
                >
                <span
                  v-show="errors.has('step3.city')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.city') ? 'Enter city' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4 select">
                <select
                  v-model="formData.state"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step3.state') }"
                  :disabled="disableFields.state"
                  name="state"
                  data-vv-validate-on="none"
                  data-vv-scope="step3"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    State*
                  </option>
                  <option
                    v-for="(state, index) in formDataDefaults.states"
                    :key="index"
                    :value="state"
                  >
                    {{ state.value }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step3.state')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.state') ? 'Choose state' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-4">
                <input
                  v-model="formData.zip"
                  v-validate="'required|length:5|numeric'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step3.zip') }"
                  :disabled="disableFields.zip"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="zip"
                  data-vv-scope="step3"
                  placeholder="ZIP*"
                  maxlength="5"
                >
                <span
                  v-show="errors.has('step3.zip')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.zip') ? 'Enter ZIP' : '' }}
                </span>
              </div>
            </div><!-- End form-row -->
            <div class="form-row">
              <div class="form-group col-lg-12 select">
                <select
                  v-model="formData.residenceType"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step3.residenceType') }"
                  name="residenceType"
                  data-vv-validate-on="none"
                  data-vv-scope="step3"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Residence type*
                  </option>
                  <option
                    v-for="(type, index) in formDataDefaults.residenceTypes"
                    :key="index"
                    :value="type"
                  >
                    {{ type | sentencecase }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step3.residenceType')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.residenceType') ? 'Choose residence type' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div class="form-row">
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.residenceYears"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step3.residenceYears') }"
                  name="residenceYears"
                  data-vv-validate-on="none"
                  data-vv-scope="step3"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Time of residence* (years)
                  </option>
                  <option
                    v-for="(years, index) in formDataDefaults.residenceYears"
                    :key="index"
                    :value="years"
                  >
                    {{ years }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step3.residenceYears')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.residenceYears') ? 'Choose residence years' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.residenceMonths"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step3.residenceMonths') }"
                  name="residenceMonths"
                  data-vv-validate-on="none"
                  data-vv-scope="step3"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Months*
                  </option>
                  <option
                    v-for="(months, index) in formDataDefaults.residenceMonths"
                    :key="index"
                    :value="months"
                  >
                    {{ months }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step3.residenceMonths')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.residenceMonths') ? 'Choose residence months' : '' }}
                </span>
              </div>
            </div><!-- end form-row -->
            <div class="form-row">
              <div class="form-group col-lg-12">
                <input
                  v-model="monthlyRentFormatted"
                  v-currency="{currency: 'USD', locale: 'en'}"
                  type="text"
                  placeholder="Monthly mortgage/rent payment*"
                  :class="{'form-control': true, 'is-invalid': errors.has('step3.monthlyRent') }"
                >
                <input
                  v-model="formData.monthlyRent"
                  v-validate="'required|min_value:0|max_value:99999'"
                  name="monthlyRent"
                  type="hidden"
                  data-vv-validate-on="none"
                  data-vv-scope="step3"
                >
                <span
                  v-show="errors.has('step3.monthlyRent')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step3.monthlyRent')?'Must be between $0 & $99,999':'' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div
              v-if="residenceLessThanTwo"
              class="previous-residence-section"
            >
              <p>
                Previous residence
              </p>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <input
                    v-model="formData.previousAddress"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step3.previousAddress') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousAddress"
                    data-vv-scope="step3"
                    placeholder="Address*"
                  >
                  <span
                    v-show="errors.has('step3.previousAddress')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousAddress') ? 'Enter address' : '' }}
                  </span>
                </div>
                <div class="form-group col-sm-12">
                  <input
                    v-model="formData.previousAddress2"
                    :class="{'form-control': true}"
                    autocomplete="new-input"
                    type="text"
                    name="previousAddress2"
                    placeholder="Address line 2"
                  >
                </div>
              </div><!-- End form-row -->
              <div class="form-row">
                <div class="form-group col-lg-4">
                  <input
                    v-model="formData.previousCity"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step3.previousCity') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousCity"
                    data-vv-scope="step3"
                    placeholder="City*"
                  >
                  <span
                    v-show="errors.has('step3.previousCity')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousCity') ? 'Enter city' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-4 select">
                  <select
                    v-model="formData.previousState"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step3.previousState') }"
                    name="previousState"
                    data-vv-validate-on="none"
                    data-vv-scope="step3"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      State*
                    </option>
                    <option
                      v-for="(state, index) in formDataDefaults.states"
                      :key="index"
                      :value="state"
                    >
                      {{ state.value }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step3.previousState')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousState') ? 'Choose state' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-4">
                  <input
                    v-model="formData.previousZip"
                    v-validate="'required|length:5|numeric'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step3.previousZip') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousZip"
                    data-vv-scope="step3"
                    placeholder="ZIP*"
                    maxlength="5"
                  >
                  <span
                    v-show="errors.has('step3.previousZip')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousZip') ? 'Enter ZIP' : '' }}
                  </span>
                </div>
              </div><!-- End form-row -->
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.previousResidenceYears"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step3.previousResidenceYears') }"
                    name="previousResidenceYears"
                    data-vv-validate-on="none"
                    data-vv-scope="step3"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Previous time of residence* (years)
                    </option>
                    <option
                      v-for="(years, index) in formDataDefaults.residenceYears"
                      :key="index"
                      :value="years"
                    >
                      {{ years }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step3.previousResidenceYears')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousResidenceYears') ? 'Select previous residence years' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.previousResidenceMonths"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step3.previousResidenceMonths') }"
                    name="previousResidenceMonths"
                    data-vv-validate-on="none"
                    data-vv-scope="step3"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Months*
                    </option>
                    <option
                      v-for="(months, index) in formDataDefaults.residenceMonths"
                      :key="index"
                      :value="months"
                    >
                      {{ months }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step3.previousResidenceMonths')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step3.previousResidenceMonths') ? 'Select previous residence months' : '' }}
                  </span>
                </div>
              </div><!-- end form-row -->
            </div>
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="next('step3')"
            >
              <div class="btn-text">
                Continue <font-awesome-icon icon="caret-right" />
              </div>
            </button>
            <button
              class="back btn btn-secondary btn-block"
              type="button"
              @click.prevent="prev()"
            >
              Back
            </button>
          </div>
        </div>
      </div> <!-- end step 3 -->
    </transition>
    <transition name="section">
      <div
        v-if="showStep(steps.step4)"
        class="accordion-item"
      >
        <section-title
          :step="getStep(4)"
        />
        <div
          class="section-content"
        >
          <gtm-events :event-data="getTrackingData(step)" />
          <p>
            Part time? full time? Work? School? Tell us more about your employment!
          </p>
          <div class="form">
            <div class="form-row">
              <div class="form-group col-lg-12">
                <input
                  v-model="grossIncomeFormatted"
                  v-currency="{currency: 'USD', locale: 'en'}"
                  type="text"
                  placeholder="Gross monthly income (before taxes)*"
                  :class="{'form-control': true, 'is-invalid': errors.has('step4.grossIncome') }"
                >
                <input
                  v-model="formData.grossIncome"
                  v-validate="'required|min_value:1|max_value:99999'"
                  name="grossIncome"
                  type="hidden"
                  data-vv-validate-on="none"
                  data-vv-scope="step4"
                >
                <span
                  v-show="errors.has('step4.grossIncome')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.grossIncome')?'Enter gross monthly income':'' }}
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.employmentType"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step4.employmentType') }"
                  name="employmentType"
                  data-vv-validate-on="none"
                  data-vv-scope="step4"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Employment type*
                  </option>
                  <option
                    v-for="(type, index) in formDataDefaults.employmentTypes"
                    :key="index"
                    :value="type"
                  >
                    {{ type | sentencedash }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step4.employmentType')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.employmentType') ? 'Choose employment type' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6">
                <input
                  v-model="formData.jobTitle"
                  v-validate="'required'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step4.jobTitle') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="jobTitle"
                  data-vv-scope="step4"
                  placeholder="Job title*"
                >
                <span
                  v-show="errors.has('step4.jobTitle')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.jobTitle') ? 'Enter job title' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div class="form-row">
              <div class="form-group col-lg-6">
                <input
                  v-model="formData.employerName"
                  v-validate="'required'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step4.employerName') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="employerName"
                  data-vv-scope="step4"
                  placeholder="Employer name*"
                >
                <span
                  v-show="errors.has('step4.employerName')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.employerName') ? 'Enter employer\'s name' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6">
                <input
                  v-model="formData.employerPhone"
                  v-validate="'required|isUsPhone'"
                  v-mask="'(###) ###-####'"
                  :class="{'form-control': true, 'is-invalid': errors.has('step4.employerPhone') }"
                  autocomplete="new-input"
                  data-vv-validate-on="none"
                  type="text"
                  name="employerPhone"
                  data-vv-scope="step4"
                  placeholder="Employer's phone number*"
                >
                <span
                  v-show="errors.has('step4.employerPhone')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.employerPhone') ? 'Enter employer\'s phone number' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div class="form-row">
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.employmentYears"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step4.employmentYears') }"
                  name="employmentYears"
                  data-vv-validate-on="none"
                  data-vv-scope="step4"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Time of employment* (years)
                  </option>
                  <option
                    v-for="(years, index) in formDataDefaults.residenceYears"
                    :key="index"
                    :value="years"
                  >
                    {{ years }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step4.employmentYears')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.employmentYears') ? 'Choose time of employment years' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.employmentMonths"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step4.employmentMonths') }"
                  name="employmentMonths"
                  data-vv-validate-on="none"
                  data-vv-scope="step4"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Months*
                  </option>
                  <option
                    v-for="(months, index) in formDataDefaults.residenceMonths"
                    :key="index"
                    :value="months"
                  >
                    {{ months }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step4.employmentMonths')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.employmentMonths') ? 'Choose time of employment months' : '' }}
                </span>
              </div>
            </div><!-- End form-inputs -->
            <div
              v-if="employmentLessThanTwo"
              class="previous-employment-section"
            >
              <p>Previous employment</p>
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.previousEmploymentType"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step4.previousEmploymentType') }"
                    name="previousEmploymentType"
                    data-vv-validate-on="none"
                    data-vv-scope="step4"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Previous employment type*
                    </option>
                    <option
                      v-for="(type, index) in formDataDefaults.employmentTypes"
                      :key="index"
                      :value="type"
                    >
                      {{ type | sentencedash }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step4.previousEmploymentType')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousEmploymentType') ? 'Choose previous employment type' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.previousJobTitle"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step4.previousJobTitle') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousJobTitle"
                    data-vv-scope="step4"
                    placeholder="Previous job title*"
                  >
                  <span
                    v-show="errors.has('step4.previousJobTitle')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousJobTitle') ? 'Enter previous job title' : '' }}
                  </span>
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.previousEmployerName"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step4.previousEmployerName') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousEmployerName"
                    data-vv-scope="step4"
                    placeholder="Previous employer name*"
                  >
                  <span
                    v-show="errors.has('step4.previousEmployerName')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousEmployerName') ? 'Enter previous employer\'s name' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.previousEmployerPhone"
                    v-validate="'required|isUsPhone'"
                    v-mask="'(###) ###-####'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step4.previousEmployerPhone') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="previousEmployerPhone"
                    data-vv-scope="step4"
                    placeholder="Previous employer's phone number*"
                  >
                  <span
                    v-show="errors.has('step4.previousEmployerPhone')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousEmployerPhone') ? 'Enter previous employer\'s phone number' : '' }}
                  </span>
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.previousEmploymentYears"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step4.previousEmploymentYears') }"
                    name="previousEmploymentYears"
                    data-vv-validate-on="none"
                    data-vv-scope="step4"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Time of previous employment* (years)
                    </option>
                    <option
                      v-for="(years, index) in formDataDefaults.residenceYears"
                      :key="index"
                      :value="years"
                    >
                      {{ years }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step4.previousEmploymentYears')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousEmploymentYears') ? 'Choose time of previous employment years' : '' }}
                  </span>
                </div>
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.previousEmploymentMonths"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step4.previousEmploymentMonths') }"
                    name="previousEmploymentMonths"
                    data-vv-validate-on="none"
                    data-vv-scope="step4"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Months*
                    </option>
                    <option
                      v-for="(months, index) in formDataDefaults.residenceMonths"
                      :key="index"
                      :value="months"
                    >
                      {{ months }}
                    </option>
                  </select>
                  <span
                    v-show="errors.has('step4.previousEmploymentMonths')"
                    class="invalid-feedback"
                  >
                    {{ errors.first('step4.previousEmploymentMonths') ? 'Choose time of previous employment months' : '' }}
                  </span>
                </div>
              </div><!-- End form-inputs -->
            </div>
            <p class="other-income">
              Alimony, child support and separate maintenance income need not be revealed if you do not wish to have them considered as a basis for repaying this obligation.
            </p>
            <div class="form-row">
              <div class="form-group col-lg-6">
                <input
                  id="otherIncomeFormatted"
                  v-model="otherIncomeFormatted"
                  v-currency="{currency: 'USD', locale: 'en'}"
                  type="text"
                  placeholder="$0"
                  :class="{'form-control': true, 'is-invalid': errors.has('step4.otherIncome') }"
                  :disabled="formData.otherIncomeType === 'NONE' || formData.otherIncomeType === null"
                >
                <input
                  v-if="formData.otherIncomeType === 'NONE' || formData.otherIncomeType === null"
                  v-model="formData.otherIncome"
                  v-validate="{ min_value: 1, max_value: 99999, required: false }"
                  name="otherIncome"
                  type="hidden"
                >
                <input
                  v-else
                  v-model="formData.otherIncome"
                  v-validate="{ min_value: 1, max_value: 99999, required: true }"
                  name="otherIncome"
                  type="hidden"
                  data-vv-scope="step4"
                >
                <span
                  v-show="errors.has('step4.otherIncome')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.otherIncome') ? 'Enter other income' : '' }}
                </span>
              </div>
              <div class="form-group col-lg-6 select">
                <select
                  v-model="formData.otherIncomeType"
                  v-validate="'required'"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('step4.otherIncomeType') }"
                  name="otherIncomeType"
                  data-vv-validate-on="none"
                  data-vv-scope="step4"
                >
                  <option
                    value="null"
                    selected
                    disabled
                  >
                    Other monthly income type*
                  </option>
                  <option
                    v-for="(type, index) in formDataDefaults.otherIncomeTypes"
                    :key="index"
                    :value="type"
                  >
                    {{ type | sentencecase }}
                  </option>
                </select>
                <span
                  v-show="errors.has('step4.otherIncomeType')"
                  class="invalid-feedback"
                >
                  {{ errors.first('step4.otherIncomeType') ? 'Select income type' : '' }}
                </span>
              </div>
            </div><!-- end form-row -->
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="next('step4')"
            >
              <div class="btn-text">
                Continue <font-awesome-icon icon="caret-right" />
              </div>
            </button>
            <button
              class="back btn btn-secondary btn-block"
              type="button"
              @click.prevent="prev()"
            >
              Back
            </button>
          </div>
        </div>
      </div> <!-- end step 4 -->
    </transition>
    <transition name="section">
      <div
        v-show="showStep(steps.step5)"
        class="accordion-item"
      >
        <section-title
          :step="getStep(5)"
          class="last-step"
        />
        <div
          class="section-content final-step"
        >
          <gtm-events
            v-if="showStep(steps.step5)"
            :event-data="getTrackingData(step)"
          />
          <h3>Let's look over your information</h3>
          <div class="row">
            <div class="col-md-6">
              <h4>Personal information</h4>
              <ul>
                <li><span>Full name</span> {{ formData.firstName }} {{ formData.lastName }}</li>
                <li><span>Email</span> {{ formData.email }}</li>
                <li><span>Cell phone number</span> {{ formData.phone }}</li>
                <li><span>DOB</span> {{ formData.dob }}</li>
                <li><span>SSN</span> {{ formData.ssn | maskssn }}</li>
              </ul>
              <h4>Residence information</h4>
              <ul>
                <li><span>Current address</span> {{ formData.address }} {{ formData.address2 }}<br>{{ formData.city }}, {{ formData.state == null ? '' : formData.state.value }} {{ formData.zip }}</li>
                <li><span>Residence type</span> {{ formData.residenceType }}</li>
                <li><span>Time of residence</span> {{ formData.residenceYears }} yrs {{ formData.residenceMonths }} mos</li>
                <li><span>Monthly mortgage/rent payment</span> {{ monthlyRentFormatted }}</li>
                <div v-if="residenceLessThanTwo">
                  <li><span>Previous address</span> {{ formData.previousAddress }} {{ formData.previousAddress2 }}<br>{{ formData.previousCity }}, {{ formData.previousState == null ? '' : formData.previousState.value }} {{ formData.previousZip }}</li>
                  <li><span>Previous time of residence</span> {{ formData.previousResidenceYears }} yrs {{ formData.previousResidenceMonths }} mos</li>
                </div>
              </ul>
            </div>
            <div class="col-md-6">
              <div v-if="isAuto">
                <h4>Vehicle information</h4>
                <ul>
                  <li><span>Year</span> {{ formData.vehicleYear }}</li>
                  <li><span>Make</span> {{ formData.vehicleMake }}</li>
                  <li><span>Model</span> {{ formData.vehicleModel }}</li>
                  <li><span>Trim</span> {{ formData.vehicleTrim }}</li>
                  <li><span>Current auto lender</span> {{ formData.currentLender }}</li>
                  <li><span>Monthly payment</span> {{ currentMonthlyPaymentFormatted }}</li>
                  <li><span>Mileage</span> {{ formData.estimatedMileage }}</li>
                </ul>
              </div>
              <div v-if="isPersonal">
                <h4>Personal loan information</h4>
                <ul>
                  <li><span>Loan purpose</span> {{ formData.loanPurpose }}</li>
                  <li><span>Loan amount</span> {{ loanAmountFormatted }}</li>
                  <li><span>Term</span> {{ formData.loanTerm }} months</li>
                </ul>
              </div>
              <h4>Employment information</h4>
              <ul>
                <li><span>Employment type</span> {{ formData.employmentType }}</li>
                <li><span>Job title</span> {{ formData.jobTitle }}</li>
                <li><span>Employer</span> {{ formData.employerName }}</li>
                <li><span>Employer's phone number</span> {{ formData.employerPhone }}</li>
                <li><span>Time of employment</span> {{ formData.employmentYears }} yrs {{ formData.employmentMonths }} mos</li>
                <li><span>Gross monthly income (before taxes)</span> {{ grossIncomeFormatted }}</li>
                <div v-if="employmentLessThanTwo">
                  <li><span>Previous employment type</span> {{ formData.previousEmploymentType }}</li>
                  <li><span>Previous job title</span> {{ formData.previousJobTitle }}</li>
                  <li><span>Previous employer</span> {{ formData.previousEmployerName }}</li>
                  <li><span>Previous employer's phone number</span> {{ formData.previousEmployerPhone }}</li>
                  <li><span>Previous time of employment</span> {{ formData.previousEmploymentYears }} yrs {{ formData.previousEmploymentMonths }} mos</li>
                </div>
              </ul>
            </div>
          </div>
          <div class="form">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <h4>Terms</h4>
                <p>
                  By submitting this application, you acknowledge that you have read and agree to our Electronic Document Policy,
                  <a
                    href="https://www.kasasa.com/legal/terms-and-conditions"
                    target="_blank"
                  >Terms of Use</a>, and
                  <a
                    href="https://www.kasasa.com/legal/privacy"
                    target="_blank"
                  >Privacy Policy</a>; that you are at least 18 years of age and that all the information provided is true, correct, and complete and you grant permission to Kasasa and/or its Lenders to obtain a copy of your credit report.
                </p>
                <div class="policy-disclaimer">
                  You are now ready to submit your application! By clicking on "I agree", you authorize us to verify the information you submitted and may obtain your credit report. Upon your request, we will tell you if a credit report was obtained and give you the name and address of the credit reporting agency that provided the report. You warrant to us that the information you are submitting is true and correct. By submitting this application, you agree to allow us to receive the information contained in your application, as well as the status of your application.
                  <br><br>
                  Important Information About Procedures For Opening New Accounts
                  <br><br>
                  To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.
                  <br><br>
                  What This Means For You
                  <br><br>
                  When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.
                </div>
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    id="agreeCheck"
                    v-model="formData.agree"
                    type="checkbox"
                    class="custom-control-input"
                    name="agree"
                  >
                  <label
                    class="custom-control-label"
                    for="agreeCheck"
                  >I have reviewed and consent to the <a
                    href="https://www.kasasa.com/hubfs/mainstreet/documents/eSign_Consent_Form.pdf"
                    target="_blank"
                  >
                    E-Sign Disclosure.
                  </a></label>
                </div>
              </div>
            </div><!-- End form-inputs -->
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              :disabled="!formData.agree || submitting"
              @click.prevent="submit()"
            >
              <div class="btn-text">
                <span
                  v-if="submitting"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                {{ (submitting) ? 'Loading..' : 'Submit' }}
              </div>
            </button>
            <button
              class="back btn btn-secondary btn-block"
              type="button"
              :disabled="submitting"
              @click.prevent="prev()"
            >
              Back
            </button>
          </div>
        </div>
      </div> <!-- end step 4 -->
    </transition>
    <error-modal ref="errorModal" />
  </div>
</template>

<script>
import { getVehicleTrimsByYearByMakeByModel, getVehicleModelsByYearByMake, getVehicleMakeByYear, getVehicleYears } from '@/api/vehicle.api';
import { getApplicationStatus, submitApplication } from '@/api/appservice.api';
import { apiClient } from '@/apiClient';
// import AccordionTitle from '@/components/AccordionTitle.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import GtmEvents from '@/components/GtmEvents.vue';
import ErrorModal from '@/components/ErrorModal.vue';

export default {
  components: {
    // AccordionTitle,
    SectionTitle,
    GtmEvents,
    ErrorModal
  },
  props: {
    loanType: {
      type: String,
      default: ''
    },
    loanDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    fiId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      step: 1,
      steps: {
        step1: {
          id: 1,
          title: this.getFirstStepTitle(),
          completed: false,
          active: true,
          show: true
        },
        step2: {
          id: 2,
          title: 'Personal information',
          completed: false,
          active: false,
          show: false,
        },
        step3: {
          id: 3,
          title: 'Residence information',
          completed: false,
          active: false,
          show: false
        },
        step4: {
          id: 4,
          title: 'Employment information',
          completed: false,
          active: false,
          show: false
        },
        step5: {
          id: 5,
          title: 'Review and Submit',
          completed: false,
          active: false,
          show: false
        },
      },
      formDataDefaults: {
        vehicleYears: [],
        vehicleMakes: [],
        vehicleModels: [],
        vehicleTrims: [],
        loanPurposes: [
          'Debt Consolidation',
          'Home Improvement',
          'Personal Expense'
        ],
        states: [
          { value: "AK", text: "Alaska" },
          { value: "AL", text: "Alabama" },
          { value: "AR", text: "Arkansas" },
          { value: "AS", text: "American Samoa" },
          { value: "AZ", text: "Arizona" },
          { value: "CA", text: "California" },
          { value: "CO", text: "Colorado" },
          { value: "CT", text: "Connecticut" },
          { value: "DC", text: "District of Columbia" },
          { value: "DE", text: "Delaware" },
          { value: "FL", text: "Florida" },
          { value: "GA", text: "Georgia" },
          { value: "GU", text: "Guam" },
          { value: "HI", text: "Hawaii" },
          { value: "IA", text: "Iowa" },
          { value: "ID", text: "Idaho" },
          { value: "IL", text: "Illinois" },
          { value: "IN", text: "Indiana" },
          { value: "KS", text: "Kansas" },
          { value: "KY", text: "Kentucky" },
          { value: "LA", text: "Louisiana" },
          { value: "MA", text: "Massachusetts" },
          { value: "MD", text: "Maryland" },
          { value: "ME", text: "Maine" },
          { value: "MI", text: "Michigan" },
          { value: "MN", text: "Minnesota" },
          { value: "MO", text: "Missouri" },
          { value: "MS", text: "Mississippi" },
          { value: "MT", text: "Montana" },
          { value: "NC", text: "North Carolina" },
          { value: "ND", text: "North Dakota" },
          { value: "NE", text: "Nebraska" },
          { value: "NH", text: "New Hampshire" },
          { value: "NJ", text: "New Jersey" },
          { value: "NM", text: "New Mexico" },
          { value: "NV", text: "Nevada" },
          { value: "NY", text: "New York" },
          { value: "OH", text: "Ohio" },
          { value: "OK", text: "Oklahoma" },
          { value: "OR", text: "Oregon" },
          { value: "PA", text: "Pennsylvania" },
          { value: "PR", text: "Puerto Rico" },
          { value: "RI", text: "Rhode Island" },
          { value: "SC", text: "South Carolina" },
          { value: "SD", text: "South Dakota" },
          { value: "TN", text: "Tennessee" },
          { value: "TX", text: "Texas" },
          { value: "UT", text: "Utah" },
          { value: "VA", text: "Virginia" },
          { value: "VI", text: "Virgin Islands" },
          { value: "VT", text: "Vermont" },
          { value: "WA", text: "Washington" },
          { value: "WI", text: "Wisconsin" },
          { value: "WV", text: "West Virginia" },
          { value: "WY", text: "Wyoming" }
        ],
        residenceYears: [ ...Array(100).keys() ],
        residenceMonths: [ ...Array(12).keys() ],
        residenceTypes: [
          'Rent',
          'Buying / Own with Mortgage',
          'Own - Free & Clear',
          'Live With Parents',
          'Government Quarters',
          'Other'
        ],
        employmentTypes: [
          'Full Time',
          'Part Time',
          'Self Employed',
          'Student',
          'Homemaker',
          'Retired',
          'Unemployed',
          'Military',
        ],
        otherIncomeTypes: [
          '2nd/3rd Jobs',
          'Bonus/Commission',
          'Child Support',
          'Disability',
          'Investments',
          'Pension/Annuity',
          'Rental Property',
          'Retirement',
          'Royalties',
          'Social Security',
          'Trust Fund',
        ]
      },
      formData: {
        currentLender: null,
        vehicleYear: null,
        vehicleMake: null,
        vehicleModel: null,
        vehicleTrim: null,
        currentMonthlyPayment: null,
        estimatedMileage: null,
        loanPurpose: null,
        loanAmount: null,
        loanTerm: null,
        creditScore: null,
        residenceType: null,
        residenceYears: null,
        residenceMonths: null,
        previousResidenceYears: null,
        previousResidenceMonths: null,
        firstName: null,
        lastName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        previousAddress: null,
        previousAddress2: null,
        previousCity: null,
        previousState: null,
        previousZip: null,
        email: null,
        dob: null,
        phone: null,
        monthlyRent: null,
        employmentType: null,
        jobTitle: null,
        employerName: null,
        employerPhone: null,
        employmentYears: null,
        employmentMonths: null,
        previousEmploymentType: null,
        previousJobTitle: null,
        previousEmployerName: null,
        previousEmployerPhone: null,
        previousEmploymentYears: null,
        previousEmploymentMonths: null,
        grossIncome: null,
        otherIncome: null,
        otherIncomeType: null,
        ssn: null,
        agree: false
      },
      loanAmountFormatted: null,
      currentMonthlyPaymentFormatted: null,
      monthlyRentFormatted: null,
      grossIncomeFormatted: null,
      otherIncomeFormatted: null,
      ssnFormatted: null,
      ssnToggleStatus: 'password',
      ssnToggleStatusLabel: 'show',
      residenceLessThanTwo: false,
      employmentLessThanTwo: false,
      isAuto: false,
      isPersonal: false,
      regexRules: {
        alphaDash: /^[A-Z-\s]*$/i,
        alphaDashApos: /^[A-Z-'\s]*$/i,
      },
      disableFields: {
        loanAmount: false,
        loanTerm: false,
        loanPurpose: false,
        address: false,
        city: false,
        state: false,
        zip: false,
        email: false
      },
      submitting: false,
      maxPollingTriesReached: false
    }
  },
  computed: {
    termFormatted: function () {
      return `${this.formData.loanTerm} months`;
    }
  },
  watch: {
    loanAmountFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.loanAmount = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.loanAmount = '';
      }
    },
    currentMonthlyPaymentFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.currentMonthlyPayment = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.currentMonthlyPayment = '';
      }
    },
    monthlyRentFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.monthlyRent = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.monthlyRent = '';
      }
    },
    grossIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.grossIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.grossIncome = '';
      }
    },
    otherIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.otherIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.otherIncome = '';
      }
    },
    ssnFormatted: function (val) {
      if (val !== null) {
        this.formData.ssn = val.replace(/-/g,'');
      }
      if (val === '') {
        this.formData.ssn = '';
      }
    },
    'formData.employmentYears': function (val) {
      const years = parseFloat(val)
      this.employmentLessThanTwo = ( years < 2 ) ? true : false;
    },
    'formData.residenceYears': function (val) {
      const years = parseFloat(val)
      this.residenceLessThanTwo = ( years < 2 ) ? true : false;
    }
  },
  created() {
    if ( this.loanType === 'VEHICLE' ) {
      this.isAuto = true;
    } else if ( this.loanType === 'PERSONAL' ) {
      this.isPersonal = true;
    }

    // Initialize data for fields
    this.initFields();

    // Prefill based on URL params
    this.initPreFill();
  },
  methods: {
    async getVehicleTrims() {
      if ( this.formData.vehicleModel !== null ) {
        try {
          const response = await getVehicleTrimsByYearByMakeByModel(this.formData.vehicleYear, this.formData.vehicleMake, this.formData.vehicleModel);
          this.formDataDefaults.vehicleTrims = response.data;
        } catch(error) {
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          console.log(error);
        }
      }
    },
    async getVehicleModels() {
      if ( this.formData.vehicleMake !== null ) {
        try {
          const response = await getVehicleModelsByYearByMake(this.formData.vehicleYear, this.formData.vehicleMake);
          this.formDataDefaults.vehicleModels = response.data;
        } catch(error) {
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          console.log(error);
        }
      }
    },
    async getVehicleMakes() {
      if ( this.formData.vehicleYear !== null ) {
        try {
          const response = await getVehicleMakeByYear(this.formData.vehicleYear);
          this.formDataDefaults.vehicleMakes = response.data;
        } catch(error) {
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          console.log(error);
        }
      }
    },
    onChangeYear(event){
      // If year changes, reset vehicle make, model, trims data
      this.formData.vehicleMake = null;
      this.formData.vehicleModel = null;
      this.formData.vehicleTrim = null;
      this.formDataDefaults.vehicleMakes = [];
      this.formDataDefaults.vehicleModels = [];
      this.formDataDefaults.vehicleTrims = [];

      // Get vehicle makes based on year
      this.getVehicleMakes();
    },
    onChangeMake(event){
      // If make changes, reset vehicle model and trim data
      this.formData.vehicleModel = null;
      this.formData.vehicleTrim = null;
      this.formDataDefaults.vehicleModels = [];
      this.formDataDefaults.vehicleTrims = [];

      // Get vehicle models based on year and make
      this.getVehicleModels();
    },
    onChangeModel(event){
      // If make changes, reset vehicle model data
      this.formData.vehicleTrim = null;
      this.formDataDefaults.vehicleTrims = [];

      // Get vehicle models based on year and make
      this.getVehicleTrims();
    },
    toggleSsnStatus() {
      this.ssnToggleStatus = ( this.ssnToggleStatus === 'password' ) ? 'text' : 'password';
      this.ssnToggleStatusLabel = ( this.ssnToggleStatus === 'password' ) ? 'show' : 'hide';
    },
    preventNonNumericInput(event) {
      let value = event.target.value;
      // Check if value is number
      let isValid = +value == +value;

      if (!isValid) {
        // input event is not cancellable by preventDefault()
        // so we have to use the below approach to reset the current value to previous one
        var resetEvent = document.createEvent('Event');
        resetEvent.initEvent('input', true, true);
        event.target.value = event.target._value;
        event.target.dispatchEvent(resetEvent);
      }
    },
    async initFields() {
      if ( this.isAuto ) {
        // Get vehicle years
        try {
          const response = await getVehicleYears();
          this.formDataDefaults.vehicleYears = response.data;
        } catch(error) {
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          console.log(error);
        }
      }
    },
    initPreFill() {
      // Initialize prefill
      const urlQuery = this.$route.query;
      const applicationData = this.$store.state.applicationData;

      // If loan amount not null or undefined
      if ( !!applicationData.amount ) {
        this.loanAmountFormatted = applicationData.amount;
        this.formData.loanAmount = applicationData.amount;
        this.disableFields.loanAmount = true;
      }

      // If loan term not null or undefined
      if ( !!urlQuery.term ) {
        this.formData.loanTerm = urlQuery.term;
        this.disableFields.loanTerm = true;
      }

      // If loan purpose not null or undefined
      if ( !!applicationData.loanPurposeType ) {
        this.formData.loanPurpose = applicationData.loanPurposeType;
        this.disableFields.loanPurpose = true;
      }

      // If credit score not null or undefined
      if ( !!urlQuery.credit_score ) {
        this.formData.creditScore = urlQuery.credit_score;
      }

      // If vehicle_year not null or undefined
      if ( !!applicationData.year ) {
        this.formData.vehicleYear = applicationData.year;
        this.getVehicleMakes();
      }

      // If vehicle_make not null or undefined
      if ( !!applicationData.make ) {
        this.formData.vehicleMake = applicationData.make;
        this.getVehicleModels();
      }

      if ( !!applicationData.model ) {
        this.formData.vehicleModel = applicationData.model;
        this.getVehicleTrims();
      }

      if ( !!applicationData.mileage ) {
        this.formData.estimatedMileage = applicationData.mileage;
      }

      if ( !!applicationData.firstName ) {
        this.formData.firstName = applicationData.firstName;
      }

      if ( !!applicationData.lastName ) {
        this.formData.lastName = applicationData.lastName;
      }

      if ( !!applicationData.address ) {
        this.formData.address = applicationData.address;
        //this.disableFields.address = true;
      }

      if ( !!applicationData.city ) {
        this.formData.city = applicationData.city;
        //this.disableFields.city = true;
      }

      if ( !!applicationData.state ) {
        function isState(state) {
          return state.value === applicationData.state;
        }
        this.formData.state = this.formDataDefaults.states.find(isState);
        //this.disableFields.state = true;
      }

      if ( !!applicationData.zip ) {
        this.formData.zip = applicationData.zip;
        //this.disableFields.zip = true;
      }

      if ( !!applicationData.email ) {
        this.formData.email = applicationData.email;
        this.disableFields.email = true;
      }

      if ( !!applicationData.phone ) {
        this.formData.phone = applicationData.phone;
      }

      if ( !!applicationData.dob ) {
        this.formData.dob = applicationData.dob;
      }

      // If gross income not null or undefined
      if ( !!applicationData.grossMthInc ) {
        this.grossIncomeFormatted = applicationData.grossMthInc;
        this.formData.grossIncome = applicationData.grossMthInc;
      }

    },
    showContent(stepScope) {
      // If step is active allow show
      if ( stepScope.active ) {
        stepScope.show = !stepScope.show;
      }
    },
    showStep(stepScope) {
      // If active and shown
      if ( stepScope.active && stepScope.show ) {
        return true;
      }
      return false;
    },
    validate(scope) {
      // If no scope validate all fields
      if (!scope) {
        return this.$validator.validate();
      }

      // if scope validate fields in scope
      return this.$validator.validateAll(scope);
    },
    scrollStepIntoView(step) {
     //document.querySelector(`.${step}`).scrollIntoView();
     document.querySelector('.application-page').scrollIntoView();
    },
    prev(){
      // Hide current step
      const currentStep = `step${self.step}`;
      self.steps[currentStep].show = false;

      // Go back a step
      this.step--;

      // Show previous step
      const prevStep = `step${self.step}`;
      this.steps[prevStep].show = true;

      // For mobile
      this.scrollStepIntoView(prevStep);
    },
    next(stepScope) {
      self = this;

      if ( stepScope !== 'none' ) {
        this.validate(stepScope).then(function(valid) {
          if (valid) {
            // Set current step as completed and hide
            self.steps[stepScope].completed = true;
            self.steps[stepScope].active = true;
            self.steps[stepScope].show = false;

            // Go to next step
            self.step = self.steps[stepScope].id + 1;

            // Find next step and show
            const nextStep = `step${self.step}`;
            self.steps[nextStep].active = true;
            self.steps[nextStep].show = true;

            // For mobile
            self.scrollStepIntoView(nextStep);
          } else {
            self.steps[stepScope].completed = false;
            // Show error messages
            document.querySelectorAll('.invalid-feedback').forEach(function(item){
              item.style.visibility = 'visible';
            })
          }
        });
      } else {
        self.step++;
      }
      // Hide error messages on click
      document.querySelectorAll('.invalid-feedback').forEach(function(item){
        item.addEventListener('click', function() {
          this.style.visibility = 'hidden';
        })
      })
    },
    getFirstStepTitle(){
      // AutoRefi
      if ( this.loanType === 'VEHICLE' ) {
        return 'Vehicle information';
      }
      // Personal
      return 'Loan information';
    },
    getStep(step) {
      // Build step object for sections
      return {
        ...this.steps[`step${step}`],
        ...{
          showStep: this.showStep(this.steps[`step${step}`])
        }
      }
    },
    async submit() {
      const payload = this.getPayload();
      this.submitting = true;

      if ( this.maxPollingTriesReached ) {
        // continue polling after submit
        this.getStatusPolling(0);
      } else {
        // Start polling
        this.getStatusPolling(0);

        try {
          const apply = await apiClient.post(`/api/v1/kloans-loan-application/submit`, payload);
        } catch (e) {
          console.log(e);
          this.$refs.errorModal.closeButton = true;
          this.$refs.errorModal.showModal('technical');
          this.submitting = false;
        }
      }
    },
    async getStatusPolling(retryCount) {
      try {
        const polledData = await this.getApplicationStatus();
        this.checkAppStatus(polledData, retryCount)
      } catch (e) {
        console.log(e);
        this.$refs.errorModal.returnButton = true;
        this.$refs.errorModal.showModal('technical');
        this.submitting = false;
      }
    },
    async getApplicationStatus() {
      const payload = {
        "applicationExternalId": this.$store.state.applicationId
      };

      return await apiClient.post(`/api/v1/kloans-loan-application/get-status`, payload, { timeout: 7000 });
    },
    async checkAppStatus(polledData, retryCount) {
      const status = polledData.data.data.status;
      const loanNumber = polledData.data.data.loanNumber;

      switch (status) {
        case 'SUBMIT_FAILED':
          // Send to confirmation page with custom message
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          return false;
          break;
        case 'APPROVED':
        case 'REFERRED':
        case 'DECLINED':
          // Send to confirmation with respective message
          this.sendToConfirmation({ status, loanNumber });
          break;
        case 'NEW':
        case 'PREQUALIFIED':
        case 'SUBMITTED':
        default:
          // continue
          if ( retryCount > 10 ) {
            console.log('reached max retries');
            this.maxPollingTriesReached = true;
            this.submitting = false;
            return false;
          }
          await this.delayPoll(retryCount);
          this.getStatusPolling(retryCount + 1);
      }
    },
    delayPoll(retryCount) {
      return new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
    },
    sendToConfirmation(data) {
      this.$router.push(
        {
          name: 'Confirmation',
          params: {
            applicant: {
              firstName: this.formData.firstName,
              lastName: this.formData.lastName,
              applicationId: this.$store.state.applicationId,
              status: data.status,
              loanNumber: data.loanNumber
            }
          }
        }
      );
    },
    getTrackingData(step) {
      return {
        title: this.steps[`step${step}`]['title'],
        stepNumber: this.steps[`step${step}`]['id'],
      }
    },
    getPayload() {
      const applicationData = this.$store.state.applicationData;
      const loanDetails = this.$store.state.loanDetails;
      const applicationId = this.$store.state.applicationId

      return {
        "applicationExternalId": applicationId,
        "applicationSource": applicationData.applicationSource,
        "loanPurposeType": applicationData.loanPurposeType,
        "term": loanDetails.term,
        "firstName": this.formData.firstName.trim(),
        "middleName": null,
        "lastName": this.formData.lastName.trim(),
        "dob": this.formData.dob,
        "ssn": this.formData.ssn,
        "email": this.formData.email,
        "phone": this.formData.phone,
        "resType": this.formData.residenceType.toUpperCase(),
        "address": this.formData.address,
        "apt": null,
        "city": this.formData.city.trim(),
        "state": this.formData.state.value,
        "zip": this.formData.zip,
        "county": applicationData.county,
        "addrY": this.formData.residenceYears,
        "addrM": this.formData.residenceMonths,
        "housePmt": this.formData.monthlyRent.toString(),
        "empType": this.formData.employmentType.toUpperCase(),
        "empTitle": this.formData.jobTitle.trim(),
        "employer": this.formData.employerName.trim(),
        "empPhone": this.formData.employerPhone,
        "empExt": null,
        "empY": this.formData.employmentYears,
        "empM": this.formData.employmentMonths,
        "empAddress": null,
        "empCity": null,
        "empState": null,
        "empZip": null,
        "grossMthInc": this.formData.grossIncome.toString(),
        "otherMthInc": this.formData.otherIncome === null ? null : this.formData.otherIncome.toString(),
        "otherIncType": this.formData.otherIncomeType === 'NONE' ? null : this.formData.otherIncomeType,
        "prevEmpType": this.formData.previousEmploymentType === null ? null : this.formData.previousEmploymentType.toUpperCase(),
        "prevEmployer": this.formData.previousEmployerName === null ? null : this.formData.previousEmployerName.trim(),
        "prevEmpTitle": this.formData.previousJobTitle === null ? null : this.formData.previousJobTitle.trim(),
        "prevEmpPhone": this.formData.previousEmployerPhone === null ? null : this.formData.previousEmployerPhone.trim(),
        "prevEmpExt": null,
        "prevEmpY": this.formData.previousEmploymentYears,
        "prevEmpM": this.formData.previousEmploymentMonths,
        "make": this.formData.vehicleMake,
        "model": this.formData.vehicleModel,
        "year": this.formData.vehicleYear,
        "bodyStyle": this.formData.vehicleTrim,
        "mileage": this.formData.estimatedMileage,
        "vin": null,
        "options": null,
        "curPmt": this.formData.currentMonthlyPayment == null ? null : this.formData.currentMonthlyPayment.toString(),
        "curLien": this.formData.currentLender,
        "prevAddress": this.formData.previousAddress,
        "prevApt": null,
        "prevCity": this.formData.previousCity === null ? null : this.formData.previousCity.trim(),
        "prevState": this.formData.previousState === null ? null : this.formData.previousState.value,
        "prevZip": this.formData.previousZip,
        "prevCounty": null,
        "prevAddrY": this.formData.previousResidenceYears,
        "prevAddrM": this.formData.previousResidenceMonths
      }
    },
  }
}
</script>