export const generator = (name, validator) => [name, validator];

const VALIDATION_MESSAGE = 'Field must be positive';

const RULE_NAME = 'positive';

export const positiveRule = [RULE_NAME, {
  getMessage: (field, { message } = {}) => message || VALIDATION_MESSAGE,
  validate: (value, { allowZero }) => {
    if (Number.isNaN(Number(value))) {
      return false;
    }

    if (allowZero) {
      return Number(value) >= 0;
    }

    return Number(value) > 0;
  }
}, {
  paramsNames: ['message', 'allowZero'],
}];
