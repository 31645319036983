export const generator = (name, validator) => [name, validator];

const VALIDATION_MESSAGE = 'Field is required';

const RULE_NAME = 'requiredExtended';

export const requiredExtendedRule = [RULE_NAME, {
  getMessage: (field, { message } = {}) => message || VALIDATION_MESSAGE,
  validate: (value) => {
    const isValid = !(
      value === null
      || value === undefined
      || String(value).length === 0
    );

    return {
      valid: isValid,
      data: {
        required: true
      }
    };
  }
}, {
  computesRequired: true,
  paramsNames: ['message'],
}];
