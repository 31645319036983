import { apiClient } from "@/apiClient";

const getVehicleTrimsByYearByMakeByModel = async (year, make, model) => {
  return await apiClient.get(`/api/v1/pi-kloans-prequal-manager/vehicle/${year}/${make}/${model}/styles`);
}

const getVehicleModelsByYearByMake = async (year, make) => {
  return await apiClient.get(`/api/v1/pi-kloans-prequal-manager/vehicle/${year}/${make}/models`);
}

const getVehicleMakeByYear = async (year) => {
  return await apiClient.get(`/api/v1/pi-kloans-prequal-manager/vehicle/${year}/makes`);
}

const getVehicleYears = async () => {
  return await apiClient.get(`/api/v1/pi-kloans-prequal-manager/vehicle/years`);
}

export {
  getVehicleTrimsByYearByMakeByModel,
  getVehicleModelsByYearByMake,
  getVehicleMakeByYear,
  getVehicleYears
}