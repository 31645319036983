<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-9 text-center channel-partner-intro">
      <div
        v-if="section === 'application'"
      >
        <h1>Get started on the Kasasa Loan<sup>&reg;</sup> application</h1>
        <h2>It only takes about 5 minutes.</h2>
      </div>
      <div
        v-else-if="section === 'review'"
      >
        <h1>Application review</h1>
        <h2>Take a second to make sure all of the information provided is correct.</h2>
      </div>
      <div
        v-else-if="section === 'results'"
      >
        <h1>Loan results</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: String,
      default: ''
    }
  }
}
</script>