<template>
  <div
    :data-event="event"
    class="gtm-event"
  />
</template>

<script>
export default {
    props: {
        eventData: {
            type: Object,
            default () {
                return {}
            }
        },
        eAdd: {
            type: Boolean,
            default: false
        },
        ePurchase: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            event: {}
        }
    },
    created() {
        // Initialize dataLayer
        window.dataLayer = window.dataLayer || [];

        // Trigger page view
        this.triggerPageView();

        // Trigger ecommerce steps
        this.triggerESteps();

        // Trigger ecommerce add
        if (this.eAdd === true) {
            this.triggerEAdd();
        }

        // Trigger ecommerce purchase
        if (this.ePurchase === true) {
            this.triggerEPurchase();
        }
    },
    methods: {
        triggerPageView() {
            this.layerPush({
                'page': this.getPageViewData(),
                'event': 'pageLoad'
            });
        },
        triggerEPurchase() {
            this.layerPush({
                'event':'ecommerce.purchase',
                'ecommerce': {
                    'currencyCode': 'USD',
                    'purchase': {
                        'actionField':{
                            'id': this.$store.state.applicationId,
                            'affiliation':  this.$store.state.fiId
                        },
                        'products': this.getProductsArray(true)
                    }
                }
            });
        },
        triggerESteps() {
            this.layerPush({
                'event':'ecommerce.checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField':{
                            'step': this.getStepNumber()
                        },
                        'products': ( this.getStepNumber() === 1 ) ? this.getProductsArray() : []
                    }
                }
            });
        },
        triggerEAdd() {
            this.layerPush(
                {
                    'event':'ecommerce.add',
                    'ecommerce': {
                        'add': {
                            'products': this.getProductsArray()
                        }
                    }
                }
            );
        },
        layerPush(eventData) {
			if (this.$store.state.channelPartnerForm) {
				// Only fire these if in D2C
				// GtmService handles Channel Partner and everything else
				// This should eventually be migrated completely to GtmService
				return;
			}

            window.dataLayer.push(eventData);
        },
        getProductsArray(withPrice) {
            let productsObj = {
                'name': this.getProductType(),
                'id': this.getLoanPurpose(),
                'variant':  this.$store.state.loanDetails.term,
                'rate':  this.$store.state.loanDetails.rate,
                'brand': 'D2C',
                'category': `Personal/Kasasa Loans/${this.getProductType()}`,
                'quantity': 1
            };

            if ( withPrice === true ) {
                productsObj = {
                    ...productsObj,
                    'price': this.$store.state.loanDetails.amountApproved,
                }
            }

            return [productsObj]
        },
        getPageViewData() {
            const pageViewObj = {
                'pageType': 'application',
                'fiId': this.$store.state.fiId,
                'fiName': this.$store.state.fiName,
                'pageName': this.getStepTitle(),
                'productType': this.getProductType(),
                'loanPurpose': this.getLoanPurpose(),
                'rate': this.$store.state.loanDetails.rate,
                'amount': this.$store.state.loanDetails.amountApproved,
                'creditScore': this.getCreditScore(),
                'websiteType': 'Mainstreet Loans'
            }

            return pageViewObj;
        },
        getProductType() {
            return ( this.$store.state.loanType === 'VEHICLE' ) ? 'Vehicle Loan' : 'Personal Loan' ;
        },
        getLoanPurpose() {
            return this.$store.state.loanPurpose;
        },
        getStepTitle() {
            return this.eventData.title;
        },
        getStepNumber() {
            return this.eventData.stepNumber;
        },
        getCreditScore() {
            return this.$store.state.creditScore;
        }
    }
}
</script>