<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <div
        class="font-barlow-bold"
        style="flex: 0 0 65%;"
      >
        Personal information
      </div>
      <div>
        <button
          type="button"
          class="btn btn-link btn-link-primary"
          @click="handleEdit"
        >
          {{ isEditing ? 'CANCEL' : 'EDIT INFO' }}
        </button>
      </div>
    </div>

    <div class="mb-2">
      <div>
        Full name
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.firstName.value }} {{ form.lastName.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="form.firstName.value"
            v-validate="form.firstName.validators"
            :name="form.firstName.name"
            type="text"
            placeholder="First name*"
            class="form-control"
            :class="{'is-invalid': form.firstName.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.firstName.error.state"
            :message="form.firstName.error.message"
          />
        </div>
        <div class="form-group position-relative">
          <input
            v-model="form.lastName.value"
            v-validate="form.lastName.validators"
            :name="form.lastName.name"
            type="text"
            placeholder="Last name*"
            class="form-control"
            :class="{'is-invalid': form.lastName.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.lastName.error.state"
            :message="form.lastName.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        Address
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ fullAddress }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <AddressInput
            :is-invalid="form.address1.error.state.isShown"
            placeholder="Address*"
            :value="form.address1.value"
            @change="handleAddressInputChange"
            @context="handleAddressInputContext"
          />
          <input
            v-model="form.address1.value"
            v-validate="form.address1.validators"
            :name="form.address1.name"
            type="hidden"
          >
          <InputError
            :state="form.address1.error.state"
            :message="form.address1.error.message"
          />
        </div>

        <div class="form-group position-relative">
          <input
            v-model="form.address2.value"
            v-validate="form.address2.validators"
            :name="form.address2.name"
            type="text"
            placeholder="Address line 2"
            class="form-control"
            :class="{'is-invalid': form.address2.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.address2.error.state"
            :message="form.address2.error.message"
          />
        </div>

        <div class="form-group position-relative">
          <input
            v-model="form.city.value"
            v-validate="form.city.validators"
            :name="form.city.name"
            type="text"
            placeholder="City*"
            class="form-control"
            :class="{'is-invalid': form.city.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.city.error.state"
            :message="form.city.error.message"
          />
        </div>

        <div class="form-group select position-relative">
          <select
            v-model="form.state.value"
            v-validate="form.state.validators"
            class="form-control"
            :class="{'is-invalid': form.state.error.state.isShown}"
            :name="form.state.name"
            @change="handleInput"
          >
            <option
              value="null"
              selected
              disabled
            >
              State*
            </option>
            <option
              v-for="(state, index) in formDataDefaults.states"
              :key="index"
              :value="state.value"
            >
              {{ state.value }}
            </option>
          </select>
          <InputError
            :state="form.state.error.state"
            :message="form.state.error.message"
          />
        </div>

        <div class="form-group position-relative">
          <input
            v-model="form.zip.value"
            v-validate="form.zip.validators"
            v-mask="'##########'"
            :name="form.zip.name"
            type="text"
            placeholder="ZIP*"
            class="form-control"
            :class="{'is-invalid': form.zip.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.zip.error.state"
            :message="form.zip.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        Email
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.email.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="form.email.value"
            v-validate="form.email.validators"
            :name="form.email.name"
            type="text"
            placeholder="Email*"
            class="form-control"
            :class="{'is-invalid': form.email.error.state.isShown}"
            @input="handleInput"
          >
          <InputError
            :state="form.email.error.state"
            :message="form.email.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        Birthdate
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.dob.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="form.dob.value"
            v-validate="form.dob.validators"
            v-mask="'##/##/####'"
            class="form-control"
            :class="{'is-invalid': form.dob.error.state.isShown }"
            data-vv-validate-on="none"
            type="text"
            :name="form.dob.name"
            placeholder="Birthdate* (MM/DD/YYYY)"
            @input="handleInput"
          >
          <InputError
            :state="form.dob.error.state"
            :message="form.dob.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        Mobile number
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.phone.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="form.phone.value"
            v-validate="form.phone.validators"
            v-mask="'(###) ###-####'"
            class="form-control"
            :class="{'is-invalid': form.phone.error.state.isShown }"
            data-vv-validate-on="none"
            type="text"
            :name="form.phone.name"
            placeholder="Mobile number* (XXX) XXX-XXXX"
            @input="handleInput"
          >
          <InputError
            :state="form.phone.error.state"
            :message="form.phone.error.message"
          />
        </div>
      </div>
    </div>

    <div
      class="mb-2"
    >
      <div>
        Annual income
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ formatDollar(form.annualIncome.value) }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="annualIncomeFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            name="annualIncomeFormatted"
            type="text"
            placeholder="Annual income*"
            class="form-control"
            :class="{'is-invalid': form.annualIncome.error.state.isShown}"
            @input="handleInput"
          >
          <input
            v-model="form.annualIncome.value"
            v-validate="form.annualIncome.validators"
            :name="form.annualIncome.name"
            type="hidden"
          >
          <InputError
            :state="form.annualIncome.error.state"
            :message="form.annualIncome.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        SSN
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.ssn.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="ssnFormatted"
            v-mask="'###-##-####'"
            type="text"
            placeholder="Social Security number* XXX-XX-XXXX"
            class="form-control"
            :class="{'is-invalid': form.ssn.error.state.isShown}"
            @input="handleInput"
          >
          <input
            v-model="form.ssn.value"
            v-mask="'#########'"
            v-validate="form.ssn.validators"
            :name="form.ssn.name"
            type="hidden"
          >
          <InputError
            :state="form.ssn.error.state"
            :message="form.ssn.error.message"
          />
        </div>
      </div>
    </div>

    <div class=" mb-2">
      <div>
        Other monthly income type
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.otherIncomeType.value }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative select">
          <select
            v-model="form.otherIncomeType.value"
            class="form-control"
            :name="form.otherIncomeType.name"
            @change="handleInput"
          >
            <option
              value="null"
              selected
              disabled
            >
              Other monthly income type
            </option>
            <option
              v-for="(type, index) in formDataDefaults.otherIncomeTypes"
              :key="index"
              :value="type"
            >
              {{ type | sentencecase }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div
      v-show="isOtherIncomeTypeSelected"
      class="mb-2"
    >
      <div>
        Other monthly income amount
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ formatDollar(form.otherIncome.value) }}
      </div>

      <div
        v-show="isEditing"
      >
        <div class="form-group position-relative">
          <input
            v-model="otherIncomeFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            :disabled="!isOtherIncomeTypeSelected"
            name="otherIncomeFormatted"
            type="text"
            placeholder="Other monthly income amount"
            class="form-control"
            :class="{'is-invalid': form.otherIncome.error.state.isShown}"
            @input="handleInput"
          >
          <input
            v-model="form.otherIncome.value"
            v-validate="form.otherIncome.validators(isOtherIncomeTypeSelected)"
            :name="form.otherIncome.name"
            type="hidden"
          >
          <InputError
            :state="form.otherIncome.error.state"
            :message="form.otherIncome.error.message"
          />
        </div>
      </div>
    </div>

    <div
      v-show="isEditing"
      class="mt-4"
    >
      <button
        class="next btn btn-primary btn-block"
        type="button"
        @click="validate()"
      >
        <div class="btn-text">
          Update
          <font-awesome-icon
            icon="caret-right"
            class="ml-2"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';
import { formatDollar } from '@/utils/utils';
import AddressInput from '@/components/AddressInput.vue';
import { formDataDefaults } from '@/utils/formDataDefaults';

const PersonalInformationReview = {
  components: {
    InlineValidationError,
    InputError,
    AddressInput,
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        firstName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'firstName',
          validators: {
            requiredExtended: {
              message: 'Enter first name',
            },
            specialChars: {
              message: 'First name cannot have special characters',
            },
          },
        },
        lastName: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'lastName',
          validators: {
            requiredExtended: {
              message: 'Enter last name',
            },
            specialChars: {
              message: 'First name cannot have special characters',
            },
          },
        },
        email: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'email',
          validators: {
            requiredExtended: {
              message: 'Enter email',
            },
            email: true,
          },
        },
        address1: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'address1',
          validators: {
            requiredExtended: {
              message: 'Enter address',
            },
            specialChars: {
              message: 'Address cannot have special characters',
            },
          },
        },
        address2: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'address2',
          validators: {
            specialChars: {
              message: 'Address 2 cannot have special characters',
            },
          },
        },
        city: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'city',
          validators: {
            requiredExtended: {
              message: 'Enter city',
            },
            specialChars: {
              message: 'City cannot have special characters'
            }
          },
        },
        state: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'state',
          validators: {
            requiredExtended: {
              message: 'Choose state',
            },
          },
        },
        zip: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'zip',
          validators: {
            requiredExtended: {
              message: 'Enter ZIP',
            },
          },
        },
        dob: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'dob',
          validators: {
            requiredExtended: {
              message: 'Enter date of birth (MM/DD/YYYY)',
            },
            date_format: 'MM/dd/yyyy',
            isOldRange: true,
            isOld: true,
          },
        },
        phone: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'phone',
          validators: {
            requiredExtended: {
              message: 'Enter mobile number',
            },
            isUsPhone: true,
          },
        },
        annualIncome: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'annualIncome',
          validators: {
            requiredExtended: {
              message: 'Enter annual income',
            },
            positive: {
              message: 'Annual income must be positive',
            },
          },
        },
        ssn: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'ssn',
          validators: {
            requiredExtended: {
              message: 'Enter SSN'
            },
            min: 9,
          },
        },
        otherIncomeType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'otherIncomeType',
        },
        otherIncome: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'otherIncome',
          validators: (isOtherIncomeTypeSelected) => ({
            requiredExtended: isOtherIncomeTypeSelected && {
              message: 'Enter other income'
            },
            positive: isOtherIncomeTypeSelected && {
              message: 'Other income must be positive'
            }
          }),
        },
      },
      formDataDefaults,
      fullAddress: '',
      formatDollar,
      loanAmountFormatted: null,
      hasValidated: false,
      isEditing: false,
      otherIncomeFormatted: null,
      ssnFormatted: null,
      annualIncomeFormatted: null,
    };
  },
  computed: {
    isOtherIncomeTypeSelected() {
      return (this.form.otherIncomeType.value !== null && this.form.otherIncomeType.value !== 'NONE');
    }
  },
  mounted() {
    this.setFormValues(this.formData);
  },
  methods: {
    handleInputError(input) {
      this.form[input].error = {
        ...this.form[input].error,
        state: {
          ...this.form[input].error.state,
          isShown: this.errors.has(input),
        },
        message: this.errors.first(input),
      };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {
        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.setFullAddress(this.form);
          this.isEditing = false;
          this.$emit('collect-data', { form: this.form, isValid: true });
        }
      });

      this.hasValidated = true;
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    },
    setFormValues(data) {
      try {
        this.form.address1.value = data.address1.value;
        this.form.address2.value = data.address2.value;
        this.form.city.value = data.city.value;
        this.form.state.value = data.state.value;
        this.form.zip.value = data.zip.value;

        this.form.firstName.value = data.firstName.value;
        this.form.lastName.value = data.lastName.value;
        this.form.email.value = data.email.value;
        this.form.dob.value = data.dob.value;
        this.form.ssn.value = data.ssn.value;
        this.form.otherIncomeType.value = data.otherIncomeType.value;
        this.form.otherIncome.value = data.otherIncome.value;
        this.form.phone.value = data.phone.value;
        this.form.annualIncome.value = data.annualIncome.value;

        this.otherIncomeFormatted = data.otherIncome.value;
        this.ssnFormatted = data.ssn.value;
        this.annualIncomeFormatted = data.annualIncome.value;
        this.setFullAddress(this.form);
      } catch (error) {
        console.error('Invalid form data passed');
      }
    },
    handleEdit() {
      this.isEditing = !this.isEditing;

      if (!this.isEditing) {
        this.setFormValues(this.formData);
      }
    },
    handleAddressInputChange(address) {
      this.form.address1.value = address;
      this.handleInput();
    },
    handleAddressInputContext(context) {
      this.form.city.value = context.city;
      this.form.state.value = context.state;
      this.form.zip.value = context.postalCode;
    },
    handleSsnChange(ssn) {
      this.form.ssn.value = ssn;
      this.handleInput();
    },
    setFullAddress(data) {
      const address2String = (data.address2.value && data.address2.value.length > 0)
        ? ` ${data.address2.value}`
        : '';

      this.fullAddress = `${data.address1.value}${address2String}, ${data.city.value}, ${data.state.value} ${data.zip.value}`;
    },
  },
  watch: {
    loanAmountFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.]/g,'');

      this.form.loanAmount.value = amount;
    },
    formData(data) {
      this.setFormValues(data)
    },
    otherIncomeFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.]/g,'');

      this.form.otherIncome.value = amount;
    },
    annualIncomeFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.]/g,'');

      this.form.annualIncome.value = amount;
    },
    ssnFormatted(ssnFormatted) {
      const ssn = ssnFormatted.replace(/\D/g, '');

      this.form.ssn.value = ssn;
    },
  },
};

export default PersonalInformationReview;
</script>