<template>
  <div
    :class="[{ 'open' : step.showStep, 'incomplete' : !step.completed && !step.showStep }, getStepClass(step) ]"
    class="step section-title"
  >
    {{ step.title }}
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    getStepClass(step) {
      return `step${step.id}`;
    }
  }
}
</script>