<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <div
        class="font-barlow-bold"
        style="flex: 0 0 65%;"
      >
        Loan information
      </div>
      <div>
        <button
          type="button"
          class="btn btn-link btn-link-primary"
          @click="handleEdit"
        >
          {{ isEditing ? 'CANCEL' : 'EDIT INFO' }}
        </button>
      </div>
    </div>

    <div class="form-group position-relative mb-2">
      <div>
        Loan amount
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ formatDollar(form.loanAmount.value) }}
      </div>

      <div v-show="isEditing">
        <input
          v-model="loanAmountFormatted"
          v-currency="{currency: 'USD', locale: 'en'}"
          name="loanAmountFormatted"
          type="text"
          placeholder="Loan amount*"
          class="form-control"
          :class="{'is-invalid': form.loanAmount.error.state.isShown}"
          @input="handleInput"
        >
        <input
          v-model="form.loanAmount.value"
          v-validate="form.loanAmount.validators"
          :name="form.loanAmount.name"
          type="hidden"
          placeholder="Loan amount*"
          class="form-control"
        >
        <InputError
          :state="form.loanAmount.error.state"
          :message="form.loanAmount.error.message"
        />
      </div>
    </div>

    <div class="form-group position-relative mb-2">
      <div>
        Sales order number
      </div>

      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.orderNumber.value }}
      </div>

      <div v-show="isEditing">
        <input
          v-model="form.orderNumber.value"
          v-mask="'###################'"
          :name="form.orderNumber.name"
          type="text"
          placeholder="Order number"
          class="form-control"
          @input="handleInput"
        >
      </div>
    </div>

    <div
      v-show="isEditing"
    >
      <button
        class="next btn btn-primary btn-block"
        type="button"
        @click="validate()"
      >
        <div class="btn-text">
          Update
          <font-awesome-icon
            icon="caret-right"
            class="ml-2"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';
import { formatDollar } from '@/utils/utils';

const LoanInformationReview = {
  components: {
    InlineValidationError,
    InputError,
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        loanAmount: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'loanAmount',
          validators: {
            requiredExtended: {
              message: 'Enter loan amount'
            },
            positive: {
              message: 'Loan amount must be positive',
            },
          },
        },
        orderNumber: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'orderNumber',
        },
      },
      formatDollar,
      loanAmountFormatted: null,
      hasValidated: false,
      isEditing: false,
    };
  },
  computed: {},
  mounted() {
    this.setFormValues(this.formData);
  },
  methods: {
    handleInputError(input) {
      this.form[input].error = {
        ...this.form[input].error,
        state: {
          ...this.form[input].error.state,
          isShown: this.errors.has(this.form[input].name),
        },
        message: this.errors.first(input),
      };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {
        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.isEditing = false;
          this.$emit('collect-data', { form: this.form, isValid: true });
        }
      });

      this.hasValidated = true;
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    },
    setFormValues(data) {
      try {
        this.form.loanAmount.value = data.loanAmount.value;
        this.form.orderNumber.value = data.orderNumber.value;

        this.loanAmountFormatted = this.form.loanAmount.value;
      } catch (error) {
        console.error('Invalid form data passed');
      }
    },
    handleEdit() {
      this.isEditing = !this.isEditing;

      if (!this.isEditing) {
        this.setFormValues(this.formData);
      }
    },
  },
  watch: {
    loanAmountFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.]/g,'');

      this.form.loanAmount.value = amount;
    },
    formData(data) {
      this.setFormValues(data)
    }
  },
};

export default LoanInformationReview;
</script>