<template>
  <div class="container">
    <div class="spacer space-6" />

    <div class="col-lg-8 offset-lg-2">
      <div class="d-flex flex-column align-items-center">
        <div class="text-uppercase color-gray font-montserrat font-weight-600 mb-3 font-size-sm-2">
          Technology provided by:
        </div>
        <div class="logo mb-3">
          <img
            class="mr-1"
            src="@/assets/images/kasasa-logo.png"
            alt="Kasasa Logo"
          >
        </div>
        <div class="color-dark-orange font-montserrat d-flex font-weight-600 letter-spacing-4 font-size-2 mb-3">
          KASASA
        </div>
        <p class="text-center font-size-sm-3 font-barlow-light">
          Kasasa is a third-party service provider powering this application platform. Kasasa is not a financial institution and is not involved in any credit decisions, loan funding, or loan servicing activities. All banking services, including loan origination, provided by TransPecos Banks or participating licensed community financial institutions and lending entities.
        </p>
      </div>
    </div>

    <div class="spacer space-5" />
  </div>
</template>

<script>
const PageContainerFooter = {};

export default PageContainerFooter;
</script>
<style lang="scss" scoped>
.logo {
  > img {
    width: 40px;
  }
}
</style>