<template>
  <div class="confirmation-page">
    <channel-partner-header
      v-if="headerType === 'partner'"
      :partner-name="partnerName"
      :no-border="true"
    />
    <nav-bar v-else />
    <page-header
      v-if="!noData"
      layout="confirmation"
      :applicant-data="applicant"
    />
    <page-header
      v-else
      layout="no-data"
    />
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-3">
          <side-bar />
        </div>
        <div class="col-lg-7 confirmation-message">
          <div
            v-if="applicant.status === 'APPROVED'"
          >
            <h3>Application ID: {{ applicant.loanNumber }}</h3>
            <p>You're on your way to getting the money you need — and a smarter way to pay it off.</p>

            <h3>Next steps: Hang tight</h3>
            <p>We'll contact you soon with further instructions. For now, just enjoy the peace of mind that comes with having a faster and safer way to pay off debt...and save!</p>
          </div>
          <div
            v-else-if="applicant.status === 'REFERRED'"
          >
            <h3>Application ID: {{ applicant.loanNumber }}</h3>
            <p>You're even closer to getting the money you need — and a smarter way to pay it off. Your loan officer just needs a few more things from you to finalize your approval.</p>
            <p>We'll contact you with further instructions and hold your approval in a pending state for now. Keep your application ID handy — and thank you for choosing the Kasasa Loan!</p>
          </div>
          <div
            v-else-if="applicant.status === 'DECLINED'"
          >
            <h3>But fear not, we're always adding new options!</h3>
            <h3>So you can apply again down the road.</h3>
          </div>
          <div
            v-else-if="applicant.status === 'SUBMITTED'"
          >
            <p>No biggie — check your email. We sent an update with details on your application.</p>
            <h3>Application ID: {{ applicant.loanNumber }}</h3>
          </div>
        </div>
      </div>
    </div>
    <error-modal ref="errorModal" />
    <page-footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageHeader from '@/components/PageHeader.vue';
import ChannelPartnerHeader from '@/components/ChannelPartnerHeader.vue';
import SideBar from '@/components/SideBar.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import { GtmService } from '@/services/gtm';
import { capitalizeWord } from '@/utils/utils';

export default {
  components: {
    NavBar,
    PageFooter,
    PageHeader,
    ChannelPartnerHeader,
    SideBar,
    ErrorModal,
	},
  props: {
    headerType: {
      type: String,
      default: 'd2c'
    },
    partnerName: {
      type: String,
      default: 'd2c'
    },
    applicant: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      noData: false,
    }
  },
  mounted() {
    if ( !Object.keys(this.applicant).length ) {
      this.noData = true;
    }

	if (this.$store.state.channelPartnerForm) {
		this.handleChannelPartnerApplication();
		return;
	}

	this.handleD2CApplication();
  },
  methods: {
	handleChannelPartnerApplication() {
			const withCoBorrower = this.$store.state.channelPartnerForm.withCoborrower === 'yes' ? true : false;
			const purchaseApplications = withCoBorrower ? 2 : 1;
			const productType = 'Personal Loan';

			GtmService.addPageLoadEvent({
				pageType: 'partnerApplication',
				consumerType: 'Channel Partner',
				fiId: String(this.$store.state.channelPartner.fiId),
				fiName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
				pageName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}: Personal Loan Confirmation`,
				productType,
				loanPurpose: 'Home Improvement',
				rate: String(this.$store.state.selectedChannelPartnerOffer.rate),
				amount: String(this.$store.state.channelPartnerForm.formData.loanAmount),
				creditScore: this.$store.state.creditScore,
				websiteType: 'Partner Loans',
			});

			const products = [{
				name: productType,
				id: this.$store.state.loanPurpose,
				variant: String(this.$store.state.selectedChannelPartnerOffer.term),
				rate:  String(this.$store.state.selectedChannelPartnerOffer.rate),
				brand: 'Partner',
				category: `Personal/Kasasa Loans/${productType}`,
				quantity: 1,
				price: this.$store.state.loanDetails.amountApproved,
			}];

			GtmService.addEcommercePurchaseEvent({
				currencyCode: 'USD',
				purchase: {
					applicants: purchaseApplications,
					appStatus: this.applicant.status,
					errorResponse: undefined,
					actionField: {
						id: this.applicant.applicationId,
						coupon: String(this.$store.state.channelPartner.storeNumber),
						affiliation: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
					},
				},
				products,
			});
	},
	handleD2CApplication() {
		const withCoBorrower = this.$store.state.channelPartnerForm.withCoborrower === 'yes' ? true : false;
		const purchaseApplications = withCoBorrower ? 2 : 1;
		const productType = ( this.$store.state.loanType === 'VEHICLE' ) ? 'Vehicle Loan' : 'Personal Loan';

		const products = [{
			name: productType,
			id: this.$store.state.loanPurpose,
			variant:  this.$store.state.loanDetails.term,
			rate:  this.$store.state.loanDetails.rate,
			brand: 'D2C',
			category: `Personal/Kasasa Loans/${productType}`,
			quantity: 1,
			price: this.$store.state.loanDetails.amountApproved,
		}];

		GtmService.addEcommercePurchaseEvent({
			currencyCode: 'USD',
			purchase: {
				applicants: purchaseApplications,
				appStatus: this.applicant.status,
				errorResponse: undefined,
				actionField: {
					id: this.applicant.applicationId,
					affiliation: this.$store.state.fiId,
				},
			},
			products,
		});
	}
  }
}
</script>