<template>
  <div class="results-page">
    <nav-bar />
    <div
      v-if="loading"
      class="loader-container"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="icon"
        v-html="getLoadingSvg()"
      />
      <!--eslint-enable-->
    </div>
    <div v-if="isDataAvailable">
      <page-header
        :layout="'results'"
        :applicant-data="{ storageData, payload }"
      />
      <div class="container results-intro">
        <fi-details
          :fi-name="'Collins'"
          :page="'results'"
          :class="'results'"
        />
        <div class="loan-video">
          <a
            href="#"
            onClick="stealthLoansDataLayer('Destop only - Watch the Kasasa Loan in Action', 'Loan Element')"
            @click.prevent="$refs.watchLoan.openModal()"
          >
            Watch the loan in action <span class="play-icon"><font-awesome-icon icon="play" />
            </span>
          </a>
        </div>
        <modal
          ref="watchLoan"
        >
          <template v-slot:body>
            <div class="watch-loan-video video-container">
              <iframe
                src="https://player.vimeo.com/video/559141688?autoplay=1&amp;loop=1&amp;background=true"
                width="640"
                height="564"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen="allowfullscreen"
              />
            </div>
          </template>
        </modal>
      </div>
      <loan-carousel
        :loan-config="loanConfig"
        :rates="rates"
      />
      <loan-graph />
    </div>
    <page-footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import PageFooter from '@/components/PageFooter.vue'
import { findApplication } from '@/api/appservice.api';
import { loadingIcon } from '@/svgs';
import PageHeader from '@/components/PageHeader.vue';
import LoanGraph from '@/components/LoanGraph.vue';
import LoanCarousel from '@/components/LoanCarousel.vue';
import FiDetails from '@/components/FiDetails.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    NavBar,
    PageFooter,
    PageHeader,
    LoanGraph,
    LoanCarousel,
    FiDetails,
    Modal,
	},
  props: {
    resultsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loanConfig: null,
      rates: null,
      storageData: null,
      tempData: null,
      applicationId: null,
      isPrequalified: false,
      isDataAvailable: false,
      payload: null,
      loading: true
    }
  },
  created() {
    console.log(this.resultsData);
    const data = '{"data":{"applicationExternalId":"77d69609-341d-4fd9-95f0-29b6e06581f3","consumerExternalId":"694d39ed-7953-4b93-8e6e-3967b175b778","fi":"1660","isPrequalified":true,"denialReason":null,"amountApproved":12322,"applicationLender":"rateGenius","rates":[{"rate":"3.26","term":48,"installmentAmount":274,"redirectUrl":null},{"rate":"3.26","term":60,"installmentAmount":222,"redirectUrl":null},{"rate":"3.46","term":66,"installmentAmount":205,"redirectUrl":null},{"rate":"3.46","term":72,"installmentAmount":189,"redirectUrl":null},{"rate":"3.76","term":76,"installmentAmount":182,"redirectUrl":null},{"rate":"4.06","term":84,"installmentAmount":168,"redirectUrl":null}],"prequalified":true},"paging":null}';

    // {
    //   "data": {
    //     "applicationExternalId": "04c95202-3ce4-4974-b642-a919c192da88",
    //     "status": "PREQUALIFIED",
    //     "loanId": "154fbdebbaf1432383a16cb8afcd6445",
    //     "loanNumber": "1711",
    //     "offerType": null,
    //     "fiId": null,
    //     "approvedAmount": null,
    //     "maxApprovedAmount": null,
    //     "estimatedMonthlyPayment": null,
    //     "term": null,
    //     "rate": null,
    //     "expirationDate": null,
    //     "offers": [{
    //       "responseId": "c9dee476-a4d6-4b2e-bd00-8b9f907974b0",
    //       "applicationLender": "meridianLink",
    //       "creditOfferType": "PREQUALIFIED",
    //       "fiId": 99999,
    //       "isPrequalified": null,
    //       "denialReason": null,
    //       "approvedAmount": "1000.00",
    //       "maxApprovedAmount": "30000.00",
    //       "estimatedMonthlyPayment": "21.42",
    //       "term": 60,
    //       "rate": "9.65",
    //       "applicationOfferDate": "2022-05-24",
    //       "applicationOfferExpirationDate": "2022-06-23"
    //     }, {
    //       "responseId": "c9dee476-a4d6-4b2e-bd00-8b9f907974b0",
    //       "applicationLender": "meridianLink",
    //       "creditOfferType": "PREQUALIFIED",
    //       "fiId": 99999,
    //       "isPrequalified": null,
    //       "denialReason": null,
    //       "approvedAmount": "1000.00",
    //       "maxApprovedAmount": "0.00",
    //       "estimatedMonthlyPayment": "87.41",
    //       "term": 12,
    //       "rate": "8.9",
    //       "applicationOfferDate": "2022-05-24",
    //       "applicationOfferExpirationDate": "2022-06-23"
    //     }, {
    //       "responseId": "c9dee476-a4d6-4b2e-bd00-8b9f907974b0",
    //       "applicationLender": "meridianLink",
    //       "creditOfferType": "PREQUALIFIED",
    //       "fiId": 99999,
    //       "isPrequalified": null,
    //       "denialReason": null,
    //       "approvedAmount": "1000.00",
    //       "maxApprovedAmount": "0.00",
    //       "estimatedMonthlyPayment": "45.64",
    //       "term": 24,
    //       "rate": "8.9",
    //       "applicationOfferDate": "2022-05-24",
    //       "applicationOfferExpirationDate": "2022-06-23"
    //     }, {
    //       "responseId": "c9dee476-a4d6-4b2e-bd00-8b9f907974b0",
    //       "applicationLender": "meridianLink",
    //       "creditOfferType": "PREQUALIFIED",
    //       "fiId": 99999,
    //       "isPrequalified": null,
    //       "denialReason": null,
    //       "approvedAmount": "1000.00",
    //       "maxApprovedAmount": "0.00",
    //       "estimatedMonthlyPayment": "31.75",
    //       "term": 36,
    //       "rate": "8.9",
    //       "applicationOfferDate": "2022-05-24",
    //       "applicationOfferExpirationDate": "2022-06-23"
    //     }]
    //   },
    //   "paging": null
    // }

    this.tempData = JSON.parse(data).data;

    this.init();
  },
  methods: {
    async init() {
      const self = this;

      this.applicationId = this.tempData.applicationExternalId;

      if ( !!this.applicationId ) {
        this.getDataFromApplicationService().then(function (response) {
          self.storageData = self.tempData;
          self.payload = response.data.data;
          // self.setupDataForEditForm();
          self.handleDataSuccess();
        })
        .catch(function (error) {
          self.handleDataFailure(error);
        });
      }
    },
    handleDataSuccess() {
      this.isPrequalified = this.storageData.isPrequalified;

      if (this.isPrequalified) {
        this.rates = this.storageData.rates.slice().reverse();
        this.loanConfig = {
          fi: this.storageData.fi,
          is_prequalified: this.storageData.isPrequalified,
          amount_approved: this.storageData.amountApproved,
        };

        this.qualAmount = this.loanConfig.amount_approved;

        this.isDataAvailable = true;

        // Trigger GA Data Layer update
        // this.triggerDataLayerUpdate();
        // this.qualifiedDataLayer();
      } else {
        this.loading = false;
        //this.$refs.notPrequalifed.openModal();
        this.nonQualifiedDataLayer();
      }
      this.loading = false;
    },
    handleDataFailure(error) {
      console.error(error);
      this.$refs.notPrequalifed.openModal();
      this.isDataAvailable = false;
    },
    async getDataFromApplicationService() {
      const payload = {
        "applicationExternalId": this.applicationId
      };
      return await findApplication(payload);
    },
    getLoadingSvg() {
      return loadingIcon;
    }
  }
}
</script>

<style lang="scss" src="@/styles/loan-results.scss"></style>