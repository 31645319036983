<template>
  <div class="application-page channel-partner">
    <channel-partner-header :partner-name="partnerName" />
    <channel-partner-form :partner-properties="{ partnerName, partnerId, hasCoborrower }" />
    <error-modal ref="errorModal" />
    <page-footer />
  </div>
</template>

<script>
import ChannelPartnerHeader from '@/components/ChannelPartnerHeader.vue';
import ChannelPartnerForm from '@/components/ChannelPartnerForm.vue';
import PageFooter from '@/components/PageFooter.vue';
import ErrorModal from '@/components/ErrorModal.vue';

export default {
  components: {
    ChannelPartnerHeader,
    ChannelPartnerForm,
    PageFooter,
    ErrorModal
	},
  props: {
    partnerName: {
      type: String,
      default: ''
    },
    partnerId: {
      type: String,
      default: ''
    },
    hasCoborrower: {
      type: Boolean,
      default: false
    },
  },
	data() {
		return {
      loading: true,
      showError: false
    }
	},
  mounted() {
    if ( this.showError ) {
      this.showErrorModal();
    }

    // to listen to event
    const self = this;

    this.$root.$on('triggerTechnicalErrorModal', function () {
      self.showErrorModal();
    });
  },
  methods: {
    showErrorModal() {
      this.$refs.errorModal.returnButton = true;
      this.$refs.errorModal.showModal('technical');
    },
  }
}
</script>