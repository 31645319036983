<template>
  <div class="row">
    <div class="col-md-12 review-section">
      <channel-partner-review-heading :step="$parent.steps.step2" />
      <ul class="personal-info">
        <li><span>Full name</span> {{ formData.firstName }} {{ formData.lastName }}</li>
        <li><span>Email</span> {{ formData.email }}</li>
        <li><span>Cell phone number</span> {{ formData.phone }}</li>
        <li><span>DOB</span> {{ formData.dob }}</li>
        <li><span>SSN</span> {{ formData.ssn | maskssn }}</li>
      </ul>
      <channel-partner-review-heading :step="$parent.steps.step3" />
      <ul>
        <li><span>Current address</span> {{ formData.addressData.address }} {{ formData.addressData.address2 }}, {{ formData.addressData.city }}, {{ formData.addressData.state == null ? '' : formData.addressData.state.value }} {{ formData.addressData.zip }}</li>
        <li><span>Residence type</span> {{ formData.residenceType }}</li>
        <li><span>Time of residence</span> {{ formData.residenceYears }} yrs {{ formData.residenceMonths }} mos</li>
        <li><span>Monthly mortgage/rent payment</span> {{ $parent.monthlyRentFormatted }}</li>
        <div v-if="$parent.residenceLessThanTwo">
          <li><span>Previous address</span> {{ formData.previousAddress }} {{ formData.previousAddress2 }}<br>{{ formData.previousCity }}, {{ formData.previousState == null ? '' : formData.previousState.value }} {{ formData.previousZip }}</li>
          <li><span>Previous time of residence</span> {{ formData.previousResidenceYears }} yrs {{ formData.previousResidenceMonths }} mos</li>
        </div>
      </ul>
      <channel-partner-review-heading :step="$parent.steps.step4" />
      <ul>
        <li><span>Employment type</span> {{ formData.employmentType }}</li>
        <li><span>Job title</span> {{ formData.jobTitle }}</li>
        <li><span>Employer</span> {{ formData.employerName }}</li>
        <li><span>Employer's phone number</span> {{ formData.employerPhone }}</li>
        <li><span>Time of employment</span> {{ formData.employmentYears }} yrs {{ formData.employmentMonths }} mos</li>
        <li><span>Gross monthly income (before taxes)</span> {{ $parent.grossIncomeFormatted }}</li>
        <div v-if="$parent.employmentLessThanTwo">
          <li><span>Previous employment type</span> {{ formData.previousEmploymentType }}</li>
          <li><span>Previous job title</span> {{ formData.previousJobTitle }}</li>
          <li><span>Previous employer</span> {{ formData.previousEmployerName }}</li>
          <li><span>Previous employer's phone number</span> {{ formData.previousEmployerPhone }}</li>
          <li><span>Previous time of employment</span> {{ formData.previousEmploymentYears }} yrs {{ formData.previousEmploymentMonths }} mos</li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import ChannelPartnerReviewHeading from '@/components/ChannelPartnerReviewHeading.vue'

export default {
  components: { ChannelPartnerReviewHeading },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>