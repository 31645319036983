export const steps = {
  step1: {
    id: 1,
    title: 'Loan information',
  },
  step2: {
    id: 2,
    title: 'Applicant information',
  },
  step3: {
    id: 3,
    title: 'Residence information',
  },
  step4: {
    id: 4,
    title: 'Employment information',
  },
  step5: {
    id: 5,
    title: 'Co-borrower information',
  },
  step6: {
    id: 6,
    title: 'Co-borrower residence information',
  },
  step7: {
    id: 7,
    title: 'Co-borrower employment information',
  },
}