<template>
  <div
    class="channel-partner-header"
    :class="{ 'no-border' : noBorder }"
  >
    <div class="nav__logo" />
    <img
      :src="logoSrc"
      class="channel-partner-logo"
      :alt="`${partnerName} logo`"
    >
  </div>
</template>

<script>
export default {
  props: {
    partnerName: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    logoSrc() {
      return `https://www.kasasa.com/hubfs/channel-partners-logos/${this.partnerName}.png`
    }
  }
}
</script>