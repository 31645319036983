<template>
  <div class="progress-bar-container">
    <p>Take-Back Balance when you pay ahead: <span>${{ balance }}</span></p>
    <div class="progress-bar">
      <span>$</span>
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="segment"
        :class="{ active: isActiveStep(step) }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 0
    },
    balance: {
      type: Number,
      default: 0,
    },
    maxbalance: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    getActiveSteps() {
      const percentOfActiveSteps = parseFloat(this.balance / this.maxbalance);
      let activeSteps = parseInt(percentOfActiveSteps * this.steps);

      if (activeSteps === 0) {
        activeSteps = 1;
      }
      return activeSteps;
    },
    isActiveStep(step) {
      return (step === this.getActiveSteps() || step < this.getActiveSteps()) ? true : false;
    }
  }
}
</script>