<template>
  <div class="card shadow">
    <div class="card-body d-flex flex-column align-items-center p-3">
      <div class="d-flex">
        <DollarDisplay
          :value="formatDollar(offer.estimatedMonthlyPayment, '')"
        />
      </div>
      <div class="text-uppercase text-center mb-3">
        Monthly Payment
      </div>
      <div class="mt-4">
        {{ offer.term }} Months
      </div>
      <div>
        {{ offer.rate }}% APR
      </div>
      <div class="text-center">
        {{ formatDollar(offer.totalOfPayments) }} Total Payments
      </div>

      <button
        v-if="!noButton"
        class="next btn btn-primary btn-block offer-select mt-3"
        type="button"
        @click.prevent="handleSelect"
      >
        <div class="btn-text">
          SELECT THIS OPTION
          <font-awesome-icon
            icon="caret-right"
            class="ml-2"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { formatDollar } from '@/utils/utils';
import DollarDisplay from '@/components/DollarDisplay.vue';

const PartnerOffer = {
  components: {
    DollarDisplay,
  },
  props: {
    offer: {
      type: Object,
      default() {
        return {};
      }
    },
    noButton: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      formatDollar,
    };
  },
  methods: {
    handleSelect() {
      this.$emit('select', this.offer);
    },
  },
};

export default PartnerOffer;
</script>
<style scoped lang="scss">
.card {
  background-color: transparent;
  border-color: #979797;
  border-radius: 10px;
  display: inline-flex;
  .offer-select {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .amount-dollar {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.25rem;
  }
}
</style>
