<template>
  <div class="card-carousel-wrapper">
    <div class="row">
      <div class="container">
        <div
          ref="slider"
          class="splide loan-option-slider"
        >
          <div class="splide__track">
            <ul class="splide__list">
              <li
                v-for="(rateItem, index) in rates"
                :key="index"
                :data-index="index"
                class="splide__slide"
              >
                <div class="loan-option">
                  <div class="loan-option-info">
                    <p>{{ rateItem.installmentAmount | toCurrency }}/mo</p>
                    <p>{{ rateItem.term }} months</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Splide from '@splidejs/splide';

export default {
  props: {
    rates: {
      type: Array,
      default: function() {
        return []
      }
    },
    loanConfig: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      slideSelected: 0,
      rate: [],
      slider: '',
      options: {
        rewind: true,
        autoWidth: true,
        focus: "center",
        trimSpace: true,
        perPage: (this.rates.length < 5) ? this.rates.length - 1 : 5,
        perMove: 1,
        gap: 20,
        pagination: false,
        breakpoints: {
          960: {
            perPage: 4,
          },
          768: {
            perPage: 2,
          }
        }
      }
    };
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    initSlider() {
      // Init slider
      this.slider = new Splide(this.$refs.slider, this.options);

      // Handle slide Change
      this.onSlideChange();

      // Mount slider
      this.slider.mount();

      // Handle slide click
      this.onSlideClick();

      // Trigger the first slide
      this.triggerIniailSlide();
    },
    triggerIniailSlide() {
      // Trigger loan active event
      this.$root.$emit('loanActive', this.findRateBySelectedSlide(), this.slideSelected, this.loanConfig);

      // Trigger window resize to refresh the width of the slides
      window.dispatchEvent(new Event('resize'));
    },
    onSlideChange() {
      const self = this;

      // On slide change, trigger setActive
      self.slider.on("moved", function (newIndex, oldIndex, destIndex) {
        // Set index as active rate
        self.setActive(destIndex);
      });
    },
    onSlideClick() {
      const self = this;

      // Get all slides
      const slides = document.querySelectorAll(".splide__slide");

      // Iterate through all slides to find index
      for (const slide of slides) {
        // Add click event
        slide.addEventListener("click", function () {
          const targetIndex = parseInt(this.dataset.index);

          // Go to targeted slide
          self.slider.go(targetIndex);
        });
      }
    },
    setActive(index) {
      // Get index
      this.slideSelected = index;

      // Emit loanActive event based on selected slide
      this.$root.$emit('loanActive', this.findRateBySelectedSlide(), this.slideSelected, this.loanConfig);
    },
    findRateBySelectedSlide() {
      // Find slide in rates based on slideSelected
      return this.rates[this.slideSelected];
    }
  }
}
</script>