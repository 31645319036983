<template>
  <div class="row">
    <div class="col-6">
      <div class="font-barlow-light">
        Apply with a joint applicant?
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <div class="form-check form-check-inline mr-4">
          <input
            id="jointApplicantYes"
            v-model="form.jointApplicant.value"
            class="form-check-input"
            type="radio"
            name="jointApplicant"
            value="YES"
            @change="handleInputChange"
          >
          <label
            class="form-check-label"
            for="jointApplicantYes"
          >
            YES
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="jointApplicantNo"
            v-model="form.jointApplicant.value"
            class="form-check-input"
            type="radio"
            name="jointApplicant"
            value="NO"
            @change="handleInputChange"
          >
          <label
            class="form-check-label"
            for="jointApplicantNo"
          >
            NO
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

const JointApplicantSelect = {
  data() {
    return {
      form: {
        jointApplicant: {
          value: 'NO',
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
        },
      }
    }
  },
  methods: {
    submit() {
      this.loanInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleInputChange(event) {
      this.$emit('change', event.target.value);
    }
  },
};

export default JointApplicantSelect;
</script>