<template>
  <input
    ref="inputElement"
    v-model="address"
    autocomplete="off"
    name="address"
    :placeholder="placeholder"
    class="search-location form-control"
    type="text"
    :disabled="isLoading"
    :class="{'is-invalid': isInvalid }"
  >
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

const GMAPS_KEY = process.env.VUE_APP_GMAPS_KEY;

export default {
  props: {
    placeholder: {
      type: String,
      default() {
        return 'Address';
      },
    },
    isInvalid: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: true,
      autocomplete: null,
      address: null,
      context: null,
    };
  },
  watch: {
    address(address) {
      this.$emit('change', address);
    },
    value(value) {
      this.setAddress(value);
    }
  },
  async mounted() {
    this.setAddress(this.value);

    const loader = new Loader({
      apiKey: GMAPS_KEY,
      version: 'weekly',
      libraries: ['places']
    });

    try {
      const google = await loader.load();

      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.inputElement,
        {
          componentRestrictions: {
            country: ['us']
          },
          fields: ['address_components', 'geometry'],
          types: ['address'],
        }
      );

      this.autocomplete.addListener(
        'place_changed',
        this.handleAutoCompletePlaceChanged,
      );

      this.isLoading = false;

    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    handleAutoCompletePlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (place.name) {
        this.address = place.name;
        return;
      }

      const context = {};

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            context.streetNumber = component.long_name;
            break;
          }

          case 'route': {
            context.streetName = component.short_name;
            break;
          }

          case 'locality':
            context.city = component.long_name;
            break;

          case 'administrative_area_level_1': {
            context.state = component.short_name;
            break;
          }

          case 'postal_code': {
            context.postalCode = component.long_name;
            break;
          }

          case 'administrative_area_level_2': {
            context.county = component.short_name;
            break;
          }
        }
      }

      this.address = `${context.streetNumber || ''} ${context.streetName || ''}`.trim();

      this.$emit('context', context);
    },
    setAddress(value) {
      this.address = value;
    },
  }
}
</script>
