import axios from 'axios';
import { FI_API_URL } from '@/constants/fi-api-url';
import { PORTAL_ID } from '@/constants/portal-id';
import { MAINTENANCE_MODE_DEV_API_URL, MAINTENANCE_MODE_UAT_API_URL, MAINTENANCE_MODE_PROD_API_URL } from '@/constants/maintenance-mode-api-urls';
import { ENVIRONMENTS } from '@/constants/environments';
import store from '@/store';

export const parseEnvironmentFromUrl = () => {
  const url = window.location.href;

  if (url.includes('.dev.') || url.includes('localhost')) {
    return ENVIRONMENTS.DEV;
  }

  if (url.includes('.uat.')) {
    return ENVIRONMENTS.UAT;
  }

  return ENVIRONMENTS.PROD;
};

export const getFiData = ({
	portalId = PORTAL_ID,
	financialid,
}) =>
	axios.get(FI_API_URL, {
		params: {
			portalId,
			financialid,
		}
	})
	.then((response) => response.data.results[0].values);

export const getMaintenanceModeUrl = () => {
  const environment = parseEnvironmentFromUrl();

  if (environment === ENVIRONMENTS.DEV) {
    return MAINTENANCE_MODE_DEV_API_URL;
  }

  if (environment === ENVIRONMENTS.UAT) {
    return MAINTENANCE_MODE_UAT_API_URL;
  }

  return MAINTENANCE_MODE_PROD_API_URL;
};

export const getMaintenanceMode = () =>
  axios.get(getMaintenanceModeUrl())
    .then((response) => response.data.data);

export const maintenanceModeRouteHandler = async (route, partnerName) => {
  for (let count = 0; count <= 1; count++) {
    try {
      const maintenanceMode = await getMaintenanceMode();

      if (maintenanceMode.mode === 'ON') {
        store.commit('setIsMaintenanceModeEnabled', true);
        return;
      }

      if (maintenanceMode.mode === 'OFF') {
        store.commit('setIsMaintenanceModeEnabled', false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  store.commit('setIsMaintenanceModeEnabled', true);
}