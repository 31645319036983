<template>
  <h4>
    {{ step.title }}
    <span
      v-if="!$parent.$parent.isPolling"
      @click="goToStep(step.id)"
    >
      <font-awesome-icon
        :icon="['fas', 'edit']"
      />
    </span>
  </h4>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    goToStep(id) {
      this.$root.$emit('goto-step', id);
    }
  }
}
</script>