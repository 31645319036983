<template>
  <div class="application-page channel-partner fade-in">
    <div
      class="channel-partner-header"
    >
      <div>
        <img
          :src="logoSrc"
          class="channel-partner-logo"
          :alt="`${partnerName} logo`"
        >
      </div>

      <div class="d-flex channel-partner-intro">
        <div>
          <h1>Get started and review your loan offers!</h1>
          <h2>It only takes a few minutes!</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <StepIndicatorBar :step="step" />
        </div>
      </div>
    </div>

    <PartnerForm />

    <PageContainerFooter />

    <PartnerPageFooter />
  </div>
</template>

<script>
import PartnerForm from '@/components/PartnerForm/PartnerForm.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageContainerFooter from '@/components/PageContainerFooter.vue';
import PartnerPageFooter from '@/components/PartnerPageFooter.vue';
import StepIndicatorBar from '@/components/StepIndicatorBar.vue';

const PartnerFormPage = {
  components: {
    PartnerForm,
    PageFooter,
    PageContainerFooter,
    PartnerPageFooter,
    StepIndicatorBar
	},
  computed: {
    partnerName() {
      const { partnerName } = this.$route.params;
      return partnerName;
    },
    logoSrc() {
      return `https://www.kasasa.com/hubfs/channel-partners-logos/${this.partnerName}.png`;
    },
    step() {
      return this.$store.state.partnerApplicationStep;
    }
  }
};

export default PartnerFormPage;
</script>
