<template>
  <input
    v-if="addressType === 'address1'"
    ref="autocomplete"
    v-model="formData.address"
    autocomplete="off"
    name="address1"
    placeholder="Address"
    class="search-location form-control"
    type="text"
    :class="{'form-control': true, 'is-invalid': validationErrors.has(`${formScope}.address`) }"
    :disabled="loading"
    @keyup.enter="selectFirstAddress"
    @animationstart="checkAnimation"
  >
  <input
    v-else
    ref="autocomplete"
    v-model="formData.fullAddress"
    autocomplete="off"
    name="fullAddress"
    placeholder="Address"
    class="search-location form-control"
    type="text"
    :class="{'form-control': true, 'is-invalid': validationErrors.has(`${formScope}.address`) }"
    :disabled="loading"
    @keyup.enter="selectFirstAddress"
    @blur="selectFirstAddress"
    @animationstart="checkAnimation"
  >
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
const GMAPS_KEY = process.env.VUE_APP_GMAPS_KEY;

export default {
  props: {
    addressType: {
      type: String,
      default: 'fullAddress'
    },
    formDataParent: {
      type: Object,
      default: function() {
        return {}
      }
    },
    formScope: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Object,
      default: function() {
        return {}
      }
    },
  },
  data() {
    return {
      formData: {
        fullAddress: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        county: ''
      },
      loading: true,
      autofilled: false
    }
  },
  watch: {
    formData: {
      handler: function () {
        this.$emit('acUpdate', [this.formData.fullAddress, this.formData.address, this.formData.city, this.formData.state, this.formData.zip, this.formData.county]);
      },
      deep: true
    },
    'formData.fullAddress': function (val, oldVal) {
      if (typeof this.formData.fullAddress !== "undefined") {
        if (this.formData.fullAddress !== this.formData.address) {
          const newAddress = val.split(this.formData.city)[0];
          this.formData.address = newAddress.trim();
        }
      }
    }
  },
  mounted() {
    if (this.formDataParent) {
      this.formData = this.formDataParent;
    }

    // To use in promise below
    const self = this;

    const loader = new Loader({
      apiKey: GMAPS_KEY,
      version: "weekly",
      libraries: ["places"]
    });

    loader
      .load()
      .then((google) => {
        self.autocomplete = new google.maps.places.Autocomplete(
          (self.$refs.autocomplete), {
            componentRestrictions: {
              country: ["us"]
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );
        self.autocomplete.addListener("place_changed", self.fillInAddress);
        self.loading = false;
      })
      .catch(e => {
        console.log(e);
        self.$root.$emit('triggerTechnicalErrorModal');
      });
  },
  methods: {
    checkAnimation(e) {
      if(e.animationName == "beginBrowserAutofill")
      {
        this.autofilled = true;
        this.$refs.autocomplete.focus();
        google.maps.event.trigger(this.$refs.autocomplete, 'focus');

        google.maps.event.trigger(this.autocomplete, 'place_changed');
      }
      else if(e.animationName == "endBrowserAutofill")
      {
        this.autofilled = false;
      }
    },
    selectFirstAddress(e) {
      google.maps.event.trigger(this.$refs.autocomplete, 'keydown', {
        keyCode: 40
      });
      google.maps.event.trigger(this.$refs.autocomplete, 'keydown', {
        keyCode: 13
      });
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      let address1 = "";
      let simpleAddress = ""

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            address1 = `${component.long_name} ${address1}`;
            simpleAddress = `${component.long_name} ${simpleAddress}`;
            break;
          }

          case "route": {
            address1 += `${component.short_name}`;
            simpleAddress += `${component.short_name}`;
            break;
          }

          case "locality":
            address1 += ` ${component.long_name}`;
            this.formData.city = component.long_name;
            break;

          case "administrative_area_level_1": {
            address1 += ` ${component.short_name}`;
            this.formData.state = component.short_name;
            break;
          }

          case "postal_code": {
            address1 += ` ${component.long_name}`;
            this.formData.zip = component.long_name;
            break;
          }

          case "administrative_area_level_2": {
            this.formData.county = component.short_name;
            break;
          }
        }
      }
      this.formData.fullAddress = address1;
      this.formData.address = simpleAddress;
    }
  }
}
</script>

<style>
:-webkit-autofill {
  animation-name: onAutoFillStart;
}
:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>