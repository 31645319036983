<template>
  <div class="prequal-page">
    <nav-bar />
    <page-header :layout="layout" />
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-3">
          <side-bar />
        </div>
        <div class="col-lg-7">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </template>
            <prequal-form :loan-type="loanType" />
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
    <page-footer />
    <error-modal ref="errorModal" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import PageFooter from '@/components/PageFooter.vue'
import PageHeader from '@/components/PageHeader.vue';
import SideBar from '@/components/SideBar.vue';
import PrequalForm from '@/components/PrequalForm.vue';
import ErrorModal from '@/components/ErrorModal.vue';

export default {
  components: {
    NavBar,
    PageFooter,
    PageHeader,
    SideBar,
    PrequalForm,
    ErrorModal
  },
  data() {
    return {
      loading: true,
      isAuto: false,
      isPersonal: false,
      loanType: null,
      layout: null
    }
  },
  mounted() {
    // Set loan type via parameter
    this.setLoanType();

    this.layout = `prequal__${this.loanType}`;

    // to listen to event
    const self = this;

    this.$root.$on('triggerTechnicalErrorModal', function () {
      self.showErrorModal();
    });
  },
  methods: {
    setLoanType() {
      const queryParams = this.$route.query;

      if ( !!queryParams.type ) {
        switch (queryParams.type) {
          case 'auto':
            this.loading = false;
            this.loanType = queryParams.type;
            this.isAuto = true;
            break;
          case 'personal':
            this.loading = false;
            this.loanType = queryParams.type;
            this.isPersonal = true
            break;
          default:
            this.showErrorModal();
        }
      } else {
        this.showErrorModal();
      }
    },
    showErrorModal() {
      this.$refs.errorModal.returnButton = true;
      this.$refs.errorModal.showModal('technical');
    },
  }
}
</script>
