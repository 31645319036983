<template>
  <div class="step-indicator">
    <div class="step step-one" />
    <div
      v-if="checkShouldShowStep(steps.stepTwo)"
      class="step step-two" 
    />
    <div
      v-if="checkShouldShowStep(steps.stepThree)"
      class="step step-three"
    />
    <div
      v-if="checkShouldShowStep(steps.stepFour)"
      class="step step-four"
    />
  </div>
</template>

<script>
import { partnerApplicationSteps } from '@/constants/partner-application-steps';

const StepIndicatorBar = {
  props: {
    step: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      steps: partnerApplicationSteps,
    };
  },
  methods: {
    checkShouldShowStep(step) {
      if (this.step === partnerApplicationSteps.stepTwo) {
        if (step === partnerApplicationSteps.stepTwo) {
          return true;
        }

        return false;
      }

      if (this.step === partnerApplicationSteps.stepThree) {
        if (step === partnerApplicationSteps.stepTwo) {
          return true;
        }

        if (step === partnerApplicationSteps.stepThree) {
          return true;
        }

        return false;
      }

      if (this.step === partnerApplicationSteps.stepFour) {
        if (step === partnerApplicationSteps.stepTwo) {
          return true;
        }

        if (step === partnerApplicationSteps.stepThree) {
          return true;
        }

        if (step === partnerApplicationSteps.stepFour) {
          return true;
        }

        return false;
      }
    },
  }
};

export default StepIndicatorBar;
</script>
<style lang="scss" scoped>
.step-indicator {
  background-color: #D8D8D8;
  border: solid 1px #B6B9BA;
  border-radius: 16px;
  height: 16px;
  display: flex;
  align-items: center;

  .step {
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 4px 0 0;
    position: relative;
    &::after {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.5);
      content: "";
      height: 6px;
      width: 6px;
      position: absolute;
      right: 4px;
      z-index: 1;
    }
    &:first-of-type {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    &:last-of-type {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  .step-one {
    background: rgb(244,128,39);
    background: linear-gradient(90deg, rgba(244,128,39,1) 0%, rgba(235,101,37,1) 100%);
    width: 25%;
  }
  .step-two {
    background: rgb(235,101,37);
    background: linear-gradient(90deg, rgba(235,101,37,1) 0%, rgba(199,52,84,1) 100%);
    width: 25%;
  }
  .step-three {
    background: rgb(199,52,84);
    background: linear-gradient(90deg, rgba(199,52,84,1) 0%, rgba(154,69,147,1) 100%);
    width: 25%;
  }
  .step-four {
    background: rgb(199,52,84);
    background: linear-gradient(90deg, rgba(154,69,147,1) 0%, rgba(102,106,175,1) 100%);
    width: 25%;
  }
}
</style>