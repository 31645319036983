<template>
  <div class="container mb-5">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <form @submit.prevent="submit">
          <div
            v-show="currentSection === sections.LOAN_PERSONAL_INFORMATION"
            class="fade-in"
          >
            <div
              class="mt-5"
            >
              <h4>
                Loan information
              </h4>
              <div class="mb-3">
                Enter the amount you're looking for and your sales order number.
              </div>
              <LoanInformation
                :event="loanInformationEvent"
                @collect-data="handleLoanInformationCollectData"
              />
            </div>

            <div class="dropdown-divider" />

            <div class="mt-5">
              <h4>
                Personal information
              </h4>
              <div class="mb-3">
                Tell us a little about yourself.
              </div>
              <PersonalInformation
                :event="personalInformationEvent"
                @collect-data="handlePersonalInformationCollectData"
              />

              <div class="go-next mt-5">
                <button
                  class="next btn btn-primary btn-block"
                  type="button"
                  @click="handlePersonalInformationNext"
                >
                  <div class="btn-text">
                    Continue
                    <font-awesome-icon
                      icon="caret-right"
                      class="ml-2"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="currentSection === sections.RESIDENCE_INFORMATION"
            class="fade-in"
          >
            <div class="mt-5">
              <div class="mb-4">
                <h4>
                  Residence information
                </h4>
                <div
                  v-if="Boolean(address)"
                  class="color-gray"
                >
                  Tell us a little about
                </div>
                <div
                  v-if="Boolean(address)"
                >
                  <button
                    class="btn btn-link color-purple font-barlow-medium p-0"
                    type="button"
                    @click="handleAddressClick"
                  >
                    {{ address }}
                  </button>
                </div>
              </div>
              <ResidenceInformation
                :event="residenceInformationEvent"
                @collect-data="handleResidenceInformationCollectData"
              />

              <div class="go-next mt-5">
                <button
                  class="next btn btn-primary btn-block"
                  type="button"
                  @click="handleResidenceInformationNext"
                >
                  <div class="btn-text">
                    Continue
                    <font-awesome-icon
                      icon="caret-right"
                      class="ml-2"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="currentSection === sections.EMPLOYMENT_INFORMATION"
            class="fade-in"
          >
            <div class="mt-5">
              <h4>
                Employment information
              </h4>
              <div class="color-gray mb-3">
                Next, please tell us a little about your job.
              </div>
              <EmploymentInformation
                :event="employmentInformationEvent"
                @collect-data="handleEmploymentInformationCollectData"
              />
            </div>

            <!-- <div class="mt-5">
              <JointApplicantSelect 
                @change="handleJointApplicantSelectChange"
              />
            </div> -->

            <div class="go-next mt-5">
              <button
                class="next btn btn-primary btn-block"
                type="button"
                @click.prevent="handleEmploymentInformationNext"
              >
                <div class="btn-text">
                  Continue
                  <font-awesome-icon
                    icon="caret-right"
                    class="ml-2"
                  />
                </div>
              </button>
            </div>
          </div>

          <div
            v-show="currentSection === sections.JOINT_APPLICANT_PERSONAL_INFORMATION"
          >
            <div class="mt-5">
              <h4>
                Joint applicant information
              </h4>
              <div class="mb-4">
                Tell us a little about them so that we can verify their identity too.
              </div>
              <PersonalInformation
                :form-data="formData.PERSONAL_INFORMATION"
                :event="jointApplicantPersonalInformationEvent"
                @collect-data="handleJointApplicantPersonalInformationCollectData"
              />

              <div class="go-next mt-5">
                <button
                  class="next btn btn-primary btn-block"
                  type="button"
                  @click="handleJointApplicantPersonalInformationNext"
                >
                  <div class="btn-text">
                    Continue
                    <font-awesome-icon
                      icon="caret-right"
                      class="ml-2"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="currentSection === sections.JOINT_APPLICANT_RESIDENCE_INFORMATION"
          >
            <div class="mt-5">
              <h4>
                Joint applicant residence information
              </h4>
              <div class="mb-4">
                Tell us a little where they live so we can verify their identity.
              </div>

              <ResidenceInformation
                :event="jointApplicantResidenceInformationEvent"
                @collect-data="handleJointApplicantResidenceInformationCollectData"
              />

              <div class="go-next mt-5">
                <button
                  class="next btn btn-primary btn-block"
                  type="button"
                  @click="handleJointApplicantResidenceInformationNext"
                >
                  <div class="btn-text">
                    Continue
                    <font-awesome-icon
                      icon="caret-right"
                      class="ml-2"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="currentSection === sections.JOINT_APPLICANT_EMPLOYMENT_INFORMATION"
          >
            <div class="mt-5">
              <h4>
                Joint applicant employment information
              </h4>
              <div class="mb-4">
                Next, we need to verify their income. Please tell us a little about their job.
              </div>

              <EmploymentInformation
                :event="jointApplicantEmploymentInformationEvent"
                @collect-data="handleJointApplicantEmploymentInformationCollectData"
              />

              <div class="go-next mt-5">
                <button
                  class="next btn btn-primary btn-block"
                  type="button"
                  @click="handleJointApplicantEmploymentInformationNext"
                >
                  <div class="btn-text">
                    Continue
                    <font-awesome-icon
                      icon="caret-right"
                      class="ml-2"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- ./row -->
  </div>
</template>

<script>
import LoanInformation from '@/components/PartnerForm/LoanInformation.vue';
import PersonalInformation from '@/components/PartnerForm/PersonalInformation.vue';
import EmploymentInformation from '@/components/PartnerForm/EmploymentInformation.vue';
import ResidenceInformation from '@/components/PartnerForm/ResidenceInformation.vue';
import JointApplicantSelect from '@/components/PartnerForm/JointApplicantSelect.vue';
import { PARTNER_FORM_SECTIONS } from '@/constants/partner-form-sections';
import SvgIcon from '@/components/SvgIcon.vue';
import GeneralErrorModal from '@/components/GeneralErrorModal.vue';
import { partnerApplicationSteps } from '@/constants/partner-application-steps';

const sections = {
  LOAN_PERSONAL_INFORMATION: 'LOAN_PERSONAL_INFORMATION',
  RESIDENCE_INFORMATION: 'RESIDENCE_INFORMATION',
  EMPLOYMENT_INFORMATION: 'EMPLOYMENT_INFORMATION',
  JOINT_APPLICANT_PERSONAL_INFORMATION: 'JOINT_APPLICANT_PERSONAL_INFORMATION',
  JOINT_APPLICANT_RESIDENCE_INFORMATION: 'JOINT_APPLICANT_RESIDENCE_INFORMATION',
  JOINT_APPLICANT_EMPLOYMENT_INFORMATION: 'JOINT_APPLICANT_EMPLOYMENT_INFORMATION',
};

const PartnerForm = {
  components: {
    LoanInformation,
    PersonalInformation,
    EmploymentInformation,
    ResidenceInformation,
    JointApplicantSelect,
    SvgIcon,
    GeneralErrorModal,
  },
  data() {
    return {
      loanInformationEvent: {},
      loanInformationData: {},
      personalInformationEvent: {},
      personalInformationData: {},
      residenceInformationEvent: {},
      residenceInformationData: {},
      employmentInformationEvent: {},
      employmentInformationData: {},
      jointApplicantPersonalInformationEvent: {},
      jointApplicantPersonalInformationData: {},
      jointApplicantResidenceInformationEvent: {},
      jointApplicantResidenceInformationData: {},
      jointApplicantEmploymentInformationEvent: {},
      jointApplicantEmploymentInformationData: {},
      currentSection: sections.LOAN_PERSONAL_INFORMATION,
      withJointApplicant: false,
      sections,
      isPolling: false,
      applicationId: null,
      isErrorModalShown: false,
      pollCount: 0,
      pollCountMax: 10,
      formData: {},
    };
  },
  computed: {
    address() {
      if (this.personalInformationData.form) {
        const address1 = this.personalInformationData.form.address1.value || ' ';
        const address2 = this.personalInformationData.form.address2.value || '';
        const city = this.personalInformationData.form.city.value || '';
        const state = this.personalInformationData.form.state.value || '';
        const zip = this.personalInformationData.form.zip.value || '';

        return `${address1}${address2}, ${city} ${state}, ${zip}`;
      }

      return null;
    },
  },
  mounted() {
    this.$store.commit('setPartnerApplicationStep', partnerApplicationSteps.stepOne);
  },
  methods: {
    scrolltop() {
      window.scrollTo(0, 0);
    },
    handleLoanInformationCollectData(data = {}) {
      this.loanInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.LOAN_INFORMATION]: data.form,
      });
    },
    handlePersonalInformationCollectData(data) {
      this.personalInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.PERSONAL_INFORMATION]: data.form,
      });
      
      if (
        data.isValid
        && this.loanInformationData
        && this.loanInformationData.isValid
      ) {
        this.currentSection = sections.RESIDENCE_INFORMATION;
        this.scrolltop();
        this.$store.commit('setPartnerApplicationStep', partnerApplicationSteps.stepTwo);
      }
    },
    handleResidenceInformationCollectData(data) {
      this.residenceInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.RESIDENCE_INFORMATION]: data.form,
      });

      if (data.isValid) {
        this.currentSection = sections.EMPLOYMENT_INFORMATION;
        this.scrolltop();
        this.$store.commit('setPartnerApplicationStep', partnerApplicationSteps.stepThree);
      }
    },
    handleEmploymentInformationCollectData(data) {
      this.employmentInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.EMPLOYMENT_INFORMATION]: data.form,
      });

      if (data.isValid) {
        if (this.withJointApplicant) {
          this.currentSection = sections.JOINT_APPLICANT_PERSONAL_INFORMATION;
          return;
        }

        this.routeToReviewPage();
      }
    },
    handleJointApplicantPersonalInformationCollectData(data) {
      this.jointApplicantPersonalInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.JOINT_APPLICANT_PERSONAL_INFORMATION]: data.form,
      });

      if (data.isValid) {
        this.currentSection = sections.JOINT_APPLICANT_RESIDENCE_INFORMATION;
        this.scrolltop();
      }
    },
    handleJointApplicantResidenceInformationCollectData(data) {
      this.jointApplicantResidenceInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.JOINT_APPLICANT_RESIDENCE_INFORMATION]: data.form,
      });

      if (data.isValid) {
        this.currentSection = sections.JOINT_APPLICANT_EMPLOYMENT_INFORMATION;
        this.scrolltop();
      }
    },
    handleJointApplicantEmploymentInformationCollectData(data) {
      this.jointApplicantEmploymentInformationData = data;

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.JOINT_APPLICANT_EMPLOYMENT_INFORMATION]: data.form,
      });
    },
    handleJointApplicantSelectChange(input) {
      this.withJointApplicant = input === 'YES';

      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.WITH_JOINT_APPLICANT]: this.withJointApplicant,
      });
    },
    handlePersonalInformationNext() {
      this.loanInformationEvent = {
        type: 'VALIDATE',
      };

      this.personalInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleResidenceInformationNext() {
      this.residenceInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleEmploymentInformationNext() {
      this.employmentInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleJointApplicantPersonalInformationNext() {
      this.jointApplicantPersonalInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleJointApplicantResidenceInformationNext() {
      this.jointApplicantResidenceInformationEvent = {
        type: 'VALIDATE',
      };
    },
    handleJointApplicantEmploymentInformationNext() {
      this.jointApplicantEmploymentInformationEvent = {
        type: 'VALIDATE',
      };
    },
    routeToReviewPage() {
        const { partnerName } = this.$route.params;
        this.$router.push({ name: 'PartnerReviewPage', params: { partnerName } });
    },
    handleAddressClick() {
        this.currentSection = sections.LOAN_PERSONAL_INFORMATION;
        this.scrolltop();
    }
  },
  watch: {
    '$store.state.partnerForm'(value = {}) {
      this.formData = value;
    },
  },
};

export default PartnerForm;
</script>
<style scoped>
.dropdown-divider {
  border-color: #C9C9C9;
}
</style>