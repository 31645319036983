<template>
  <div class="container">
    <channel-partner-heading :section="currentSection" />
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <div
          v-if="currentSection === 'application'"
          class="form-container"
        >
          <channel-partner-section-title
            :step="getStep(1)"
          />
          <div
            class="channel-partner-section-content"
          >
            <p>
              Enter the information from your order form.
            </p>
            <div
              class="form"
            >
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.orderNumber"
                    type="text"
                    placeholder="Sales order number"
                    :class="{'form-control': true }"
                  >
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="loanAmountFormatted"
                    v-currency="{currency: 'USD', locale: 'en'}"
                    type="text"
                    placeholder="Quoted amount*"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.loanAmount') }"
                  >
                  <input
                    v-model="formData.loanAmount"
                    v-validate="'required|min_value:1|max_value:30000'"
                    name="loanAmount"
                    type="hidden"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.loanAmount"
                    message="Enter loan amount"
                  />
                </div>
              </div><!-- End form-inputs -->
            </div>
          </div> <!-- end step 1 -->
          <channel-partner-section-title
            :step="getStep(2)"
          />
          <div
            class="channel-partner-section-content"
          >
            <p>
              Tell us a little about yourself.
            </p>
            <div class="form">
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model.trim="formData.firstName"
                    v-validate="{ required: true, regex: regexRules.alphaDash }"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.firstName') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="firstName"
                    data-vv-scope="step1"
                    placeholder="First name*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.firstName"
                    message="Enter first name"
                  />
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model.trim="formData.lastName"
                    v-validate="{ required: true, regex: regexRules.alphaDashApos }"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.lastName') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="lastName"
                    data-vv-scope="step1"
                    placeholder="Last name*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.lastName"
                    message="Enter last name"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.email"
                    v-validate="'required|email'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.email') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="email"
                    data-vv-scope="step1"
                    placeholder="Email"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.email"
                    message="Enter email"
                  />
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.phone"
                    v-validate="'required|isUsPhone'"
                    v-mask="'(###) ###-####'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.phone') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="phone"
                    data-vv-scope="step1"
                    placeholder="Mobile number*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.phone"
                    message="Enter phone number"
                  />
                </div>
              </div><!-- End form-row -->
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.dob"
                    v-validate="{ required: true, date_format: 'MM/dd/yyyy', isOldRange: true, isOld: true }"
                    v-mask="'##/##/####'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.dob') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="dob"
                    data-vv-scope="step1"
                    placeholder="Date of birth (MM/DD/YYYY)"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.dob"
                    message="Enter date of birth"
                  />
                </div>
                <div class="form-group col-lg-6 ssn-field">
                  <b-button
                    v-b-tooltip.hover
                    class="tooltip-button"
                    title="Your SSN allows us to provide you with the most accurate loan options. All information is securely encrypted."
                  >
                    <font-awesome-icon icon="info-circle" />
                  </b-button>
                  <span
                    class="toggle-ssn"
                    @click="toggleSsnStatus"
                  >
                    <font-awesome-icon
                      v-if="ssnToggleStatusLabel === 'show'"
                      icon="eye"
                    />
                    <font-awesome-icon
                      v-if="ssnToggleStatusLabel === 'hide'"
                      icon="eye-slash"
                    />
                  </span>
                  <input
                    v-model="ssnFormatted"
                    v-mask="'###-##-####'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.ssn') }"
                    autocomplete="new-input"
                    :type="ssnToggleStatus"
                    placeholder="Social Security number*"
                  >
                  <input
                    v-model="formData.ssn"
                    v-validate="{ required: true, numeric: true, length: 9 }"
                    :class="{ 'form-control': true }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="hidden"
                    name="ssn"
                    data-vv-scope="step1"
                    maxlength="9"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.ssn"
                    message="Enter SSN"
                  />
                </div>
              </div><!-- End form-row -->
            </div>
          </div> <!-- end step 2 -->
          <channel-partner-section-title
            :step="getStep(3)"
          />
          <div
            class="channel-partner-section-content"
          >
            <p>
              Tell us a little about your place!
            </p>
            <div class="form">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <address-autocomplete
                    :address-type="'address1'"
                    :validation-errors="errors"
                    :form-data-parent="formData.addressData"
                    :form-scope="'step1'"
                    @acUpdate="handleAutoCompleteData($event, formData.addressData)"
                  />
                  <input
                    v-model="formData.addressData.address"
                    v-validate="'required'"
                    name="address"
                    type="hidden"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.address') }"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.address"
                    message="Enter address"
                  />
                </div>
                <div class="form-group col-sm-12">
                  <input
                    v-model="formData.address2"
                    :class="{'form-control': true}"
                    autocomplete="new-input"
                    type="text"
                    name="address2"
                    placeholder="Address line 2"
                  >
                </div>
              </div><!-- End form-row -->
              <div class="form-row">
                <div class="form-group col-lg-4">
                  <input
                    v-model="formData.addressData.city"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.city') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="city"
                    data-vv-scope="step1"
                    placeholder="City*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.city"
                    message="Enter city"
                  />
                </div>
                <div class="form-group col-lg-4 select">
                  <select
                    v-model="formData.addressData.state"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.state') }"
                    name="state"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      State*
                    </option>
                    <option
                      v-for="(state, index) in formDataDefaults.states"
                      :key="index"
                      :value="state.value"
                    >
                      {{ state.value }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.state"
                    message="Choose state"
                  />
                </div>
                <div class="form-group col-lg-4">
                  <input
                    v-model="formData.addressData.zip"
                    v-validate="'required|length:5|numeric'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.zip') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="zip"
                    data-vv-scope="step1"
                    placeholder="ZIP*"
                    maxlength="5"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.zip"
                    message="Enter ZIP"
                  />
                </div>
              </div><!-- End form-row -->
              <div class="form-row">
                <div class="form-group col-lg-12 select">
                  <select
                    v-model="formData.residenceType"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.residenceType') }"
                    name="residenceType"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Residence type*
                    </option>
                    <option
                      v-for="(type, index) in formDataDefaults.residenceTypes"
                      :key="index"
                      :value="type"
                    >
                      {{ type | sentencecase }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.residenceType"
                    message="Choose residence type"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.residenceYears"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.residenceYears') }"
                    name="residenceYears"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Time of residence* (years)
                    </option>
                    <option
                      v-for="(years, index) in formDataDefaults.residenceYears"
                      :key="index"
                      :value="years"
                    >
                      {{ years }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.residenceYears"
                    message="Choose residence years"
                  />
                </div>
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.residenceMonths"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.residenceMonths') }"
                    name="residenceMonths"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Months*
                    </option>
                    <option
                      v-for="(months, index) in formDataDefaults.residenceMonths"
                      :key="index"
                      :value="months"
                    >
                      {{ months }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.residenceMonths"
                    message="Choose residence months"
                  />
                </div>
              </div><!-- end form-row -->
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <input
                    v-model="monthlyRentFormatted"
                    v-currency="{currency: 'USD', locale: 'en'}"
                    type="text"
                    placeholder="Monthly mortgage/rent payment*"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.monthlyRent') }"
                  >
                  <input
                    v-model="formData.monthlyRent"
                    v-validate="'required|min_value:0|max_value:99999'"
                    name="monthlyRent"
                    type="hidden"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.monthlyRent"
                    message="Must be between $0 & $99,999"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div
                v-if="residenceLessThanTwo"
                class="previous-residence-section"
              >
                <p>
                  Previous residence
                </p>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <address-autocomplete
                      :address-type="'address1'"
                      :validation-errors="errors"
                      :form-data-parent="formData.prevAddressData"
                      :form-scope="'step1'"
                      @acUpdate="handleAutoCompleteData($event, formData.prevAddressData)"
                    />
                    <input
                      v-model="formData.prevAddressData.address"
                      v-validate="'required'"
                      name="previousAddress"
                      type="hidden"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousAddress') }"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousAddress"
                      message="Enter address"
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <input
                      v-model="formData.previousAddress2"
                      :class="{'form-control': true}"
                      autocomplete="new-input"
                      type="text"
                      name="previousAddress2"
                      placeholder="Address line 2"
                    >
                  </div>
                </div><!-- End form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-4">
                    <input
                      v-model="formData.prevAddressData.city"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousCity') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="previousCity"
                      data-vv-scope="step1"
                      placeholder="City*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousCity"
                      message="Enter city"
                    />
                  </div>
                  <div class="form-group col-lg-4 select">
                    <select
                      v-model="formData.prevAddressData.state"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousState') }"
                      name="previousState"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        State*
                      </option>
                      <option
                        v-for="(state, index) in formDataDefaults.states"
                        :key="index"
                        :value="state.value"
                      >
                        {{ state.value }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousState"
                      message="Choose state"
                    />
                  </div>
                  <div class="form-group col-lg-4">
                    <input
                      v-model="formData.prevAddressData.zip"
                      v-validate="'required|length:5|numeric'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousZip') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="previousZip"
                      data-vv-scope="step1"
                      placeholder="ZIP*"
                      maxlength="5"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousZip"
                      message="Enter ZIP"
                    />
                  </div>
                </div><!-- End form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.previousResidenceYears"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousResidenceYears') }"
                      name="previousResidenceYears"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Time of previous residence* (years)
                      </option>
                      <option
                        v-for="(years, index) in formDataDefaults.residenceYears"
                        :key="index"
                        :value="years"
                      >
                        {{ years }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousResidenceYears"
                      message="Select previous residence years"
                    />
                  </div>
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.previousResidenceMonths"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousResidenceMonths') }"
                      name="previousResidenceMonths"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Months*
                      </option>
                      <option
                        v-for="(months, index) in formDataDefaults.residenceMonths"
                        :key="index"
                        :value="months"
                      >
                        {{ months }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousResidenceMonths"
                      message="Select previous residence months"
                    />
                  </div>
                </div><!-- end form-row -->
              </div>
            </div>
          </div> <!-- end step 3 -->
          <channel-partner-section-title
            :step="getStep(4)"
          />
          <div
            class="channel-partner-section-content"
          >
            <p>
              Tell us a little about it.
            </p>
            <div class="form">
              <div class="form-row">
                <div class="form-group col-lg-12">
                  <input
                    v-model="grossIncomeFormatted"
                    v-currency="{currency: 'USD', locale: 'en'}"
                    type="text"
                    placeholder="Gross monthly income (before taxes)*"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.grossIncome') }"
                  >
                  <input
                    v-model="formData.grossIncome"
                    v-validate="'required|min_value:1|max_value:99999'"
                    name="grossIncome"
                    type="hidden"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.grossIncome"
                    message="Enter gross monthly income"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.employmentType"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.employmentType') }"
                    name="employmentType"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Employment type*
                    </option>
                    <option
                      v-for="(type, index) in formDataDefaults.employmentTypes"
                      :key="index"
                      :value="type"
                    >
                      {{ type | sentencedash }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.employmentType"
                    message="Choose employment type"
                  />
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.jobTitle"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.jobTitle') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="jobTitle"
                    data-vv-scope="step1"
                    placeholder="Job title*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.jobTitle"
                    message="Enter job title"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.employerName"
                    v-validate="'required'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.employerName') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="employerName"
                    data-vv-scope="step1"
                    placeholder="Employer name*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.employerName"
                    message="Enter employer's name"
                  />
                </div>
                <div class="form-group col-lg-6">
                  <input
                    v-model="formData.employerPhone"
                    v-validate="'required|isUsPhone'"
                    v-mask="'(###) ###-####'"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.employerPhone') }"
                    autocomplete="new-input"
                    data-vv-validate-on="none"
                    type="text"
                    name="employerPhone"
                    data-vv-scope="step1"
                    placeholder="Employer's phone number*"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.employerPhone"
                    message="Enter employer's phone number"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div class="form-row">
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.employmentYears"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.employmentYears') }"
                    name="employmentYears"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Time of employment* (years)
                    </option>
                    <option
                      v-for="(years, index) in formDataDefaults.residenceYears"
                      :key="index"
                      :value="years"
                    >
                      {{ years }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.employmentYears"
                    message="Choose time of employment years"
                  />
                </div>
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.employmentMonths"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.employmentMonths') }"
                    name="employmentMonths"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Months*
                    </option>
                    <option
                      v-for="(months, index) in formDataDefaults.residenceMonths"
                      :key="index"
                      :value="months"
                    >
                      {{ months }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.employmentMonths"
                    message="Choose time of employment months"
                  />
                </div>
              </div><!-- End form-inputs -->
              <div
                v-if="employmentLessThanTwo"
                class="previous-employment-section"
              >
                <p>Previous employment</p>
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.previousEmploymentType"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousEmploymentType') }"
                      name="previousEmploymentType"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Previous employment type*
                      </option>
                      <option
                        v-for="(type, index) in formDataDefaults.employmentTypes"
                        :key="index"
                        :value="type"
                      >
                        {{ type | sentencedash }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousEmploymentType"
                      message="Choose previous employment type"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.previousJobTitle"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousJobTitle') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="previousJobTitle"
                      data-vv-scope="step1"
                      placeholder="Previous job title*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousJobTitle"
                      message="Enter previous job title"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.previousEmployerName"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousEmployerName') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="previousEmployerName"
                      data-vv-scope="step1"
                      placeholder="Previous employer name*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousEmployerName"
                      message="Enter previous employer's name"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.previousEmployerPhone"
                      v-validate="'required|isUsPhone'"
                      v-mask="'(###) ###-####'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.previousEmployerPhone') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="previousEmployerPhone"
                      data-vv-scope="step1"
                      placeholder="Previous employer's phone number*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousEmployerPhone"
                      message="Enter previous employer's phone number"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.previousEmploymentYears"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousEmploymentYears') }"
                      name="previousEmploymentYears"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Time of previous employment* (years)
                      </option>
                      <option
                        v-for="(years, index) in formDataDefaults.residenceYears"
                        :key="index"
                        :value="years"
                      >
                        {{ years }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousEmploymentYears"
                      message="Choose time of previous employment years"
                    />
                  </div>
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.previousEmploymentMonths"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.previousEmploymentMonths') }"
                      name="previousEmploymentMonths"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Months*
                      </option>
                      <option
                        v-for="(months, index) in formDataDefaults.residenceMonths"
                        :key="index"
                        :value="months"
                      >
                        {{ months }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.previousEmploymentMonths"
                      message="Choose time of previous employment months"
                    />
                  </div>
                </div><!-- End form-inputs -->
              </div>
              <p class="other-income">
                Alimony, child support and separate maintenance income need not be revealed if you do not wish to have them considered as a basis for repaying this obligation.
              </p>
              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input
                    id="otherIncomeFormatted"
                    v-model="otherIncomeFormatted"
                    v-currency="{currency: 'USD', locale: 'en'}"
                    type="text"
                    placeholder="$0"
                    :class="{'form-control': true, 'is-invalid': errors.has('step1.otherIncome') }"
                    :disabled="formData.otherIncomeType === 'NONE' || formData.otherIncomeType === null"
                  >
                  <input
                    v-if="formData.otherIncomeType === 'NONE' || formData.otherIncomeType === null"
                    v-model="formData.otherIncome"
                    v-validate="{ min_value: 1, max_value: 99999, required: false }"
                    name="otherIncome"
                    type="hidden"
                  >
                  <input
                    v-else
                    v-model="formData.otherIncome"
                    v-validate="{ min_value: 1, max_value: 99999, required: true }"
                    name="otherIncome"
                    type="hidden"
                    data-vv-scope="step1"
                  >
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.otherIncome"
                    message="Enter other income"
                  />
                </div>
                <div class="form-group col-lg-6 select">
                  <select
                    v-model="formData.otherIncomeType"
                    v-validate="'required'"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('step1.otherIncomeType') }"
                    name="otherIncomeType"
                    data-vv-validate-on="none"
                    data-vv-scope="step1"
                  >
                    <option
                      value="null"
                      selected
                      disabled
                    >
                      Other monthly income type*
                    </option>
                    <option
                      v-for="(type, index) in formDataDefaults.otherIncomeTypes"
                      :key="index"
                      :value="type"
                    >
                      {{ type | sentencecase }}
                    </option>
                  </select>
                  <inline-validation-error
                    :validation-errors="errors"
                    field="step1.otherIncomeType"
                    message="Select income type"
                  />
                </div>
              </div><!-- end form-row -->
            </div>
          </div> <!-- end step 4 -->
          <div
            v-if="partnerProperties.hasCoborrower"
            class="coborrower-apply"
          >
            <h3>Applying with a co-borrower?</h3>
            <div class="radios">
              <div class="form-check form-check-inline">
                <input
                  id="yesCoborrower"
                  v-model="withCoborrower"
                  class="form-check-input"
                  type="radio"
                  value="yes"
                >
                <label
                  class="form-check-label"
                  for="yesCoborrower"
                >YES</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  id="noCoborrower"
                  v-model="withCoborrower"
                  class="form-check-input"
                  type="radio"
                  value="no"
                >
                <label
                  class="form-check-label"
                  for="noCoborrower"
                >NO</label>
              </div>
            </div>
          </div>
          <div
            v-if="withCoborrower === 'yes'"
          >
            <channel-partner-section-title
              :step="getStep(5)"
            />
            <div
              class="channel-partner-section-content"
            >
              <p>
                Tell us a little about yourself.
              </p>
              <div class="form">
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      v-model.trim="formData.coborrower.firstName"
                      v-validate="{ required: true, regex: regexRules.alphaDash }"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cofirstName') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="cofirstName"
                      data-vv-scope="step1"
                      placeholder="First name*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cofirstName"
                      message="Enter first name"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model.trim="formData.coborrower.lastName"
                      v-validate="{ required: true, regex: regexRules.alphaDashApos }"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.colastName') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="colastName"
                      data-vv-scope="step1"
                      placeholder="Last name*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.colastName"
                      message="Enter last name"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.email"
                      v-validate="'required|email'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.coemail') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="coemail"
                      data-vv-scope="step1"
                      placeholder="Email"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemail"
                      message="Enter email"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.phone"
                      v-validate="'required|isUsPhone'"
                      v-mask="'(###) ###-####'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cophone') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="cophone"
                      data-vv-scope="step1"
                      placeholder="Mobile number*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cophone"
                      message="Enter phone number"
                    />
                  </div>
                </div><!-- End form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.dob"
                      v-validate="{ required: true, date_format: 'MM/dd/yyyy', isOldRange: true, isOld: true }"
                      v-mask="'##/##/####'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.codob') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="codob"
                      data-vv-scope="step1"
                      placeholder="Date of birth (MM/DD/YYYY)"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.codob"
                      message="Enter date of birth"
                    />
                  </div>
                  <div class="form-group col-lg-6 ssn-field">
                    <b-button
                      v-b-tooltip.hover
                      class="tooltip-button"
                      title="Your SSN allows us to provide you with the most accurate loan options. All information is securely encrypted."
                    >
                      <font-awesome-icon icon="info-circle" />
                    </b-button>
                    <span
                      class="toggle-ssn"
                      @click="coToggleSsnStatus"
                    >
                      <font-awesome-icon
                        v-if="coSsnToggleStatusLabel === 'show'"
                        icon="eye"
                      />
                      <font-awesome-icon
                        v-if="coSsnToggleStatusLabel === 'hide'"
                        icon="eye-slash"
                      />
                    </span>
                    <input
                      v-model="coSsnFormatted"
                      v-mask="'###-##-####'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.cossn') }"
                      autocomplete="new-input"
                      :type="coSsnToggleStatus"
                      placeholder="Social Security number*"
                    >
                    <input
                      v-model="formData.coborrower.ssn"
                      v-validate="{ required: true, numeric: true, length: 9 }"
                      :class="{ 'form-control': true }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="hidden"
                      name="cossn"
                      data-vv-scope="step1"
                      maxlength="9"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cossn"
                      message="Enter SSN"
                    />
                  </div>
                </div><!-- End form-row -->
              </div>
            </div> <!-- end step 5 -->
            <channel-partner-section-title
              :step="getStep(6)"
            />
            <div
              class="channel-partner-section-content"
            >
              <p>
                Tell us a little about your place!
              </p>
              <div class="form">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <input
                      v-model="formData.coborrower.address"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.coaddress') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="coaddress"
                      data-vv-scope="step1"
                      placeholder="Address*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coaddress"
                      message="Enter address"
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <input
                      v-model="formData.coborrower.address2"
                      :class="{'form-control': true}"
                      autocomplete="new-input"
                      type="text"
                      name="coaddress2"
                      placeholder="Address line 2"
                    >
                  </div>
                </div><!-- End form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-4">
                    <input
                      v-model="formData.coborrower.city"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cocity') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="cocity"
                      data-vv-scope="step1"
                      placeholder="City*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cocity"
                      message="Enter city"
                    />
                  </div>
                  <div class="form-group col-lg-4 select">
                    <select
                      v-model="formData.coborrower.state"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.costate') }"
                      name="costate"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        State*
                      </option>
                      <option
                        v-for="(state, index) in formDataDefaults.states"
                        :key="index"
                        :value="state"
                      >
                        {{ state.value }}
                      </option>
                    </select>
                    <span
                      v-show="errors.has('step1.costate')"
                      class="invalid-feedback"
                    >
                      {{ errors.first('step1.costate') ? 'Choose state' : '' }}
                    </span>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.costate"
                      message="Choose state"
                    />
                  </div>
                  <div class="form-group col-lg-4">
                    <input
                      v-model="formData.coborrower.zip"
                      v-validate="'required|length:5|numeric'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cozip') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="cozip"
                      data-vv-scope="step1"
                      placeholder="ZIP*"
                      maxlength="5"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cozip"
                      message="Enter ZIP"
                    />
                  </div>
                </div><!-- End form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-12 select">
                    <select
                      v-model="formData.coborrower.residenceType"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coresidenceType') }"
                      name="coresidenceType"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Residence type*
                      </option>
                      <option
                        v-for="(type, index) in formDataDefaults.residenceTypes"
                        :key="index"
                        :value="type"
                      >
                        {{ type | sentencecase }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coresidenceType"
                      message="Choose residence type"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.residenceYears"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coresidenceYears') }"
                      name="coresidenceYears"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Time of residence* (years)
                      </option>
                      <option
                        v-for="(years, index) in formDataDefaults.residenceYears"
                        :key="index"
                        :value="years"
                      >
                        {{ years }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coresidenceYears"
                      message="Choose residence years"
                    />
                  </div>
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.residenceMonths"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coresidenceMonths') }"
                      name="coresidenceMonths"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Months*
                      </option>
                      <option
                        v-for="(months, index) in formDataDefaults.residenceMonths"
                        :key="index"
                        :value="months"
                      >
                        {{ months }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coresidenceMonths"
                      message="Choose residence months"
                    />
                  </div>
                </div><!-- end form-row -->
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <input
                      v-model="coMonthlyRentFormatted"
                      v-currency="{currency: 'USD', locale: 'en'}"
                      type="text"
                      placeholder="Monthly mortgage/rent payment*"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.comonthlyRent') }"
                    >
                    <input
                      v-model="formData.coborrower.monthlyRent"
                      v-validate="'required|min_value:0|max_value:99999'"
                      name="comonthlyRent"
                      type="hidden"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.comonthlyRent"
                      message="Must be between $0 & $99,999"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div
                  v-if="coResidenceLessThanTwo"
                  class="previous-residence-section"
                >
                  <p>
                    Previous residence
                  </p>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <input
                        v-model="formData.coborrower.previousAddress"
                        v-validate="'required'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousAddress') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousAddress"
                        data-vv-scope="step1"
                        placeholder="Address*"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousAddress"
                        message="Enter address"
                      />
                    </div>
                    <div class="form-group col-sm-12">
                      <input
                        v-model="formData.coborrower.previousAddress2"
                        :class="{'form-control': true}"
                        autocomplete="new-input"
                        type="text"
                        name="previousAddress2"
                        placeholder="Address line 2"
                      >
                    </div>
                  </div><!-- End form-row -->
                  <div class="form-row">
                    <div class="form-group col-lg-4">
                      <input
                        v-model="formData.coborrower.previousCity"
                        v-validate="'required'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousCity') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousCity"
                        data-vv-scope="step1"
                        placeholder="City*"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousCity"
                        message="Enter city"
                      />
                    </div>
                    <div class="form-group col-lg-4 select">
                      <select
                        v-model="formData.coborrower.previousState"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousState') }"
                        name="copreviousState"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          State*
                        </option>
                        <option
                          v-for="(state, index) in formDataDefaults.states"
                          :key="index"
                          :value="state"
                        >
                          {{ state.value }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousState"
                        message="Choose state"
                      />
                    </div>
                    <div class="form-group col-lg-4">
                      <input
                        v-model="formData.coborrower.previousZip"
                        v-validate="'required|length:5|numeric'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousZip') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousZip"
                        data-vv-scope="step1"
                        placeholder="ZIP*"
                        maxlength="5"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousZip"
                        message="Enter ZIP"
                      />
                    </div>
                  </div><!-- End form-row -->
                  <div class="form-row">
                    <div class="form-group col-lg-6 select">
                      <select
                        v-model="formData.coborrower.previousResidenceYears"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousResidenceYears') }"
                        name="copreviousResidenceYears"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          Time of previous residence* (years)
                        </option>
                        <option
                          v-for="(years, index) in formDataDefaults.residenceYears"
                          :key="index"
                          :value="years"
                        >
                          {{ years }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousResidenceYears"
                        message="Select previous residence years"
                      />
                    </div>
                    <div class="form-group col-lg-6 select">
                      <select
                        v-model="formData.coborrower.previousResidenceMonths"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousResidenceMonths') }"
                        name="copreviousResidenceMonths"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          Months*
                        </option>
                        <option
                          v-for="(months, index) in formDataDefaults.residenceMonths"
                          :key="index"
                          :value="months"
                        >
                          {{ months }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousResidenceMonths"
                        message="Select previous residence months"
                      />
                    </div>
                  </div><!-- end form-row -->
                </div>
              </div>
            </div> <!-- end step 6 -->
            <channel-partner-section-title
              :step="getStep(7)"
            />
            <div
              class="channel-partner-section-content"
            >
              <p>
                Tell us a little about it.
              </p>
              <div class="form">
                <div class="form-row">
                  <div class="form-group col-lg-12">
                    <input
                      v-model="coGrossIncomeFormatted"
                      v-currency="{currency: 'USD', locale: 'en'}"
                      type="text"
                      placeholder="Gross monthly income (before taxes)*"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cogrossIncome') }"
                    >
                    <input
                      v-model="formData.coborrower.grossIncome"
                      v-validate="'required|min_value:1|max_value:99999'"
                      name="cogrossIncome"
                      type="hidden"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cogrossIncome"
                      message="Enter gross monthly income"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.employmentType"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coemploymentType') }"
                      name="coemploymentType"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Employment type*
                      </option>
                      <option
                        v-for="(type, index) in formDataDefaults.employmentTypes"
                        :key="index"
                        :value="type"
                      >
                        {{ type | sentencedash }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemploymentType"
                      message="Choose employment type"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.jobTitle"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cojobTitle') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="cojobTitle"
                      data-vv-scope="step1"
                      placeholder="Job title*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cojobTitle"
                      message="Enter job title"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.employerName"
                      v-validate="'required'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.coemployerName') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="coemployerName"
                      data-vv-scope="step1"
                      placeholder="Employer name*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemployerName"
                      message="Enter employer's name"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      v-model="formData.coborrower.employerPhone"
                      v-validate="'required|isUsPhone'"
                      v-mask="'(###) ###-####'"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.coemployerPhone') }"
                      autocomplete="new-input"
                      data-vv-validate-on="none"
                      type="text"
                      name="coemployerPhone"
                      data-vv-scope="step1"
                      placeholder="Employer's phone number*"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemployerPhone"
                      message="Enter employer's phone number"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div class="form-row">
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.employmentYears"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coemploymentYears') }"
                      name="coemploymentYears"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Time of employment* (years)
                      </option>
                      <option
                        v-for="(years, index) in formDataDefaults.residenceYears"
                        :key="index"
                        :value="years"
                      >
                        {{ years }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemploymentYears"
                      message="Choose time of employment years"
                    />
                  </div>
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.employmentMonths"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.coemploymentMonths') }"
                      name="coemploymentMonths"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Months*
                      </option>
                      <option
                        v-for="(months, index) in formDataDefaults.residenceMonths"
                        :key="index"
                        :value="months"
                      >
                        {{ months }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.coemploymentMonths"
                      message="Choose time of employment months"
                    />
                  </div>
                </div><!-- End form-inputs -->
                <div
                  v-if="coEmploymentLessThanTwo"
                  class="previous-employment-section"
                >
                  <p>Previous employment</p>
                  <div class="form-row">
                    <div class="form-group col-lg-6 select">
                      <select
                        v-model="formData.coborrower.previousEmploymentType"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousEmploymentType') }"
                        name="copreviousEmploymentType"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          Previous employment type*
                        </option>
                        <option
                          v-for="(type, index) in formDataDefaults.employmentTypes"
                          :key="index"
                          :value="type"
                        >
                          {{ type | sentencedash }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousEmploymentType"
                        message="Choose previous employment type"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <input
                        v-model="formData.coborrower.previousJobTitle"
                        v-validate="'required'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousJobTitle') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousJobTitle"
                        data-vv-scope="step1"
                        placeholder="Previous job title*"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousJobTitle"
                        message="Enter previous job title"
                      />
                    </div>
                  </div><!-- End form-inputs -->
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <input
                        v-model="formData.coborrower.previousEmployerName"
                        v-validate="'required'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousEmployerName') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousEmployerName"
                        data-vv-scope="step1"
                        placeholder="Previous employer name*"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousEmployerName"
                        message="Enter previous employer's name"
                      />
                    </div>
                    <div class="form-group col-lg-6">
                      <input
                        v-model="formData.coborrower.previousEmployerPhone"
                        v-validate="'required|isUsPhone'"
                        v-mask="'(###) ###-####'"
                        :class="{'form-control': true, 'is-invalid': errors.has('step1.copreviousEmployerPhone') }"
                        autocomplete="new-input"
                        data-vv-validate-on="none"
                        type="text"
                        name="copreviousEmployerPhone"
                        data-vv-scope="step1"
                        placeholder="Previous employer's phone number*"
                      >
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousEmployerPhone"
                        message="Enter previous employer's phone number"
                      />
                    </div>
                  </div><!-- End form-inputs -->
                  <div class="form-row">
                    <div class="form-group col-lg-6 select">
                      <select
                        v-model="formData.coborrower.previousEmploymentYears"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousEmploymentYears') }"
                        name="copreviousEmploymentYears"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          Time of previous employment* (years)
                        </option>
                        <option
                          v-for="(years, index) in formDataDefaults.residenceYears"
                          :key="index"
                          :value="years"
                        >
                          {{ years }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousEmploymentYears"
                        message="Choose time of previous employment years"
                      />
                    </div>
                    <div class="form-group col-lg-6 select">
                      <select
                        v-model="formData.coborrower.previousEmploymentMonths"
                        v-validate="'required'"
                        :class="{ 'form-control': true, 'is-invalid': errors.has('step1.copreviousEmploymentMonths') }"
                        name="copreviousEmploymentMonths"
                        data-vv-validate-on="none"
                        data-vv-scope="step1"
                      >
                        <option
                          value="null"
                          selected
                          disabled
                        >
                          Months*
                        </option>
                        <option
                          v-for="(months, index) in formDataDefaults.residenceMonths"
                          :key="index"
                          :value="months"
                        >
                          {{ months }}
                        </option>
                      </select>
                      <inline-validation-error
                        :validation-errors="errors"
                        field="step1.copreviousEmploymentMonths"
                        message="Choose time of previous employment months"
                      />
                    </div>
                  </div><!-- End form-inputs -->
                </div>
                <p class="other-income">
                  Alimony, child support and separate maintenance income need not be revealed if you do not wish to have them considered as a basis for repaying this obligation.
                </p>
                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input
                      id="otherIncomeFormatted"
                      v-model="coOtherIncomeFormatted"
                      v-currency="{currency: 'USD', locale: 'en'}"
                      type="text"
                      placeholder="$0"
                      :class="{'form-control': true, 'is-invalid': errors.has('step1.cootherIncome') }"
                      :disabled="formData.coborrower.otherIncomeType === 'NONE' || formData.coborrower.otherIncomeType === null"
                    >
                    <input
                      v-if="formData.coborrower.otherIncomeType === 'NONE' || formData.coborrower.otherIncomeType === null"
                      v-model="formData.coborrower.otherIncome"
                      v-validate="{ min_value: 1, max_value: 99999, required: false }"
                      name="cootherIncome"
                      type="hidden"
                    >
                    <input
                      v-else
                      v-model="formData.coborrower.otherIncome"
                      v-validate="{ min_value: 1, max_value: 99999, required: true }"
                      name="cootherIncome"
                      type="hidden"
                      data-vv-scope="step1"
                    >
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cootherIncome"
                      message="Enter other income"
                    />
                  </div>
                  <div class="form-group col-lg-6 select">
                    <select
                      v-model="formData.coborrower.otherIncomeType"
                      v-validate="'required'"
                      :class="{ 'form-control': true, 'is-invalid': errors.has('step1.cootherIncomeType') }"
                      name="cootherIncomeType"
                      data-vv-validate-on="none"
                      data-vv-scope="step1"
                    >
                      <option
                        value="null"
                        selected
                        disabled
                      >
                        Other monthly income type*
                      </option>
                      <option
                        v-for="(type, index) in formDataDefaults.otherIncomeTypes"
                        :key="index"
                        :value="type"
                      >
                        {{ type | sentencecase }}
                      </option>
                    </select>
                    <inline-validation-error
                      :validation-errors="errors"
                      field="step1.cootherIncomeType"
                      message="Select income type"
                    />
                  </div>
                </div><!-- end form-row -->
              </div>
            </div> <!-- end step 7 -->
          </div>
          <div class="go-next">
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="next('step1')"
            >
              <div class="btn-text">
                Next <font-awesome-icon icon="caret-right" />
              </div>
            </button>
          </div>
        </div>
        <div
          v-if="currentSection === 'review'"
          class="application-review"
        >
          <channel-partner-review :form-data="formData" />

          <div class="form">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <h4>Terms</h4>
                <p>
                  By submitting this application, you acknowledge that you have read and agree to our Electronic Document Policy,
                  <a
                    href="https://www.kasasa.com/legal/terms-and-conditions"
                    target="_blank"
                  >Terms of Use</a>, and
                  <a
                    href="https://www.kasasa.com/legal/privacy"
                    target="_blank"
                  >Privacy Policy</a>; that you are at least 18 years of age and that all the information provided is true, correct, and complete and you grant permission to Kasasa and/or its Lenders to obtain a copy of your credit report.
                </p>
                <div class="policy-disclaimer">
                  You are now ready to submit your application! By clicking on "I agree", you authorize us to verify the information you submitted and may obtain your credit report. Upon your request, we will tell you if a credit report was obtained and give you the name and address of the credit reporting agency that provided the report. You warrant to us that the information you are submitting is true and correct. By submitting this application, you agree to allow us to receive the information contained in your application, as well as the status of your application.
                  <br><br>
                  Important Information About Procedures For Opening New Accounts
                  <br><br>
                  To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.
                  <br><br>
                  What This Means For You
                  <br><br>
                  When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.
                </div>
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    id="agreeCheck"
                    v-model="formData.agree"
                    type="checkbox"
                    class="custom-control-input"
                    name="agree"
                  >
                  <label
                    class="custom-control-label"
                    for="agreeCheck"
                  >I have reviewed and consent to the <a
                    href="https://www.kasasa.com/hubfs/mainstreet/documents/eSign_Consent_Form.pdf"
                    target="_blank"
                  >
                    E-Sign Disclosure.
                  </a></label>
                </div>
              </div>
            </div><!-- End form-inputs -->
          </div>
          <div>
            <button
              class="next btn btn-primary btn-block"
              type="button"
              :disabled="!formData.agree || submitting"
              @click.prevent="submitToPrequal()"
            >
              <div class="btn-text">
                <span
                  v-if="submitting"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                {{ (submitting) ? 'Loading..' : 'Submit' }}
              </div>
            </button>
          </div>
        </div> <!-- end review -->
        <div
          v-if="currentSection === 'results'"
        >
          <channel-partner-results
            :form-data="formData"
            :results-data="offerData"
            :partner-name="partnerProperties.partnerName"
          />
        </div>
      </div>
    </div>
    <error-modal ref="errorModal" />
  </div>
</template>

<script>
import { formDataDefaults } from '@/utils/formDataDefaults'
import { steps } from '@/utils/channelPartnerDefaults'
import { createChannelPartnerApplication, getApplicationStatus } from '@/api/appservice.api';
import { delayPoll, closeInputErrorValidation, allowCloseErrorValidation, scrollToSectionTop, regexRules } from '@/utils/utils'
import ChannelPartnerSectionTitle from '@/components/ChannelPartnerSectionTitle.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import ChannelPartnerHeading from '@/components/ChannelPartnerHeading.vue';
import ChannelPartnerReview from '@/components/ChannelPartnerReview.vue';
import ChannelPartnerResults from '@/components/ChannelPartnerResults.vue';
import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import InlineValidationError from '@/components/InlineValidationError.vue';
import { GtmService } from '@/services/gtm';
import { capitalizeWord } from '@/utils/utils';

const PAGE_NAME = (fiName) => `Partner: ${fiName}`;
const APPLICATION_PAGE_NAME = (fiName) => `${PAGE_NAME(fiName)}: Personal Loan Application`;
const REVIEW_PAGE_NAME = (fiName) => `${PAGE_NAME(fiName)}: Personal Loan Review and Submit`;
const RESULTS_PAGE_NAME = (fiName) => `${PAGE_NAME(fiName)}: Personal Loan Results`;

export default {
  components: {
    AddressAutocomplete,
    ChannelPartnerSectionTitle,
    ErrorModal,
    ChannelPartnerHeading,
    ChannelPartnerReview,
    ChannelPartnerResults,
    InlineValidationError
  },
  props: {
    partnerProperties: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      step: 1,
      steps,
      formDataDefaults,
      formData: {
        orderNumber: null,
        loanPurpose: null,
        loanAmount: null,
        loanTerm: null,
        creditScore: null,
        residenceType: null,
        residenceYears: null,
        residenceMonths: null,
        previousResidenceYears: null,
        previousResidenceMonths: null,
        firstName: null,
        lastName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        previousAddress: null,
        previousAddress2: null,
        previousCity: null,
        previousState: null,
        previousZip: null,
        email: null,
        dob: null,
        phone: null,
        monthlyRent: null,
        employmentType: null,
        jobTitle: null,
        employerName: null,
        employerPhone: null,
        employmentYears: null,
        employmentMonths: null,
        previousEmploymentType: null,
        previousJobTitle: null,
        previousEmployerName: null,
        previousEmployerPhone: null,
        previousEmploymentYears: null,
        previousEmploymentMonths: null,
        grossIncome: null,
        otherIncome: null,
        otherIncomeType: null,
        ssn: null,
        agree: false,
        addressData: {
          fullAddress: null,
          address: null,
          city: null,
          state: null,
          zip: null,
          county: null
        },
        prevAddressData: {
          fullAddress: null,
          address: null,
          city: null,
          state: null,
          zip: null,
          county: null
        },
        coborrower: {
          residenceType: null,
          residenceYears: null,
          residenceMonths: null,
          previousResidenceYears: null,
          previousResidenceMonths: null,
          firstName: null,
          lastName: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          previousAddress: null,
          previousAddress2: null,
          previousCity: null,
          previousState: null,
          previousZip: null,
          email: null,
          dob: null,
          phone: null,
          monthlyRent: null,
          employmentType: null,
          jobTitle: null,
          employerName: null,
          employerPhone: null,
          employmentYears: null,
          employmentMonths: null,
          previousEmploymentType: null,
          previousJobTitle: null,
          previousEmployerName: null,
          previousEmployerPhone: null,
          previousEmploymentYears: null,
          previousEmploymentMonths: null,
          grossIncome: null,
          otherIncome: null,
          otherIncomeType: null,
          ssn: null,
        }
      },
      loanAmountFormatted: null,
      monthlyRentFormatted: null,
      grossIncomeFormatted: null,
      otherIncomeFormatted: null,
      ssnFormatted: null,
      coMonthlyRentFormatted: null,
      coGrossIncomeFormatted: null,
      coOtherIncomeFormatted: null,
      coSsnFormatted: null,
      ssnToggleStatus: 'password',
      ssnToggleStatusLabel: 'show',
      coSsnToggleStatus: 'password',
      coSsnToggleStatusLabel: 'show',
      residenceLessThanTwo: false,
      employmentLessThanTwo: false,
      coResidenceLessThanTwo: false,
      coEmploymentLessThanTwo: false,
      isPersonal: false,
      regexRules,
      submitting: false,
      maxPollingTriesReached: false,
      withCoborrower: 'no',
      currentSection: 'application', // application, review, results,
      applicationId: null,
      isPolling: false,
      idvStatus: null,
      offerData: null
    }
  },
  computed: {
    termFormatted: function () {
      return `${this.formData.loanTerm} months`;
    }
  },
  watch: {
    loanAmountFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.loanAmount = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.loanAmount = '';
      }
    },
    monthlyRentFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.monthlyRent = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.monthlyRent = '';
      }
    },
    grossIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.grossIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.grossIncome = '';
      }
    },
    otherIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.otherIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.otherIncome = '';
      }
    },
    ssnFormatted: function (val) {
      if (val !== null) {
        this.formData.ssn = val.replace(/-/g,'');
      }
      if (val === '') {
        this.formData.ssn = '';
      }
    },
    'formData.employmentYears': function (val) {
      const years = parseFloat(val)
      this.employmentLessThanTwo = ( years < 2 ) ? true : false;
    },
    'formData.residenceYears': function (val) {
      const years = parseFloat(val)
      this.residenceLessThanTwo = ( years < 2 ) ? true : false;
    },
    coMonthlyRentFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.coborrower.monthlyRent = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.coborrower.monthlyRent = '';
      }
    },
    coGrossIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.coborrower.grossIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.coborrower.grossIncome = '';
      }
    },
    coOtherIncomeFormatted: function (val) {
      if (this.$ci.parse(val) !== null) {
        this.formData.coborrower.otherIncome = this.$ci.parse(val);
      }
      if (val === '') {
        this.formData.coborrower.otherIncome = '';
      }
    },
    coSsnFormatted: function (val) {
      if (val !== null) {
        this.formData.coborrower.ssn = val.replace(/-/g,'');
      }
      if (val === '') {
        this.formData.coborrower.ssn = '';
      }
    },
    'formData.coborrower.employmentYears': function (val) {
      const years = parseFloat(val)
      this.coEmploymentLessThanTwo = ( years < 2 ) ? true : false;
    },
    'formData.coborrower.residenceYears': function (val) {
      const years = parseFloat(val)
      this.coResidenceLessThanTwo = ( years < 2 ) ? true : false;
    },
	currentSection: function (section) {
		this.handleSectionChange(section);
	},
  },
  created() {
    this.isPersonal = true;

    this.$root.$on('goto-step', (id) => {
      this.handleGoToStep(id)
    });

	this.handleSectionChange(this.currentSection);
  },
  mounted() {
	  this.$store.commit('setChannelPartnerForm', { channelPartnerForm: undefined });
  },
  methods: {
	handleSectionChange(section) {
		if (section === 'application') {
			GtmService.addPageLoadEvent({
				pageType: 'partnerApplication',
				consumerType: 'Channel Partner',
				fiId: String(this.$store.state.channelPartner.fiId),
				fiName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
				pageName: APPLICATION_PAGE_NAME(capitalizeWord(this.$store.state.channelPartner.partnerName)),
				productType: 'Personal Loan',
				loanPurpose: 'Home Improvement',
				rate: undefined,
				amount: undefined,
				creditScore: undefined,
				websiteType: 'Partner Loans',
			});

			return;
		}

		if (section === 'review') {
			GtmService.addPageLoadEvent({
				pageType: 'partnerApplication',
				consumerType: 'Channel Partner',
				fiId: String(this.$store.state.channelPartner.fiId),
				fiName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
				pageName: REVIEW_PAGE_NAME(capitalizeWord(this.$store.state.channelPartner.partnerName)),
				productType: 'Personal Loan',
				loanPurpose: 'Home Improvement',
				rate: undefined,
				amount: String(this.formData.loanAmount),
				creditScore: undefined,
				websiteType: 'Partner Loans',
			});

			return;
		}

		if (section === 'results') {
			GtmService.addPageLoadEvent({
				pageType: 'partnerApplication',
				consumerType: 'Channel Partner',
				fiId: String(this.$store.state.channelPartner.fiId),
				fiName: `Partner: ${capitalizeWord(this.$store.state.channelPartner.partnerName)}`,
				pageName: RESULTS_PAGE_NAME(capitalizeWord(this.$store.state.channelPartner.partnerName)),
				productType: 'Personal Loan',
				loanPurpose: 'Home Improvement',
				rate: undefined,
				amount: String(this.formData.loanAmount),
				creditScore: undefined,
				websiteType: 'Partner Loans',
			});
		}
	},
    toggleSsnStatus() {
      this.ssnToggleStatus = ( this.ssnToggleStatus === 'password' ) ? 'text' : 'password';
      this.ssnToggleStatusLabel = ( this.ssnToggleStatus === 'password' ) ? 'show' : 'hide';
    },
    coToggleSsnStatus() {
      this.coSsnToggleStatus = ( this.coSsnToggleStatus === 'password' ) ? 'text' : 'password';
      this.coSsnToggleStatusLabel = ( this.coSsnToggleStatus === 'password' ) ? 'show' : 'hide';
    },
    showContent(stepScope) {
      // If step is active allow show
      if ( stepScope.active ) {
        stepScope.show = !stepScope.show;
      }
    },
    showStep(stepScope) {
      // If active and shown
      if ( stepScope.active && stepScope.show ) {
        return true;
      }
      return false;
    },
    validate(scope) {
      // if scope validate fields in scope
      return this.$validator.validateAll(scope);
    },
    scrollStepIntoView(step) {
      document.querySelector(`.step${step}`).scrollIntoView();
    },
    handleGoToStep(stepId) {
      this.currentSection = 'application';
      setTimeout(() => {
        this.scrollStepIntoView(stepId);
      }, 500);
    },
    next(stepScope) {
      if ( stepScope !== 'none' ) {
        this.validate(stepScope).then((valid) => {
          if (valid) {
            this.currentSection = 'review';
            scrollToSectionTop();
          } else {
            // Show error messages
            closeInputErrorValidation();

            // Scroll to first error message
            document.querySelector('.is-invalid').scrollIntoView();
          }
        });
      } else {
        this.step++;
      }
      // Hide error messages on click
      allowCloseErrorValidation();
    },
    getStep(step) {
      // Build step object for sections
      return {
        ...this.steps[`step${step}`],
        ...{
          showStep: this.showStep(this.steps[`step${step}`])
        }
      }
    },
    handleAutoCompleteData: function (e, addressData) {
      [addressData.fullAddress, addressData.address, addressData.city, addressData.state, addressData.zip, addressData.county] = e;
    },
    async submitToPrequal(){
      const payload = this.getPayload();
      this.submitting = true;

	  this.$store.commit(
		  'setChannelPartnerForm',
			{
				channelPartnerForm: {
					formData: this.formData,
					withCoborrower: this.withCoborrower,
				}
			},
	  );

      if ( this.isPolling ) {
        this.getStatusPolling(0);
      } else {
        try {
          const apply = await createChannelPartnerApplication(payload);
          this.idvStatus = apply.data.data.status;
          this.applicationId = apply.data.data.applicationExternalId;

          if ( this.idvStatus === 'IDV_SUCCESS' ) {
            this.isPolling = true;
            // Start polling
            this.getStatusPolling(0);
          } else {
            console.log('Could not verify you --- throw modal')
          }
        } catch(e) {
          console.log(e);
          this.$refs.errorModal.closeButton = true;
          this.$refs.errorModal.showModal('technical');
          this.submitting = false;
		  this.isPolling = false;
        }
      }
    },
    async getStatusPolling(retryCount) {
      try {
        const polledData = await getApplicationStatus({
          "applicationExternalId": this.applicationId
        });
        this.checkAppStatus(polledData, retryCount)
      } catch (e) {
        console.log(e);
        this.$refs.errorModal.returnButton = true;
        this.$refs.errorModal.showModal('technical');
        this.submitting = false;
      }
    },
    async checkAppStatus(polledData, retryCount) {
      const status = polledData.data.data.status;

      switch (status) {
        case 'NOT_QUALIFIED':
        case 'RATES_FAILED':
          // Send to confirmation page with custom message
          console.log(status);
          this.isPolling = false;
          this.$refs.errorModal.returnButton = true;
          this.$refs.errorModal.showModal('technical');
          return false;
          break;
        case 'PREQUALIFIED':
          // Send to confirmation page with custom message
          this.isPolling = false;
          this.offerData = polledData.data;
          this.currentSection = 'results';
          scrollToSectionTop();
          break;
        case 'IDV_SUCCESS':
        default:
          // continue
          if ( retryCount > 10 ) {
            console.log('reached max retries');
            this.maxPollingTriesReached = true;
            this.submitting = false;
            return false;
          }
          await delayPoll(retryCount);
          this.getStatusPolling(retryCount + 1);
      }
    },
    getTrackingData(step) {
      return {
        title: this.steps[`step${step}`]['title'],
        stepNumber: this.steps[`step${step}`]['id'],
      }
    },
    getPayload() {
      return {
        "loanType": "PERSONAL",
        "loanPurposeType": 'Home Improvement',
        "amount": this.formData.loanAmount.toString(),
        "applicationSource": 'Stately',
        "storeNumber": 0,
        "storeName": "stately",
        "employeeName": "string",
        "orderNumber": this.formData.orderNumber,
        "firstName": this.formData.firstName.trim(),
        "middleName": null,
        "lastName": this.formData.lastName.trim(),
        "dob": this.formData.dob,
        "ssn": this.formData.ssn,
        "creditScore": '0',
        "email": this.formData.email,
        "phone": this.formData.phone,
        "resType": this.formData.residenceType.toUpperCase(),
        "address": this.formData.addressData.address,
        "apt": null,
        "city": this.formData.addressData.city.trim(),
        "state": this.formData.addressData.state,
        "zip": this.formData.addressData.zip,
        "county": 'Orange',
        "addrY": this.formData.residenceYears,
        "addrM": this.formData.residenceMonths,
        "housePmt": this.formData.monthlyRent.toString(),
        "employer": this.formData.employerName.trim(),
        "empType": this.formData.employmentType.toUpperCase(),
        "empTitle": this.formData.jobTitle.trim(),
        "empPhone": this.formData.employerPhone,
        "empExt": null,
        "empY": this.formData.employmentYears,
        "empM": this.formData.employmentMonths,
        "empAddress": null,
        "empCity": null,
        "empState": null,
        "empZip": null,
        "grossMthInc": this.formData.grossIncome.toString(),
        "otherMthInc": this.formData.otherIncome === null ? null : this.formData.otherIncome.toString(),
        "otherIncType": this.formData.otherIncomeType === 'NONE' ? null : this.formData.otherIncomeType,
        "prevEmpType": this.formData.previousEmploymentType === null ? null : this.formData.previousEmploymentType.toUpperCase(),
        "prevEmployer": this.formData.previousEmployerName === null ? null : this.formData.previousEmployerName.trim(),
        "prevEmpTitle": this.formData.previousJobTitle === null ? null : this.formData.previousJobTitle.trim(),
        "prevEmpPhone": this.formData.previousEmployerPhone === null ? null : this.formData.previousEmployerPhone.trim(),
        "prevEmpExt": null,
        "prevEmpY": this.formData.previousEmploymentYears,
        "prevEmpM": this.formData.previousEmploymentMonths,
        "prevAddress": this.formData.previousAddress,
        "prevApt": null,
        "prevCity": this.formData.previousCity === null ? null : this.formData.previousCity.trim(),
        "prevState": this.formData.previousState === null ? null : this.formData.previousState.value,
        "prevZip": this.formData.previousZip,
        "prevCounty": null,
        "prevAddrY": this.formData.previousResidenceYears,
        "prevAddrM": this.formData.previousResidenceMonths
      }
    },
  }
}
</script>