<template>
  <transition name="fade">
    <div
      v-if="show"
      class="v-modal"
    >
      <div
        v-if="closeButton === false"
        class="v-modal__backdrop"
      />
      <div
        v-else
        class="v-modal__backdrop"
        @click="closeModal()"
      />

      <div class="v-modal__dialog">
        <button
          v-if="closeButton !== false"
          type="button"
          aria-label="Close"
          class="v-modal__close"
          @click="closeModal()"
        >
          <span class="text">CLOSE</span>
        </button>

        <div class="v-modal__body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
    props: {
        closeButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        closeModal() {
            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
            document.querySelector("html").style.overflow = 'initial';
        },
        openModal() {
            this.show = true;
            document.querySelector("body").classList.add("overflow-hidden");
            document.querySelector("html").style.overflow = 'hidden';
        }
    }
}
</script>
