import { flow } from "lodash";

export function goBack() {
    const defaultLocation = "https://www.kasasa.com/kasasaloan";
    const oldHash = window.location.hash;

    history.back(); // Try to go back

    const newHash = window.location.hash;

    /* If the previous page hasn't been loaded in a given time (in this case
     * 1000ms) the user is redirected to the default location given above.
     * This enables you to redirect the user to another page.
     *
     * However, you should check whether there was a referrer to the current
     * site. This is a good indicator for a previous entry in the history
     * session.
     *
     * Also you should check whether the old location differs only in the hash,
     * e.g. /index.html#top --> /index.html# shouldn't redirect to the default
     * location.
     */

    if (
        newHash === oldHash &&
        (typeof (document.referrer) !== "string" || document.referrer === "")
    ) {
        window.setTimeout(function () {
            // redirect to default location
            window.location.href = defaultLocation;
        }, 1000); // set timeout in ms
    }
    if (e) {
        if (e.preventDefault)
            e.preventDefault();
        if (e.preventPropagation)
            e.preventPropagation();
    }
    return false; // stop event propagation and browser default event
}

export function preventNonNumericInput(event) {
    let value = event.target.value;
    // Check if value is number
    let isValid = +value == +value;

    if (!isValid) {
        // input event is not cancellable by preventDefault()
        // so we have to use the below approach to reset the current value to previous one
        var resetEvent = document.createEvent('Event');
        resetEvent.initEvent('input', true, true);
        event.target.value = event.target._value;
        event.target.dispatchEvent(resetEvent);
    }
}

export function closeInputErrorValidation() {
    // show error messages on validation failure
    document.querySelectorAll('.invalid-feedback').forEach(function(item){
        item.style.visibility = 'visible';
    })
}

export function allowCloseErrorValidation() {
    // Hide error messages on click
    document.querySelectorAll('.invalid-feedback').forEach(function(item){
        item.addEventListener('click', function() {
            this.style.visibility = 'hidden';
        })
    })
}

export function getViewportWidth() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}

export function delayPoll(retryCount) {
    return new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function scrollToSectionTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}

export const regexRules = {
    alphaDash: /^[A-Z-\s]*$/i,
    alphaDashApos: /^[A-Z-'\s]*$/i,
}

export const capitalizeWord = (word = '') => word.charAt(0).toUpperCase() + word.slice(1);

export const capitalizeWordString = (wordString = '') => wordString
	.split(' ')
	.map(capitalizeWord)
	.join(' ');

export const formatCurrency = (amount = 0, locale = 'en-US') => new Intl.NumberFormat(locale).format(Number(String(amount).replace(/[^0-9.]/g,'')));

/**
 * Takes a dollar amount as a string or number
 * and adds commas where hundredths exist i.e. xx,xxx,xxx
 *
 * @param dollars dollar value
 *
 * @returns formatted dollar amount string
 */
export const addCommasToDollarAmount = (dollars) => dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Given a string value
 * Removes all non numerical characters from the string
 *
 * @param value string to evaluate
 *
 * @returns evaluated string
 */
export const removeNonNumbersFromString = (value) => value.replace(/\D/g, '');

/**
 * Given a cents dollar or string value
 * Formats the value to staisfy dollar cent value requirements.
 *
 * i.e.
 * If value is 3, should return 30
 * If value is 729 should return 72
 *
 *
 * @param cents cents value
 *
 * @returns formatted cent string
 */
export const formatCentAmount = (cents) => {
	const formattedCents = cents.toString().substring(0, 2);

	if (formattedCents.length === 0) {
		return '00';
	}

	if (formattedCents.length === 1) {
		return `${formattedCents}0`;
	}

	return formattedCents;
};

/**
 * Formats a number or string value as a dollar amount
 *
 * @param value - value to be formatted
 * @param prefix - string to prepend to the dollar amount, default = '$'
 *
 * @returns formatted dollar string
 */
export const formatDollar = (
	value,
	prefix = '$',
) => {
	const dollarParts = (value || '0').toString().split('.');
	const dollars = flow([
		removeNonNumbersFromString,
		addCommasToDollarAmount,
	])(dollarParts[0] || '');

	const cents = flow([
		removeNonNumbersFromString,
		formatCentAmount,
	])(dollarParts[1] || '');

	// Account for negative numbers
	if (Number(value) < 0) {
		return `-${prefix}${dollars}.${cents}`;
	}

	return `${prefix}${dollars}.${cents}`;
};