export const PARTNER_FORM_SECTIONS = {
  LOAN_INFORMATION: 'LOAN_INFORMATION',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  RESIDENCE_INFORMATION: 'RESIDENCE_INFORMATION',
  EMPLOYMENT_INFORMATION: 'EMPLOYMENT_INFORMATION',
  WITH_JOINT_APPLICANT: 'WITH_JOINT_APPLICANT',
  JOINT_APPLICANT_PERSONAL_INFORMATION: 'JOINT_APPLICANT_PERSONAL_INFORMATION',
  JOINT_APPLICANT_RESIDENCE_INFORMATION: 'JOINT_APPLICANT_RESIDENCE_INFORMATION',
  JOINT_APPLICANT_EMPLOYMENT_INFORMATION: 'JOINT_APPLICANT_EMPLOYMENT_INFORMATION',
};
