<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="loanAmountFormatted"
          v-currency="{currency: 'USD', locale: 'en'}"
          name="loanAmountFormatted"
          type="text"
          placeholder="Loan amount*"
          class="form-control"
          :class="{'is-invalid': form.loanAmount.error.state.isShown}"
          @input="handleInput"
        >
        <input
          v-model="form.loanAmount.value"
          v-validate="form.loanAmount.validators"
          :name="form.loanAmount.name"
          type="hidden"
          placeholder="Loan amount*"
          class="form-control"
        >
        <InputError
          :state="form.loanAmount.error.state"
          :message="form.loanAmount.error.message"
        />
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group position-relative">
        <input
          v-model="form.orderNumber.value"
          v-mask="'###################'"
          :name="form.orderNumber.name"
          type="text"
          placeholder="Order number"
          class="form-control"
          @input="handleInput"
        >
      </div>
    </div>
  </div>
  <!-- ./row -->
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';

const LoanInformation = {
  components: {
    InlineValidationError,
    InputError,
  },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      form: {
        loanAmount: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'loanAmount',
          validators: {
            requiredExtended: {
              message: 'Enter loan amount'
            },
            positive: {
              message: 'Loan amount must be positive',
            },
          },
        },
        orderNumber: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'orderNumber',
        },
      },
      loanAmountFormatted: null,
      hasValidated: false,
    };
  },
  methods: {
    handleInputError(input) {
      this.form[input].error = {
        ...this.form[input].error,
        state: {
          ...this.form[input].error.state,
          isShown: this.errors.has(this.form[input].name),
        },
        message: this.errors.first(input),
      };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {
        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.$emit('collect-data', { form: this.form, isValid: true });
        }
      });

      this.hasValidated = true;
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    }
  },
  watch: {
    event(event) {
      if (event.type === 'VALIDATE') {
        this.validate();
      }
    },
    loanAmountFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.-]/g,'');

      this.form.loanAmount.value = amount;
    }
  },
};

export default LoanInformation;
</script>