<template>
  <div>
    <div v-if="isMaintenanceModeEnabled">
      <MaintenanceModePage />
    </div>
    <router-view />
  </div>
</template>
<script>
import MaintenanceModePage from '@/pages/MaintenanceModePage.vue';

const App = {
  components: {
    MaintenanceModePage,
	},
  computed: {
    isMaintenanceModeEnabled() {
      return this.$store.state.isMaintenanceModeEnabled;
    }
  },
}

export default App;
  </script>