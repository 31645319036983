<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <div
        class="font-barlow-bold"
        style="flex: 0 0 65%;"
      >
        Residence information
      </div>
      <div>
        <button
          type="button"
          class="btn btn-link btn-link-primary"
          @click="handleEdit"
        >
          {{ isEditing ? 'CANCEL' : 'EDIT INFO' }}
        </button>
      </div>
    </div>

    <div class="mb-2">
      <div>
        Residence type
      </div>
      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.residenceType.value }}
      </div>

      <div v-show="isEditing">
        <div class="form-group select position-relative">
          <select
            v-model="form.residenceType.value"
            v-validate="form.residenceType.validators"
            class="form-control"
            :class="{'is-invalid': form.residenceType.error.state.isShown}"
            :name="form.residenceType.name"
            @change="handleInput"
          >
            <option
              value="null"
              selected
              disabled
            >
              Residence type*
            </option>
            <option
              v-for="(type, index) in formDataDefaults.residenceTypes"
              :key="index"
              :value="type"
            >
              {{ type | sentencecase }}
            </option>
          </select>
          <InputError
            :state="form.residenceType.error.state"
            :message="form.residenceType.error.message"
          />
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div>
        Time of residence
      </div>

      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ form.residenceYears.value }} Years {{ form.residenceMonths.value }} Months
      </div>
      <div v-show="isEditing">
        <div class="form-group select position-relative">
          <select
            v-model="form.residenceYears.value"
            v-validate="form.residenceYears.validators"
            class="form-control"
            :class="{ 'is-invalid': form.residenceYears.error.state.isShown }"
            :name="form.residenceYears.name"
            @change="handleInput"
          >
            <option
              value="null"
              selected
              disabled
            >
              Time of residence* (years)
            </option>
            <option
              v-for="(years, index) in formDataDefaults.residenceYears"
              :key="index"
              :value="years"
            >
              {{ years }}
            </option>
          </select>
          <InputError
            :state="form.residenceYears.error.state"
            :message="form.residenceYears.error.message"
          />
        </div>
        <div class="form-group select position-relative">
          <select
            v-model="form.residenceMonths.value"
            v-validate="form.residenceMonths.validators"
            class="form-control"
            :class="{ 'is-invalid': form.residenceMonths.error.state.isShown }"
            :name="form.residenceMonths.name"
            @change="handleInput"
          >
            <option
              value="null"
              selected
              disabled
            >
              Months*
            </option>
            <option
              v-for="(months, index) in formDataDefaults.residenceMonths"
              :key="index"
              :value="months"
            >
              {{ months }}
            </option>
          </select>
          <InputError
            :state="form.residenceMonths.error.state"
            :message="form.residenceMonths.error.message"
          />
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div>
        Monthly mortgage/rent payment
      </div>

      <div
        v-show="!isEditing"
        class="font-barlow-light font-size-09"
      >
        {{ formatDollar(form.monthlyPayment.value) }}
      </div>

      <div v-show="isEditing">
        <div class="form-group position-relative">
          <input
            v-model="monthlyPaymentFormatted"
            v-currency="{currency: 'USD', locale: 'en'}"
            name="monthlyPaymentFormatted"
            type="text"
            placeholder="Monthly mortgage/rent payment*"
            class="form-control"
            :class="{'is-invalid': form.monthlyPayment.error.state.isShown}"
            @input="handleInput"
          >
          <input
            v-model="form.monthlyPayment.value"
            v-validate="form.monthlyPayment.validators"
            :name="form.monthlyPayment.name"
            type="hidden"
          >
          <InputError
            :state="form.monthlyPayment.error.state"
            :message="form.monthlyPayment.error.message"
          />
        </div>
      </div>
    </div>

    <div
      v-show="isPreviousResidenceShown"
    >
      <div class="mb-2">
        <div>
          Previous residence type
        </div>

        <div
          v-show="!isEditing"
          class="font-barlow-light font-size-09"
        >
          {{ form.previousResidenceType.value }}
        </div>

        <div v-show="isEditing">
          <div class="form-group select position-relative">
            <select
              v-model="form.previousResidenceType.value"
              v-validate="{ required: isPreviousResidenceShown }"
              class="form-control"
              :class="{'is-invalid': errors.has('previousResidenceType')}"
              name="previousResidenceType"
              @change="handleInput"
            >
              <option
                value="null"
                selected
                disabled
              >
                Previous residence type*
              </option>
              <option
                v-for="(type, index) in formDataDefaults.residenceTypes"
                :key="index"
                :value="type"
              >
                {{ type | sentencecase }}
              </option>
            </select>
            <InputError
              :state="form.previousResidenceType.error.state"
              :message="form.previousResidenceType.error.message"
            />
          </div>
        </div>
      </div>

      <div class="mb-2">
        <div>
          Previous address
        </div>

        <div
          v-show="!isEditing"
          class="font-barlow-light font-size-09"
        >
          {{ previousFullAddress }}
        </div>

        <div v-show="isEditing">
          <div class="form-group position-relative">
            <AddressInput
              :is-invalid="form.previousResidenceAddress1.error.state.isShown"
              :value="form.previousResidenceAddress1.value"
              placeholder="Address*"
              @change="handlePreviousResidenceAddress1InputChange"
              @context="handlePreviousResidenceAddress1InputContext"
            />
            <input
              v-model="form.previousResidenceAddress1.value"
              v-validate="form.previousResidenceAddress1.validators(isPreviousResidenceShown)"
              :name="form.previousResidenceAddress1.name"
              type="hidden"
            >
            <InputError
              :state="form.previousResidenceAddress1.error.state"
              :message="form.previousResidenceAddress1.error.message"
            />
          </div>
          <div class="form-group position-relative">
            <input
              v-model="form.previousResidenceAddress2.value"
              v-validate="form.previousResidenceAddress2.validators(isPreviousResidenceShown)"
              :name="form.previousResidenceAddress2.name"
              type="text"
              placeholder="Address line 2"
              class="form-control"
              :class="{'is-invalid': form.previousResidenceAddress2.error.state.isShown}"
              @input="handleInput"
            >
            <InputError
              :state="form.previousResidenceAddress2.error.state"
              :message="form.previousResidenceAddress2.error.message"
            />
          </div>
          <div class="form-group position-relative">
            <input
              v-model="form.previousResidenceCity.value"
              v-validate="form.previousResidenceCity.validators(isPreviousResidenceShown)"
              :name="form.previousResidenceCity.name"
              type="text"
              placeholder="City*"
              class="form-control"
              :class="{'is-invalid': form.previousResidenceCity.error.state.isShown}"
              @input="handleInput"
            >
            <InputError
              :state="form.previousResidenceCity.error.state"
              :message="form.previousResidenceCity.error.message"
            />
          </div>
          <div class="form-group select position-relative">
            <select
              v-model="form.previousResidenceState.value"
              v-validate="form.previousResidenceState.validators(isPreviousResidenceShown)"
              class="form-control"
              :class="{'is-invalid': form.previousResidenceState.error.state.isShown}"
              :name="form.previousResidenceState.name"
              @change="handleInput"
            >
              <option
                value="null"
                selected
                disabled
              >
                State*
              </option>
              <option
                v-for="(state, index) in formDataDefaults.states"
                :key="index"
                :value="state.value"
              >
                {{ state.value }}
              </option>
            </select>
            <InputError
              :state="form.previousResidenceState.error.state"
              :message="form.previousResidenceState.error.message"
            />
          </div>
          <div class="form-group position-relative">
            <input
              v-model="form.previousResidenceZip.value"
              v-validate="form.previousResidenceZip.validators(isPreviousResidenceShown)"
              v-mask="'##########'"
              :name="form.previousResidenceZip.name"
              type="text"
              placeholder="ZIP*"
              class="form-control"
              :class="{'is-invalid': form.previousResidenceZip.error.state.isShown}"
              @input="handleInput"
            >
            <InputError
              :state="form.previousResidenceZip.error.state"
              :message="form.previousResidenceZip.error.message"
            />
          </div>
        </div>
      </div>

      <div class="mb-2">
        <div>
          Previous time of residence
        </div>

        <div
          v-show="!isEditing"
          class="font-barlow-light font-size-09"
        >
          {{ form.previousResidenceYears.value }} Years {{ form.previousResidenceMonths.value }} Months
        </div>

        <div v-show="isEditing">
          <div class="form-group select position-relative">
            <select
              v-model="form.previousResidenceYears.value"
              v-validate="form.previousResidenceYears.validators(isPreviousResidenceShown)"
              class="form-control"
              :class="{ 'is-invalid': form.previousResidenceYears.error.state.isShown }"
              :name="form.previousResidenceYears.name"
              @change="handleInput"
            >
              <option
                value="null"
                selected
                disabled
              >
                Previous time of residence* (years)
              </option>
              <option
                v-for="(years, index) in formDataDefaults.residenceYears"
                :key="index"
                :value="years"
              >
                {{ years }}
              </option>
            </select>
            <InputError
              :state="form.previousResidenceYears.error.state"
              :message="form.previousResidenceYears.error.message"
            />
          </div>

          <div class="form-group select position-relative">
            <select
              v-model="form.previousResidenceMonths.value"
              v-validate="form.previousResidenceMonths.validators(isPreviousResidenceShown)"
              class="form-control"
              :class="{ 'is-invalid': form.previousResidenceMonths.error.state.isShown }"
              :name="form.previousResidenceMonths.name"
              @change="handleInput"
            >
              <option
                value="null"
                selected
                disabled
              >
                Months*
              </option>
              <option
                v-for="(months, index) in formDataDefaults.residenceMonths"
                :key="index"
                :value="months"
              >
                {{ months }}
              </option>
            </select>
            <InputError
              :state="form.previousResidenceMonths.error.state"
              :message="form.previousResidenceMonths.error.message"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="isEditing"
    >
      <button
        class="next btn btn-primary btn-block"
        type="button"
        @click="validate()"
      >
        <div class="btn-text">
          Update
          <font-awesome-icon
            icon="caret-right"
            class="ml-2"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import InlineValidationError from '@/components/InlineValidationError.vue';
import InputError from '@/components/InputError.vue';
import { formatDollar } from '@/utils/utils';
import { formDataDefaults } from '@/utils/formDataDefaults';
import AddressInput from '@/components/AddressInput.vue';

const ResidenceInformationReview = {
  components: {
    InlineValidationError,
    InputError,
    AddressInput,
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isPreviousResidenceShown: false,
      form: {
        residenceType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'residenceType',
          validators: {
            requiredExtended: {
              message: 'Choose residence type',
            },
          },
        },
        residenceYears: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'residenceYears',
          validators: {
            requiredExtended: {
              message: 'Choose time of residence',
            },
          },
        },
        residenceMonths: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'residenceMonths',
          validators: {
            requiredExtended: {
              message: 'Choose time of residence',
            },
          },
        },
        previousResidenceYears: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceYears',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Choose time of residence',
            },
          }),
        },
        previousResidenceMonths: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceMonths',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Choose time of residence',
            },
          }),
        },
        monthlyPayment: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'monthlyPayment',
          validators: {
            requiredExtended: {
              message: 'Enter monthly payment',
            },
            positive: {
              message: 'Monthly payment must be positive',
              allowZero: true,
            },
          },
        },
        previousResidenceAddress1: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceAddress1',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Enter address',
            },
            specialChars: isPreviousResidenceShown && {
              message: 'Address cannot have special characters',
            },
          }),
        },
        previousResidenceAddress2: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceAddress2',
          validators: (isPreviousResidenceShown) => ({
            specialChars: isPreviousResidenceShown && {
              message: 'Address 2 cannot have special characters',
            },
          }),
        },
        previousResidenceCity: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceCity',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Enter city',
            },
            specialChars: isPreviousResidenceShown && {
              message: 'City cannot have special characters',
            },
          }),
        },
        previousResidenceState: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceState',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Choose state',
            },
          }),
        },
        previousResidenceZip: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceZip',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Enter ZIP',
            },
          }),
        },
        previousResidenceType: {
          value: null,
          error: {
            state: {
              isShown: false,
            },
            message: '',
          },
          name: 'previousResidenceType',
          validators: (isPreviousResidenceShown) => ({
            requiredExtended: isPreviousResidenceShown && {
              message: 'Choose residence type',
            },
          }),
        },
      },
      formDataDefaults,
      formatDollar,
      hasValidated: false,
      isEditing: false,
      monthlyPaymentFormatted: null,
      previousFullAddress: '',
    };
  },
  mounted() {
    this.setFormValues(this.formData);
  },
  methods: {
    handleInputError(input) {
      this.form[input].error = {
        ...this.form[input].error,
        state: {
          ...this.form[input].error.state,
          isShown: this.errors.has(this.form[input].name),
        },
        message: this.errors.first(this.form[input].name),
      };
    },
    validate(emit = true) {
      this.$validator.validateAll().then((isValid) => {
        Object.entries(this.form)
          .forEach(([key, value]) => {
            this.handleInputError(key);
          });

        if (isValid && emit) {
          this.setPreviousFullAddress(this.form);
          this.isEditing = false;

          if (!this.isPreviousResidenceShown) {
            this.clearPreviouseResidenceFields();
          }

          this.$emit('collect-data', { form: this.form, isValid: true });

          this.$validator.reset();
          this.hasValidated = false;
        }
      });

      this.hasValidated = true;
    },
    handleInput() {
      if (this.hasValidated) {
        this.validate(false);
      }
    },
    setFormValues(data) {
      try {
        this.form.residenceType.value = data.residenceType.value;
        this.form.residenceYears.value = data.residenceYears.value;
        this.form.residenceMonths.value = data.residenceMonths.value;
        this.form.monthlyPayment.value = data.monthlyPayment.value;
        this.monthlyPaymentFormatted = data.monthlyPayment.value;

        this.form.previousResidenceAddress1.value = data.previousResidenceAddress1.value;
        this.form.previousResidenceAddress2.value = data.previousResidenceAddress2.value;
        this.form.previousResidenceCity.value = data.previousResidenceCity.value;
        this.form.previousResidenceState.value = data.previousResidenceState.value;
        this.form.previousResidenceZip.value = data.previousResidenceZip.value;
        this.form.previousResidenceType.value = data.previousResidenceType.value;
        this.form.previousResidenceYears.value = data.previousResidenceYears.value;
        this.form.previousResidenceMonths.value = data.previousResidenceMonths.value;
        this.setPreviousFullAddress(this.form);
      } catch (error) {
        console.error(error);
        console.error('Invalid form data passed');
      }
    },
    handleEdit() {
      this.isEditing = !this.isEditing;
      this.setFormValues(this.formData);
    },
    handlePreviousResidenceAddress1InputChange(address) {
      this.form.previousResidenceAddress1.value = address;
      this.handleInput();
    },
    handlePreviousResidenceAddress1InputContext(context) {
      this.form.previousResidenceCity.value = context.city;
      this.form.previousResidenceState.value = context.state;
      this.form.previousResidenceZip.value = context.postalCode;
    },
    setPreviousFullAddress(data) {
      const previousAddress2String = (data.previousResidenceAddress2.value && data.previousResidenceAddress2.value.length > 0)
        ? ` ${data.previousResidenceAddress2.value}`
        : '';

      this.previousFullAddress = `${data.previousResidenceAddress1.value}${previousAddress2String}, ${data.previousResidenceCity.value}, ${data.previousResidenceState.value} ${data.previousResidenceZip.value}`;
    },
    clearPreviouseResidenceFields() {
      this.form.previousResidenceAddress1.value = null;
      this.form.previousResidenceAddress2.value = null;
      this.form.previousResidenceCity.value = null;
      this.form.previousResidenceState.value = null;
      this.form.previousResidenceZip.value = null;
      this.form.previousResidenceType.value = null;
      this.form.previousResidenceYears.value = null;
      this.form.previousResidenceMonths.value = null;
      this.previousFullAddress = '';
    },
  },
  watch: {
    formData(data) {
      this.setFormValues(data)
    },
    monthlyPaymentFormatted(formattedAmount) {
      const amount = formattedAmount.replace(/[^0-9.]/g,'');

      this.form.monthlyPayment.value = amount;
    },
    'form.residenceYears.value': function (value) {
      this.isPreviousResidenceShown = value <= 1;

      if (!this.isPreviousResidenceShown) {
        this.clearPreviouseResidenceFields();
      }
    },
  },
};

export default ResidenceInformationReview;
</script>