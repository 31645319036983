<template>
  <div class="d-flex align-items-start">
    <span class="dollar-sign mr-1">$</span>
    <span class="dollar font-barlow-bold">
      {{ dollars }}
    </span>
    <span class="cents font-barlow-bold">
      .{{ cents }}
    </span>
  </div>
</template>

<script>
const DollarDisplay = {
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      dollars: '',
      cents: '',
    };
  },
  mounted() {
    this.setDisplay(this.value);
  },
  watch: {
    value(value) {
      setDisplay(value);
    }
  },
  methods: {
    setDisplay(value) {
      const dollarParts = value.split('.');
      const [dollars, cents] = dollarParts;

      this.dollars = dollars;
      this.cents = cents;
    },
  }
}

export default DollarDisplay;
</script>
<style scoped lang="scss">
.dollar-sign {
    font-size: 0.85rem;
    line-height: 1.5rem;
    margin-right: 0.15rem;
}
</style>