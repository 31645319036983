<template>
  <div
    :class="{ 'no-details' : noDetails }"
    class="sidebar"
  >
    <a
      :href="backDetails.destination"
      class="go-back"
    >
      <font-awesome-icon icon="caret-left" /> {{ backDetails.label }}
    </a>
    <div
      v-if="dataAvailable"
      class="loan-info"
    >
      <loan-details v-bind="loanDetails" />
      <fi-details :fi-name="fiName" />
    </div>
  </div>
</template>

<script>
import LoanDetails from '@/components/LoanDetails.vue';
import FiDetails from '@/components/FiDetails.vue';

export default {
  components: {
    LoanDetails,
    FiDetails
  },
  props: {
    fiId: {
      type: Number,
      default: null
    },
    fiName: {
      type: String,
      default: ''
    },
    loanDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    dataAvailable: {
      type: Boolean,
      default: false
    },
    noDetails: {
      type: Boolean,
      default: false,
    },
    backDetails: {
      type: Object,
      default() {
        return {
          label: this.$router.history.current.path.indexOf('/confirmation') > -1 ? "Go home" : "Need to go back?",
          destination: this.$router.history.current.path.indexOf('/confirmation') > -1 ? "https://www.kasasa.com" : `https://www.kasasa.com/kasasaloan/results?application_id=${this.$store.state.applicationId}`
        }
      }
    }
  }
}
</script>