<template>
  <div class="application-page channel-partner fade-in">
    <div
      class="channel-partner-header"
    >
      <div>
        <img
          :src="logoSrc"
          class="channel-partner-logo"
          :alt="`${partnerName} logo`"
        >
      </div>

      <div class="d-flex channel-partner-intro">
        <div>
          <h1>Information review</h1>
          <h2>Take a second to make sure all of the information provided is correct.</h2>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <StepIndicatorBar :step="step" />
        </div>
      </div>
    </div>

    <div
      v-if="form"
      class="container mt-5"
    >
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="row">
            <div class="col-lg-6">
              <LoanInformationReview
                :form-data="form.LOAN_INFORMATION"
                @collect-data="handleLoanInformationCollectData"
              />

              <div class="dropdown-divider my-4" />

              <PersonalInformationReview
                :form-data="form.PERSONAL_INFORMATION"
                @collect-data="handlePersonalInformationCollectData"
              />

              <div class="dropdown-divider my-4" />

              <ResidenceInformationReview
                :form-data="form.RESIDENCE_INFORMATION"
                @collect-data="handleResidenceInformationCollectData"
              />
            </div>
            <!-- ./col-lg-6 -->

            <div class="col-lg-6">
              <EmploymentInformationReview
                :form-data="form.EMPLOYMENT_INFORMATION"
                @collect-data="handleEmploymentInformationCollectData"
              />
            </div>
          </div>
          <!-- ./row -->
        </div>
      </div>

      <div class="row my-5">
        <div class="col-lg-8 offset-lg-2">
          <div class="dropdown-divider" />
        </div>
      </div>
      <!-- ./row -->

      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div>
            <h4>
              Terms
            </h4>
            <p class="font-size-sm-4">
              By clicking Submit, you acknowledge, agree, and authorize that (a) you are providing “written instructions”  to Kasasa, under the Fair Credit Reporting Act to obtain consumer and related information about you from one or more consumer reporting agencies for the purposes of identifying the lenders and/or service providers who would be the best match for you, (b) Kasasa may obtain and share your personal information with lenders and third-parties for servicing and to provide you with products and services that may be of interest to you, (c) your information is true and accurate and may be used to make sure you are who you say you are, and to qualify you for loan options, (d) such lenders and third-parties may use the information you provided to obtain consumer reports and related information about you from one or more consumer reporting agencies, such as TransUnion, Experian and Equifax, or perform a hard credit pull, which may affect your credit history (e) and your information may be used and shared by lenders and third-parties to make prequalification, qualification, and other credit decisions and (f) if you are approved for a loan with a joint applicant that both applicants are responsible for the loan.
            </p>
            <p class="font-size-sm-4">
              You also agree to receive marketing communications, offers, and recommendations from Kasasa and its partners. 
            </p>
          </div>
        </div>
      </div>
      <!-- ./row -->

      <div class="row mb-5">
        <div class="col-lg-8 offset-lg-2">
          <div class="go-next mt-5">
            <button
              class="next btn btn-primary btn-block"
              type="button"
              @click.prevent="handleSubmit"
            >
              <div class="btn-text">
                {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                <font-awesome-icon
                  v-if="!isSubmitting"
                  icon="caret-right"
                  class="ml-2"
                />
                <span
                  v-if="isSubmitting"
                  class="btn-spinner spin ml-2"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- ./row -->
    </div>

    <PageContainerFooter />

    <GeneralErrorModal
      :state="errorModalState"
    />

    <IdentityErrorModal
      :state="identityErrorModalState"
    />

    <NetworkErrorModal
      :state="networkErrorModalState"
    />

    <PartnerPageFooter />
  </div>
</template>

<script>
import PartnerForm from '@/components/PartnerForm/PartnerForm.vue';
import PageFooter from '@/components/PageFooter.vue';
import { capitalizeWord, sleep } from '@/utils/utils';
import { createChannelPartnerApplication, getApplicationStatus } from '@/api/appservice.api';
import GeneralErrorModal from '@/components/GeneralErrorModal.vue';
import IdentityErrorModal from '@/components/IdentityErrorModal.vue';
import NetworkErrorModal from '@/components/NetworkErrorModal.vue';
import { formatDollar, removeNonNumbersFromString } from '@/utils/utils';
import PageContainerFooter from '@/components/PageContainerFooter.vue';
import LoanInformationReview from '@/components/PartnerReview/LoanInformationReview.vue';
import PersonalInformationReview from '@/components/PartnerReview/PersonalInformationReview.vue';
import { PARTNER_FORM_SECTIONS } from '@/constants/partner-form-sections';
import EmploymentInformationReview from '@/components/PartnerReview/EmploymentInformationReview.vue';
import ResidenceInformationReview from '@/components/PartnerReview/ResidenceInformationReview.vue';
import PartnerPageFooter from '@/components/PartnerPageFooter.vue';
import StepIndicatorBar from '@/components/StepIndicatorBar.vue';
import { partnerApplicationSteps } from '@/constants/partner-application-steps';

const POLL_STATE = {
  COMPLETE: 'COMPLETE',
};

const PartnerReviewPage = {
  components: {
    PartnerForm,
    PageFooter,
    GeneralErrorModal,
    PageContainerFooter,
    LoanInformationReview,
    PersonalInformationReview,
    EmploymentInformationReview,
    ResidenceInformationReview,
    PartnerPageFooter,
    StepIndicatorBar,
    IdentityErrorModal,
    NetworkErrorModal,
	},
	data() {
		return {
      isSubmitting: false,
      isPolling: false,
      pollCount: 0,
      pollCountMax: 10,
      form: null,
      errorModalState: {
        isShown: false,
      },
      formatDollar,
      identityErrorModalState: {
        isShown: false,
      },
      networkErrorModalState: {
        isShown: false,
      }
    }
	},
  mounted() {
    this.$store.commit('setPartnerApplicationStep', partnerApplicationSteps.stepFour);
    this.form = this.$store.state.partnerForm;
  },
  computed: {
    step() {
      return this.$store.state.partnerApplicationStep;
    },
    partnerName() {
      const { partnerName } = this.$route.params;
      return partnerName;
    },
    logoSrc() {
      return `https://www.kasasa.com/hubfs/channel-partners-logos/${this.partnerName}.png`;
    },
    monthlyIncome() {
      const form = this.$store.state.partnerForm;
      const annualIncome = form.PERSONAL_INFORMATION.annualIncome.value || 0;
      const monthlyIncome = Boolean(annualIncome)
        ? Number(parseFloat(annualIncome / 12, 10).toFixed(2))
        : 0;

        return monthlyIncome;
    },
    loanAmount() {
      const loanAmount = this.$store.state.partnerPrequalifiedData.approvedAmount
        ? this.$store.state.partnerPrequalifiedData.approvedAmount
        : this.$store.state.partnerPrequalifiedData.offers[0].approvedAmount
          ? this.$store.state.partnerPrequalifiedData.offers[0].approvedAmount
          : 0;

      return loanAmount;
    },
    hasPreviousEmployment() {
      return this.form.EMPLOYMENT_INFORMATION.employmentYears.value < 2;
    },
    monthlyIncome() {
      const form = this.$store.state.partnerForm;
      const annualIncome = form.PERSONAL_INFORMATION.annualIncome.value || 0;
      const monthlyIncome = Boolean(annualIncome)
        ? Number(parseFloat(annualIncome / 12, 10).toFixed(2))
        : 0;

        return monthlyIncome;
    }
  },
  methods: {
    scrolltop() {
      window.scrollTo(0, 0, {
        behavior: 'smooth'
      });
    },
    handleLoanInformationCollectData(data = {}) {
      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.LOAN_INFORMATION]: {
          ...this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.LOAN_INFORMATION],
          ...data.form,
        }
      });
      this.form = this.$store.state.partnerForm;
    },
    handlePersonalInformationCollectData(data) {
      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.PERSONAL_INFORMATION]: {
          ...this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.PERSONAL_INFORMATION],
          ...data.form,
        }
      });
      this.form = this.$store.state.partnerForm;
    },
    handleEmploymentInformationCollectData(data) {
      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.EMPLOYMENT_INFORMATION]: {
          ...this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.EMPLOYMENT_INFORMATION],
          ...data.form,
        }
      });
      this.form = this.$store.state.partnerForm;
    },
    handleResidenceInformationCollectData(data) {
      this.$store.commit('updatePartnerForm', {
        [PARTNER_FORM_SECTIONS.RESIDENCE_INFORMATION]: {
          ...this.$store.state.partnerForm[PARTNER_FORM_SECTIONS.RESIDENCE_INFORMATION],
          ...data.form,
        }
      });
      this.form = this.$store.state.partnerForm;
    },
    routeToOffersPage() {
      const { partnerName } = this.$route.params;
      this.$router.push({ name: 'PartnerOffersPage', params: { partnerName } });
      this.scrolltop();
    },
    handleNotQualified() {
      const { partnerName } = this.$route.params;
      this.$router.push({ name: 'PartnerConfirmationPage', params: { partnerName }, query: { status: 'NOT_QUALIFIED' } });
      this.scrolltop();
    },
    async handleSubmit() {
      if (this.isSubmitting || this.isPolling) {
        return;
      }

      this.isSubmitting = true;

      try {
        const request = this.buildSubmitRequest();
        const response = await createChannelPartnerApplication(request);
        const status = response.data.data.status;

        this.$store.commit('setPartnerApplicationData', response.data.data);

        if (status === 'IDV_REJECTED') {
          this.handleIdentityError(status);
          return;
        }

        if (status === 'IDV_SUCCESS' || status === 'PREQUALIFIED') {
          this.startPolling(response.data.data.applicationExternalId);
          return;
        }

        this.handleError('Unable to submit application');
      } catch(error) {
        console.error(error);

        if (error && error.message && error.message === 'Network Error') {
          this.handleNetworkError('Network Error');
          return;
        }

        this.handleError('Unable to submit application');
      }
    },
    async startPolling(applicationId) {
      this.isPolling = true;
      this.pollCount = 0;

      for (this.pollCount = 0; this.pollCount < this.pollCountMax; this.pollCount++) {
        if (!this.isPolling) {
          return;
        }

        if (this.pollCount !== 0) {
          await sleep(3000);
        }

        try {
          const response = await getApplicationStatus({
              applicationExternalId: applicationId,
          });

          const pollState = this.handleApplicationStatusResponse(response);

          if (pollState === POLL_STATE.COMPLETE) return;

        } catch (error) {
          console.error(error);

          if (error && error.message && error.message === 'Network Error') {
            this.handleNetworkError('Network Error');
            return;
          }

          this.handleError('Unable to poll application status');
        }
      }

      this.handleError('Max poll count reached');
    },
    handleApplicationStatusResponse(response) {
      const status = response.data.data.status;

      if (status === 'IDV_SUCCESS') {
        return;
      }

      if (status === 'IDV_REJECTED') {
        this.handleIdentityError(status);
        return POLL_STATE.COMPLETE;
      }

      if (status === 'NOT_QUALIFIED') {
        this.handleNotQualified();
        return POLL_STATE.COMPLETE;
      }

      if (status === 'RATES_FAILED') {
        this.handleError(status);
        return POLL_STATE.COMPLETE;
      }

      if (status === 'PREQUALIFIED') {
        if (
          response.data.data.offers
          && response.data.data.offers.length > 0
        ) {
          this.isPolling = false;
          this.isSubmitting = false;
          this.$store.commit('setPartnerPrequalifiedData', response.data.data);
          this.routeToOffersPage();
          return POLL_STATE.COMPLETE;
        }

        this.handleError('Invalid Offers');
        return POLL_STATE.COMPLETE;
      }
    },
    handleIdentityError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.identityErrorModalState = {
        ...this.identityErrorModalState,
        isShown: true,
      };
    },
    handleError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.errorModalState = {
        ...this.errorModalState,
        isShown: true,
      };
    },
    handleNetworkError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.networkErrorModalState = {
        ...this.networkErrorModalState,
        isShown: true,
      };
    },
    buildSubmitRequest() {
      const partner = this.$store.state.channelPartner;
      const form = this.$store.state.partnerForm;
      const hasPreviousResidence =
        form.RESIDENCE_INFORMATION.residenceYears.value <= 1
        && form.RESIDENCE_INFORMATION.previousResidenceAddress1.value !== null;
      const hasPreviousEmployment =
        form.EMPLOYMENT_INFORMATION.employmentYears.value <= 1
        && form.EMPLOYMENT_INFORMATION.previousJobTitle.value !== null;

      return {
        loanType: 'PERSONAL',
        loanPurposeType: 'Home Improvement',
        amount: String(form.LOAN_INFORMATION.loanAmount.value),
        applicationSource: capitalizeWord(partner.partnerName),
        storeNumber: partner.storeNumber,
        storeName: partner.storeName,
        employeeName: null,
        orderNumber: form.LOAN_INFORMATION.orderNumber.value,
        firstName: form.PERSONAL_INFORMATION.firstName.value,
        middleName: null,
        lastName: form.PERSONAL_INFORMATION.lastName.value,
        dob: form.PERSONAL_INFORMATION.dob.value,
        ssn: String(form.PERSONAL_INFORMATION.ssn.value),
        creditScore: '0',
        email: form.PERSONAL_INFORMATION.email.value,
        phone: removeNonNumbersFromString(form.PERSONAL_INFORMATION.phone.value),
        resType: form.RESIDENCE_INFORMATION.residenceType.value.toUpperCase(),
        address: form.PERSONAL_INFORMATION.address1.value,
        apt: form.PERSONAL_INFORMATION.address2.value,
        city: form.PERSONAL_INFORMATION.city.value,
        state: form.PERSONAL_INFORMATION.state.value,
        zip: form.PERSONAL_INFORMATION.zip.value,
        county: 'county',
        addrY: form.RESIDENCE_INFORMATION.residenceYears.value,
        addrM: form.RESIDENCE_INFORMATION.residenceMonths.value,
        housePmt: String(form.RESIDENCE_INFORMATION.monthlyPayment.value),
        employer: form.EMPLOYMENT_INFORMATION.employersName.value,
        empType: form.EMPLOYMENT_INFORMATION.employmentType.value.toUpperCase(),
        empTitle: form.EMPLOYMENT_INFORMATION.jobTitle.value,
        empPhone: removeNonNumbersFromString(form.EMPLOYMENT_INFORMATION.employersPhone.value),
        empExt: null,
        empY: form.EMPLOYMENT_INFORMATION.employmentYears.value,
        empM: form.EMPLOYMENT_INFORMATION.employmentMonths.value,
        empAddress: null,
        empCity: null,
        empState: null,
        empZip: null,
        grossMthInc: String(this.monthlyIncome),
        otherMthInc: form.PERSONAL_INFORMATION.otherIncome.value,
        otherIncType: form.PERSONAL_INFORMATION.otherIncomeType.value
          ? form.PERSONAL_INFORMATION.otherIncomeType.value
          : null,
        prevEmpType: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousEmploymentType.value.toUpperCase()
          : null,
        prevEmployer: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousEmployersName.value
          : null,
        prevEmpTitle: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousJobTitle.value
          : null,
        prevEmpPhone: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousEmployersPhone.value
          : null,
        prevEmpExt: null,
        prevEmpY: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousEmploymentYears.value
          : null,
        prevEmpM: hasPreviousEmployment
          ? form.EMPLOYMENT_INFORMATION.previousEmploymentMonths.value
          : null,
        prevAddress: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceAddress1.value
          : null,
        prevApt: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceAddress2.value
          : null,
        prevCity: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceCity.value
          : null,
        prevState: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceState.value
          : null,
        prevZip: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceZip.value
          : null,
        prevCounty: null,
        prevAddrY: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceYears.value
          : null,
        prevAddrM: hasPreviousResidence
          ? form.RESIDENCE_INFORMATION.previousResidenceMonths.value
          : null,
      };
    },
  },
};

export default PartnerReviewPage;
</script>
<style scoped>
.dropdown-divider {
  border-color: #C9C9C9;
}
</style>