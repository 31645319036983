import axios from 'axios';

const API_DATA_HOST_DEV = process.env.VUE_APP_API_DATA_HOST_DEV;
const API_DATA_HOST_UAT = process.env.VUE_APP_API_DATA_HOST_UAT;
const API_DATA_HOST_PROD = process.env.VUE_APP_API_DATA_HOST_PROD;

export const apiClient = axios.create({
  baseURL: getBaseUrl()
})

function getBaseUrl() {
  const locationUrl = window.location.href;

  if (locationUrl.includes('dev') || locationUrl.includes('localhost')) {
    return API_DATA_HOST_DEV;
  } else if (locationUrl.includes('uat')) {
    return API_DATA_HOST_UAT;
  } else {
    return API_DATA_HOST_PROD;
  }
}