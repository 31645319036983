<template>
  <div class="application-page channel-partner fade-in">
    <div
      class="channel-partner-header"
    >
      <div>
        <img
          :src="logoSrc"
          class="channel-partner-logo"
          :alt="`${partnerName} logo`"
        >
      </div>

      <div class="d-flex channel-partner-intro">
        <div>
          <h1>Your personalized loan options are below.</h1>
          <h2>
            The following options are offered to you by {{ partnerFiName }} for a loan amount of
            <div class="font-barlow-bold font-size-4 mt-3">
              {{ formatDollar(loanAmount) }}
            </div>
          </h2>
        </div>
      </div>
    </div>

    <div class="spacer space-6" />

    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="row justify-content-center">
            <div
              v-for="(offer, index) in offers"
              :key="index"
              class="col-lg-3 mb-3 d-flex justify-content-center"
            >
              <PartnerOffer
                :offer="offer"
                @select="handleOfferSelect"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-10 offset-lg-1">
          <p class="font-size-1">
            Monthly payments are rounded to the nearest cent. Calculations have a margin of error of up to $0.005 times the term of the loan.
          </p>
          <p class="font-size-1">
            Rates are current as of {{ today }}. Upon verification of the information you provided, you may qualify for rates as low as {{ lowestRate }}% APR. These are non-binding, non-offers of credit. The rates above do not include origination fees and final loan amount may vary. 
          </p>
        </div>
      </div>
    </div>

    <PageContainerFooter />

    <PartnerOffersPageFooter
      :offers="offers"
      :loan-amount="loanAmount"
    />

    <b-modal
      v-model="isSelectedOfferModalShown"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      no-fade
    >
      <div
        v-show="selectedOffer"
        class="py-3 px-4"
      >
        <div class="text-center">
          <p>You selected:</p>
          <h3 class="mb-4">
            {{ formatDollar(loanAmount) }} loan
          </h3>

          <PartnerOffer
            :offer="selectedOffer"
            no-button
          />

          <p class="mt-5">
            Is this right?
          </p>

          <button
            class="next btn btn-primary btn-block offer-select mt-3"
            type="button"
            @click.prevent="handleSubmit"
          >
            <div class="btn-text">
              {{ isSubmitting ? 'Submitting...' : 'Yes, Submit' }}
              <font-awesome-icon
                v-if="!isSubmitting"
                icon="caret-right"
                class="ml-2"
              />
              <span
                v-if="isSubmitting"
                class="btn-spinner spin ml-2"
                role="status"
                aria-hidden="true"
              />
            </div>
            <div
              v-show="isSubmitting"
              ref="submitButtonTextContainerRef"
              class="submit-button-text-container"
            >
              <span
                v-for="(text, index) in submitButtonTexts"
                :key="index"
                class="fade-in display-none"
              >
                {{ text }}
              </span>
            </div>
          </button>


          <button
            class="btn btn-secondary btn-block offer-select mt-3"
            type="button"
            :disabled="isSubmitting"
            @click.prevent="handleChangeTerms"
          >
            <div class="btn-text">
              No, Change Loan Terms
            </div>
          </button>

          <p class="text-left mt-4 font-size-sm-3 font-barlow-light">
            Monthly payments are rounded to the nearest cent. Calculations have a margin of error of up to $0.005 times the term of the loan.
          </p>
          <p class="text-left mt-4 font-size-sm-3 font-barlow-light">
            By clicking on "Yes, submit", you authorize us to share your information with TransPecos Banks and other participating financial institutions for purposes of loan qualification.  You further understand and agree that TransPecos Banks will verify your information and obtain your credit report through a hard credit pull. Upon your request, you will be told if a credit report was obtained and give you the name and address of the credit reporting agency that provided the report.  You warrant that the information you are submitting is true and correct.  By submitting this application, you agree to allow the information contained in your application, as well as the status of your application to be share with and received from TransPecos banks and any participating financial institutions and lending entities.
          </p>
        </div>
      </div>
    </b-modal>

    <GeneralErrorModal
      :state="errorModalState"
    />

    <NetworkErrorModal
      :state="networkErrorModalState"
    />
  </div>
</template>

<script>
import ChannelPartnerHeader from '@/components/ChannelPartnerHeader.vue';
import PartnerForm from '@/components/PartnerForm/PartnerForm.vue';
import PartnerOffer from '@/components/PartnerOffer.vue';
import PageFooter from '@/components/PageFooter.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import NetworkErrorModal from '@/components/NetworkErrorModal.vue';
import { submitChannelPartnerApplication, getApplicationStatus } from '@/api/appservice.api';
import GeneralErrorModal from '@/components/GeneralErrorModal.vue';
import { format } from 'date-fns'
import { formatDollar, sleep } from '@/utils/utils';
import PageContainerFooter from '@/components/PageContainerFooter.vue';
import PartnerOffersPageFooter from '@/components/PartnerOffersPageFooter.vue';
import DollarDisplay from '@/components/DollarDisplay.vue';

const PartnerOffersPage = {
  components: {
    ChannelPartnerHeader,
    PartnerForm,
    PageFooter,
    ErrorModal,
    PartnerOffer,
    GeneralErrorModal,
    PageContainerFooter,
    PartnerOffersPageFooter,
    DollarDisplay,
	},
	data() {
		return {
      offers: [],
      isSelectedOfferModalShown: false,
      selectedOffer: null,
      pollCount: 0,
      pollCountMax: 10,
      isErrorModalShown: false,
      isSubmitting: false,
      isPolling: false,
      errorModalState: {
        isShown: false,
      },
      formatDollar,
      partnerFiName: 'TransPecos Banks',
      submitButtonTexts: ['Processing', 'We Received Your Application', 'Response Coming Soon'],
      animateSubmitButtonTextInterval: null,
      networkErrorModalState: {
        isShown: false,
      },
    }
	},
  mounted() {
    this.offers = this.$store.state.partnerPrequalifiedData.offers;
  },
  computed: {
    partnerName() {
      const { partnerName } = this.$route.params;
      return partnerName;
    },
    logoSrc() {
      return `https://www.kasasa.com/hubfs/channel-partners-logos/${this.partnerName}.png`;
    },
    loanAmount() {
      const loanAmount = this.$store.state.partnerPrequalifiedData.approvedAmount
        ? this.$store.state.partnerPrequalifiedData.approvedAmount
        : this.$store.state.partnerPrequalifiedData.offers[0].approvedAmount
          ? this.$store.state.partnerPrequalifiedData.offers[0].approvedAmount
          : 0;

      return loanAmount;
    },
    today() {
      return format(new Date(), 'MM/dd/yyyy');
    },
    lowestRate() {
      return this.offers.reduce((lowestRate, currentOffer) => {
        if (lowestRate !== undefined) {
          return Number(currentOffer.rate) < lowestRate
            ? Number(currentOffer.rate)
            : lowestRate;
        }

        return Number(currentOffer.rate);
      }, undefined) || 0;
    }
  },
  methods: {
    handleOfferSelect(offer) {
      this.selectedOffer = offer;
      this.isSelectedOfferModalShown = true;
    },
    handleChangeTerms() {
      this.selectedOffer = null;
      this.isSelectedOfferModalShown = false;
    },
    async handleSubmit() {
      if (this.isSubmitting || this.isPolling) {
        return;
      }

      this.isSubmitting = true;
      this.animateSubmitButtonTexts();

      try {
        await submitChannelPartnerApplication({
          applicationExternalId: this.$store.state.partnerPrequalifiedData.applicationExternalId,
          term: this.selectedOffer.term,
        });

        this.startPolling();
      } catch(error) {
        console.error(error);

        if (error && error.message && error.message === 'Network Error') {
          this.handleNetworkError('Network Error');
          return;
        }

        this.handleError('Unable to submit application');
      }
    },
    async startPolling() {
      this.isPolling = true;
      this.pollCount = 0;

      for (this.pollCount = 0; this.pollCount < this.pollCountMax; this.pollCount++) {
        if (!this.isPolling) {
          return;
        }

        if (this.pollCount !== 0) {
          await sleep(3000);
        }

        try {
          const response = await getApplicationStatus({
              applicationExternalId: this.$store.state.partnerPrequalifiedData.applicationExternalId,
          });

          this.handleApplicationStatusResponse(response);
        } catch (error) {
          console.error(error);

          if (error && error.message && error.message === 'Network Error') {
            this.handleNetworkError('Network Error');
            return;
          }

          this.handleError('Unable to poll application status');
        }
      }

      this.handleError('Max poll count reached');
    },
    handleApplicationStatusResponse(response) {
      const status = response.data.data.status;

      if (status === 'SUBMIT_FAILED') {
        this.handleError(status);
        return;
      }

      if (
        status === 'APPROVED'
        || status === 'REFERRED'
        || status === 'DECLINED'
      ) {
          this.isPolling = false;
          this.isSubmitting = false;

          this.$store.commit('setSelectedPartnerOffer', this.selectedOffer);
          this.$store.commit('setPartnerSubmissionData', response.data.data);
          this.routeToConfirmationPage();
        return;
      }
    },
    handleError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.errorModalState = {
        ...this.errorModalState,
        isShown: true,
      };
    },
    handleNetworkError(message) {
      if (message) {
        console.error(message);
      }

      this.isPolling = false;
      this.isSubmitting = false;
      this.networkErrorModalState = {
        ...this.networkErrorModalState,
        isShown: true,
      };
    },
    routeToConfirmationPage() {
        const { partnerName } = this.$route.params;
        this.$router.push({ name: 'PartnerConfirmationPage', params: { partnerName } });
        this.scrolltop();
    },
    scrolltop() {
      window.scrollTo(0, 0, {
        behavior: 'smooth'
      });
    },
    animateSubmitButtonTexts() {
      try {
        const textElements = this.$refs.submitButtonTextContainerRef.querySelectorAll(':scope > span') || [];

        let index = 0;

        textElements[index].classList.remove('display-none');

        index++;

        this.animateSubmitButtonTextInterval = setInterval(() => {
          textElements.forEach((element) => element.classList.add('display-none'));
          textElements[index].classList.remove('display-none');

          index++;

          if (index === textElements.length) {
            index = 0;
            return;
          }
        }, 3000);
      } catch (error) {
        console.error(error);
      }

    }
  },
  watch: {
    isSubmitting(isSubmitting) {
        if (!isSubmitting) {
          clearInterval(this.animateSubmitButtonTextInterval);
        }
    }
  },
  unmounted() {
    clearInterval(this.animateSubmitButtonTextInterval);
  }
};

export default PartnerOffersPage;
</script>
<style lang="scss" scoped>
.submit-button-text-container {
  position: relative;
  z-index: 2;
  > span {
    font-size: 0.75rem;
  }
}
</style>