<template>
  <div
    class="fi-details"
  >
    <div v-if="page === 'sidebar'">
      <p>This loan is brought to you by</p>
      <p>{{ fiName }}</p>
    </div>
    <div v-else-if="page === 'results'">
      <p>Based on the information you provided, the following loan options are brought to you by {{ fiName }}:</p>
    </div>
  </div>
</template>

<script>

export default {
 props: {
    fiName: {
     type: String,
     default: ''
    },
    page: {
      type: String,
      default: 'sidebar'
    }
 }
}
</script>