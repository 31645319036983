import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VeeValidate from 'vee-validate';
import UUID from "vue-uuid";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMask from 'v-mask';
import VueCurrencyInput from 'vue-currency-input'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight, faCaretLeft, faCaretDown, faCheckCircle, faEye, faEyeSlash, faInfoCircle, faPlay, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/main.scss'; // Load main stylesheet
import { positiveRule } from './plugins/vee-validate/rules/positive';
import { requiredExtendedRule } from './plugins/vee-validate/rules/required-extended';
import { specialCharsRule } from './plugins/vee-validate/rules/special-chars';

// Font Awesome
library.add(faCaretRight, faCaretLeft, faCaretDown, faCheckCircle, faTwitter, faFacebookF, faInstagram, faEye, faEyeSlash, faInfoCircle, faPlay, faEdit)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

// Initialize UUID
Vue.use(UUID);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Use VeeValidate
Vue.use(VeeValidate);
// Use V-Mask
Vue.use(VueMask);
// Use VueCurrencyInput
Vue.use(VueCurrencyInput)

// 18 years or older validation
VeeValidate.Validator.extend('isOld', {
  // Custom validation message
  getMessage: (field) => 'Must 18 or older',
  // Custom validation rule
  validate: (value) => {
    const dateArray = value.split('/');
    if ( dateArray.length ) {
      const month = parseFloat(dateArray[0]);
      const day = parseFloat(dateArray[1]);
      const year = parseFloat(dateArray[2]);
      return new Date(year+18, month-1, day) <= new Date();
    }
    return false;
  }
});

// Is no more than 150 years old
VeeValidate.Validator.extend('isOldRange', {
  // Custom validation message
  getMessage: (field) => `Please enter a valid date`,
  // Custom validation rule
  validate: (value) => {
    const currentDateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    const currentDate = new Date().toLocaleDateString("en-US", currentDateOptions);

    const thisYearOptions = {
      year: 'numeric'
    };
    const thisYear = parseInt(new Date().toLocaleDateString("en-US", thisYearOptions));
    const oldestYear = (thisYear - 120);
    const todaysDateArray = currentDate.split('/');
    const oldestDate = `${todaysDateArray[0]}/${todaysDateArray[1]}/${oldestYear}`;

    const d1 = oldestDate.split("/");
    const d2 = currentDate.split("/");
    const c = value.split("/");

    const from = new Date(d1[2],  parseInt(d1[0])-1, parseInt(d1[1]));  // -1 because months are from 0 to 11
    const to   = new Date(d2[2],  parseInt(d2[0])-1, parseInt(d2[1]));
    const check = new Date(c[2],  parseInt(c[0])-1, parseInt(c[1]));

    return (check > from && check < to);
  }
});

// Is a US based phone number validator
VeeValidate.Validator.extend('isUsPhone', {
  // Custom validation message
  getMessage: (field) => `Only US based numbers`,
  // Custom validation rule
  validate: (value) => {
    const phoneRule = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if ( value.match(phoneRule) ) {
      return true;
    }
    return false;
  }
});

VeeValidate.Validator.extend('isFullAddress', {
  // Custom validation message
  getMessage: (field) => `Must be full address`,
  // Custom validation rule
  validate: (value, formData) => {
    if (
      formData.fullAddress !== "" &&
      formData.address !== "" &&
      formData.city !== "" &&
      formData.zip !== "" &&
      formData.state !== "" &&
      formData.county !== ""
    ) {
      return formData.fullAddress;
    }
    return false;
  }
});

VeeValidate.Validator.extend(...positiveRule);

VeeValidate.Validator.extend(...requiredExtendedRule);

VeeValidate.Validator.extend(...specialCharsRule);

// Filters
Vue.filter('sentencedash', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase();
  value = value.charAt(0).toUpperCase() + value.slice(1)
  return value.replace(' ', '-');
})

Vue.filter('sentencecase', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('dashify', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(' ', '-');
})

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('maskssn', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(/\d(?=\d{3})/g, "*")
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
});

Vue.filter('toCurrencyTwoDigits', function (value) {
  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toCurrencyNoFraction', function (value) {
  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter('toFixedTwo', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  return value.toFixed(2);
});

Vue.filter('decodeUri', function (value) {
  return decodeURIComponent(value);
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
