<template>
  <span
    v-show="isShown"
    class="invalid-feedback"
    @click="handleClick"
  >
    {{ message }}
  </span>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default() {
        return '';
      },
    },
    state: {
      type: Object,
      default() {
        return {
          isShown: false,
        };
      }
    }
  },
  data() {
    return {
      isShown: false,
    };
  },
  watch: {
    state: function(state) {
      this.isShown = state.isShown;
    }
  },
  methods: {
    handleClick: function () {
      this.isShown = false;
    }
  },

}
</script>