<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="icon"
    v-html="getSvg(icon)"
  />
  <!--eslint-enable-->
</template>

<script>
import { confirmationIcon } from '@/svgs';
import { technicalIssueIcon } from '@/svgs';

export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  methods: {
    getSvg(icon) {
      if ( icon === 'confirmation-icon' ) return confirmationIcon;
      if ( icon === 'technical-issues-icon' ) return technicalIssueIcon;
    }
  }
}
</script>